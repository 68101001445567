import { deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import ParentPath from './ParentPath';
import RemoveString from './RemoveString';

/**
 *
 * @param {object} orgTree - pass the organizational tree as an object in an array
 * @param {object} db - Firestore database
 * @param {string} pathToRemove - the document title (path) that is being removed
 * @returns
 */
export default async function MoveChildren(orgTree, db, pathToRemove, user) {
	// console.log('orgTree:');
	// console.log(orgTree);
	return new Promise(async (resolve) => {
		orgTree.forEach(async (branch) => {
			const data = branch;
			// console.log(`title: ${data.title}`);
			// console.log(`data:`);
			// console.log(data);

			const originalPath = data.path;
			const children = data.children;
			// console.log('children:');
			// console.log(children);

			delete data.children; //removes children from the data
			const timestamp = new Date().toISOString();

			// console.log(`pathToRemove: ${pathToRemove}`);

			//copy, update path and history, and move up the document
			let newPath = data.path.replace(pathToRemove, '');
			newPath = RemoveString(`/${data.title}`, newPath);
			// console.log(`${ParentPath(newPath)}/children`);
			// console.log(`newPath: ${newPath}`);
			const docRefNewPath = doc(db, newPath, data.title);
			// data.path = `${newPath}/children/${data.title}`;
			data.path = `${newPath}/${data.title}`;
			// console.log(`data.path: ${data.path}`);
			data.history[timestamp] = {
				user: user,
				note: 'Parent moved.  This item automatically moved up one level.',
				data: {
					path: `${newPath}/${data.title}`,
				},
			};
			// console.log(`data:`);
			// console.log(data);
			await setDoc(docRefNewPath, data);

			//run this function for any children found

			if (children !== undefined && children.length > 0) {
				children.forEach(async (child) => {
					// console.log('child:');
					// console.log(child);
					await MoveChildren([child], db, pathToRemove, user);
				});
			}

			//delete the original document since it's moved up
			const docRef = doc(db, `${ParentPath(originalPath)}/children`, data.title);
			await deleteDoc(docRef);
		});

		resolve();
	});
}
