import React, { useContext, useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import {
	Box,
	Typography,
	Stack,
	TextField,
	Button,
	Dialog,
	DialogTitle,
	Container,
	DialogContent,
	Autocomplete,
	Chip,
} from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import Tooltip from '../ui/Tooltip';
import TextEditor from '../textEditor/TextEditor';
import AlertContext from '../ui/AlertContext';
import sendGrid from './sendGrid';
import LoadingSpinner from '../ui/LoadingSpinner';
import getFirestoreDocument from '../localFunctions/getFirestoreDocument';
import CustomClaimsContext from '../auth/CustomClaimsContext';

export default function SendEmailAsTemplate(props) {
	const auth = getAuth();
	const alertCtx = useContext(AlertContext);
	const claimsCtx = useContext(CustomClaimsContext);
	const [sending, setSending] = useState(false);
	const [openTo, setOpenTo] = useState(false);
	const [openCC, setOpenCC] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [groups, setGroups] = useState(null);
	const [emailGroups, setEmailGroups] = useState(null);
	const initialEmailSettings = {
		to: null,
		ToConsent: null,
		cc: null,
		CCConsent: null,
		bcc: null,
		fromGroup: null,
		emailHTML: '',
		emailSubject: null,
		emailTitle: null,
		plainText: null,
		attachments: null,
		textEditorValid: null,
	};
	const [emailSettings, setEmailSettings] = useState(initialEmailSettings);

	const inputs = [
		{ label: 'From Group', value: 'fromGroup', disabled: false, tooltip: 'Select a group to send an email from.' },
		{
			label: 'To',
			value: 'to',
			disabled: false,
			tooltip:
				'Not recommended to use.  Any emails listed here will be included on any Reply All and Reply To email responses.',
		},
		{
			label: 'CC',
			value: 'cc',
			disabled: false,
			tooltip:
				'Not recommended to use.  Any emails listed here will be included on any Reply All email responses, but not on Reply To.',
		},
		{
			label: 'BCC',
			value: 'bcc',
			disabled: false,
			tooltip:
				'Recommended method to send emails to groups & individuals.  Any emails listed here will not be included in any Reply All or Reply To fields.',
		},
		{ label: 'Email Subject', value: 'emailSubject', disabled: false, tooltip: null },
		{
			label: 'Email Title',
			value: 'emailTitle',
			disabled: false,
			tooltip: 'Recommended - A header for the email.  If no input then the Email Subject will be used.',
		},
	];

	// useEffect(() => {
	// 	console.log('emailSettings.attachments', emailSettings.attachments);
	// }, [emailSettings]);

	//get groups
	useEffect(() => {
		if (!groups && auth.currentUser) {
			async function getData() {
				const uid = auth.currentUser.uid;
				const res = await getFirestoreDocument(`Users/${uid}`);
				// console.log(res);

				if (res.status === 'success') {
					const unsortedSendEmailAs = res.data.SendEmailAs_User || [];
					const sortedSendEmailAs = unsortedSendEmailAs.sort((a, b) => a.name.localeCompare(b.name));
					setGroups(sortedSendEmailAs);

					const unsortedSendEmailTo = res.data.SendEmailTo || [];
					const sortedSendEmailTo = unsortedSendEmailTo.sort((a, b) => a.name.localeCompare(b.name));
					setEmailGroups(sortedSendEmailTo);
				} else {
					alertCtx.setMessage(res.message);
					alertCtx.setSeverity(res.status);
					alertCtx.setTitle('Error');
					alertCtx.setActive(true);

					setGroups([]);
					setEmailGroups([]);
				}
			}
			getData();
		}
	}, [groups, auth.currentUser]);

	//turns off loading once data set
	useEffect(() => {
		if (groups) setIsLoading(false);
	}, [groups]);

	//updates the emailSettings fields
	const updateEmailSettingsField = (newItem, field) => {
		setEmailSettings((prevData) => {
			const newData = { ...prevData };
			newData[field] = newItem;
			return newData;
		});
	};

	//updates the emailBody of emailSettings
	const handleEmailBodyChange = (obj) => {
		updateEmailSettingsField(obj.text, 'emailHTML');
		updateEmailSettingsField(obj.valid, 'textEditorValid');
	};

	const handleOpenToConsent = () => {
		setOpenTo(true);
	};

	const handleCloseToConsent = (value) => {
		setOpenTo(false);
		if (value === true) updateEmailSettingsField(value, 'ToConsent');
	};

	const handleOpenCCConsent = () => {
		setOpenCC(true);
	};

	const handleCloseCCConsent = (value) => {
		setOpenCC(false);
		if (value === true) updateEmailSettingsField(value, 'CCConsent');
	};

	function extractImageSources(htmlContent) {
		const imgRegex = /<img[^>]+src="([^">]+)"/g;
		let images = [];
		let match;

		while ((match = imgRegex.exec(htmlContent)) !== null) {
			images.push(match[1]);
		}

		return images;
	}

	async function uploadImageToFirebase(imageSrc) {
		const storage = getStorage();
		const date = new Date();
		const year = date.getFullYear();
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed; add 1 to get the correct month
		const day = date.getDate().toString().padStart(2, '0');
		const randomString = Math.random().toString(36).substring(2, 15);
		const groupName = emailSettings.fromGroup.name; // Make sure this is sanitized and safe to use in a file path
		const sanitizedGroupName = groupName.replace(/\s+/g, '_').toLowerCase(); // Replaces all spaces with underscores and converts to lower case

		const imageName = `SendEmailAs/${year}-${month}-${day}/${sanitizedGroupName}/${randomString}`; // Generate a unique name
		const storageRef = ref(storage, imageName);

		const response = await fetch(imageSrc);
		const blob = await response.blob(); // Convert the image source to a blob

		await uploadBytes(storageRef, blob); // Upload the blob to Firebase Cloud Storage
		const publicUrl = await getDownloadURL(storageRef); // Get the public URL of the uploaded image
		if (publicUrl) return `https://storage.googleapis.com/directory-71d6d.appspot.com/${imageName}`;
		else return null;
	}

	async function replaceImageSources(htmlContent) {
		const imageSources = extractImageSources(htmlContent);

		for (const src of imageSources) {
			const firebaseUrl = await uploadImageToFirebase(src);

			// Include the quotes in the replacement pattern
			htmlContent = htmlContent.replace(`"${src}"`, firebaseUrl); // Removed quotes around firebaseUrl
		}

		return htmlContent;
	}

	const convertToBase64 = (file) =>
		new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);

			fileReader.onload = () => {
				resolve(fileReader.result);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});

	//add files as attachments
	const handleFileChange = async (event) => {
		const newFiles = Array.from(event.target.files);
		let totalSize = 0;

		// First, check if files were selected
		if (!newFiles.length) return;

		// Calculate the total size of newly selected files
		newFiles.forEach((file) => {
			totalSize += file.size;
		});

		// Include the size of existing attachments in the total size
		if (emailSettings.attachments) {
			emailSettings.attachments.forEach((attachment) => {
				// Assuming you have a way to track the size of existing attachments
				// For example, if you're storing the size in bytes as part of the attachment object
				totalSize += attachment.size; // Add the size of each existing attachment
			});
		}

		// Convert total size to megabytes
		totalSize = totalSize / (1024 * 1024);

		if (totalSize > 25) {
			// If total size exceeds 25 MB, inform the user
			alertCtx.setSeverity('warning');
			alertCtx.setMessage(
				'The total size of attachments exceeds the 25 MB limit. Please select smaller files or reduce the number of files.'
			);
			alertCtx.setActive(true);
			// Optionally, clear the file input here if you want to force the user to select again
			// event.target.value = null; // This will clear the selected files
			return; // Stop the function execution
		}

		// If total size is within the limit, proceed with base64 conversion and updating the state
		const filesWithBase64 = await Promise.all(
			newFiles.map(async (file) => {
				const base64 = await convertToBase64(file);
				return {
					content: base64.split(',')[1], // Remove the Data URL part
					filename: file.name,
					contentType: file.type,
					size: file.size, // Store the size of the file for future calculations
				};
			})
		);

		// Combine the new files with any existing attachments
		const updatedFiles = emailSettings.attachments
			? [...emailSettings.attachments, ...filesWithBase64]
			: [...filesWithBase64];

		// Update the state with the new list of attachments
		updateEmailSettingsField(updatedFiles, 'attachments');
	};

	// Function to handle removing a file
	const handleRemoveFile = (indexToRemove) => {
		if (!emailSettings.attachments) return;

		const filteredFiles = emailSettings.attachments.filter((_, index) => index !== indexToRemove);
		updateEmailSettingsField(filteredFiles, 'attachments');
	};

	const handleSendEmail = async () => {
		setSending(true);

		let template_id = 'd-e737b47592874685adf84df535d2ea01'; //default, without inline image

		let toField = [];
		let ccField = [];
		let bccField = [];
		if (emailSettings.to) toField = emailSettings.to.map((group) => group.email);
		if (emailSettings.cc) ccField = emailSettings.cc.map((group) => group.email);
		if (emailSettings.bcc) bccField = emailSettings.bcc.map((group) => group.email);

		const newHTML = await replaceImageSources(emailSettings.emailHTML);

		// console.log('formattedHTML', formattedHTML);
		// setSending(false);
		// return;

		const response = await sendGrid({
			to: toField,
			...(ccField ? { cc: ccField } : {}),
			...(bccField ? { bcc: bccField } : {}),
			replyTo: emailSettings.fromGroup.email,
			plainText: emailSettings.plainText || newHTML,
			html: newHTML,
			template_id: template_id,
			emailSubject: emailSettings.emailSubject,
			emailTitle: emailSettings.emailTitle || emailSettings.emailSubject,
			emailHTML: newHTML,
			fromPerson: claimsCtx.claims.name,
			fromGroup: emailSettings.fromGroup.name,
			...(emailSettings.attachments ? { attachments: emailSettings.attachments } : {}),
		});
		// console.log(response);

		alertCtx.setSeverity(response.data.status);
		alertCtx.setMessage(response.data.message + ` from ${emailSettings.fromGroup.name}.`);
		alertCtx.setTimer(response.data.code === 200 ? 10000 : null);
		alertCtx.setActive(true);

		//resets email settings if successful
		if (response.data.code === 200) setEmailSettings(initialEmailSettings);

		setSending(false);
	};

	//display if loading
	if (isLoading || sending) {
		return <LoadingSpinner />;
	}

	//no groups to sendEmailAs
	if (!claimsCtx?.claims?.sendEmailAs)
		return (
			<Container>
				<Typography textAlign={'center'} mt={2}>
					No groups are available for you to send an email as.
				</Typography>
			</Container>
		);

	return (
		<Box sx={{ m: 2 }}>
			{/* email settings */}
			<Stack spacing={1} mb={1}>
				{inputs.map((input, key) => {
					let componentToRender;
					let sendEmailFields = (
						<Autocomplete
							disabled={sending || input.disabled || !groups}
							size='small'
							fullWidth
							multiple
							id={input.label}
							options={emailGroups}
							getOptionLabel={(option) => `${option.name} -- ${option.email}`} // Defines how to get the string value for each option
							value={emailGroups.find((option) => option.name === emailSettings[input.value]) || undefined} // Find the object in options that matches the selected label
							freeSolo
							onChange={(e, newValue) => {
								if (typeof newValue[newValue.length - 1] === 'string') {
									const newestItem = newValue.pop();
									newValue.push({ email: newestItem, name: newestItem });
								}

								updateEmailSettingsField(newValue, input.value);
							}}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => {
									const tagProps = getTagProps({ index });
									const { key, ...rest } = tagProps; // Extract the key prop and the rest of the props
									return (
										<Chip
											key={key} // Pass the key prop directly
											variant='contained'
											color={'otherButton'}
											label={option.name}
											{...rest} // Spread the rest of the props
										/>
									);
								})
							}
							renderInput={(params) => (
								<TextField
									{...params}
									variant='outlined'
									label={input.label}
									placeholder='Choose an email address or enter one not listed (click enter when done)'
								/>
							)}
						/>
					);
					let fromGroupField = (
						<Autocomplete
							disabled={sending || input.disabled || !groups}
							size='small'
							fullWidth
							// multiple
							id={input.label}
							options={groups}
							getOptionLabel={(option) => `${option.name} -- ${option.email}`} // Defines how to get the string value for each option
							value={emailSettings[input.value] || null}
							// freeSolo
							onChange={(e, newValue) => {
								// if (typeof newValue[newValue.length - 1] === 'string') {
								// 	const newestItem = newValue.pop();
								// 	newValue.push({ email: newestItem, description: newestItem });
								// }

								updateEmailSettingsField(newValue, input.value);
							}}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip variant='contained' color={'otherButton'} label={option.name} {...getTagProps({ index })} />
								))
							}
							renderInput={(params) => (
								<TextField
									{...params}
									variant='outlined'
									label={input.label}
									placeholder='Choose an group to send an email from.'
								/>
							)}
						/>
					);
					let defaultField = (
						<TextField
							disabled={sending || input.disabled || !groups}
							label={input.label}
							variant='outlined'
							size='small'
							margin='dense'
							value={emailSettings[input.value] ? emailSettings[input.value] : ''}
							onChange={(e) => updateEmailSettingsField(e.target.value, input.value)}
							color='secondary'
							sx={{ width: '95%' }}
						/>
					);

					// Determine what to render based on the input.label or any other condition
					switch (input.label) {
						case 'To':
							if (!emailSettings.ToConsent) {
								componentToRender = (
									<Button variant='outlined' onClick={handleOpenToConsent}>
										Consent to Sending via 'To'
									</Button>
								);
							} else {
								componentToRender = sendEmailFields;
							}
							break;
						case 'CC':
							if (!emailSettings.CCConsent) {
								componentToRender = (
									<Button variant='outlined' onClick={handleOpenCCConsent}>
										Consent to Sending via 'CC'
									</Button>
								);
							} else {
								componentToRender = sendEmailFields;
							}
							break;
						case 'BCC':
							componentToRender = sendEmailFields;
							break;
						case 'From Group':
							componentToRender = fromGroupField;
							break;
						default:
							componentToRender = defaultField;
							break;
					}
					// Return the Stack component with the conditionally rendered component
					return (
						<Stack direction='row' sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }} key={key}>
							<Container sx={{ width: '5%', marginLeft: '1rem', marginRight: '1rem' }}>
								{input.tooltip && <Tooltip text={input.tooltip} />}
							</Container>
							{componentToRender}
						</Stack>
					);
				})}
				<Box sx={{ display: 'flex', justifyContent: 'left' }}>
					<Container sx={{ width: '5%', marginLeft: '1rem', marginRight: '1rem' }} />

					<Stack sx={{ width: '95%' }} spacing={2}>
						{/* email body - html */}
						<Stack>
							<Typography variant='caption'>Email Body</Typography>
							<TextEditor
								disabled={sending || !groups}
								initialContent={emailSettings.emailHTML}
								onChange={handleEmailBodyChange}
							/>
						</Stack>

						{/* upload files button */}
						<Container sx={{ display: 'flex', justifyContent: 'center' }}>
							<Button variant='outlined' component='label'>
								{emailSettings?.attachments?.length === 0 ? 'Upload Files' : 'Add Files'}
								<input
									type='file'
									hidden
									multiple // Allows multiple file selection
									onChange={handleFileChange}
								/>
							</Button>
						</Container>

						{/* display uploaded files */}
						{emailSettings.attachments &&
							emailSettings.attachments.map((file, index) => (
								<Chip
									key={index}
									label={file.filename}
									onDelete={() => handleRemoveFile(index)}
									color='info'
									variant='outlined'
								/>
							))}

						{/* send email button */}
						<Container sx={{ display: 'flex', justifyContent: 'center' }}>
							<Button
								variant='contained'
								color='otherButton'
								disabled={
									sending ||
									!emailSettings.fromGroup ||
									!emailSettings.emailSubject?.length > 0 ||
									!emailSettings.textEditorValid ||
									(!emailSettings.to && !emailSettings.cc && !emailSettings.bcc)
								}
								startIcon={<EmailOutlinedIcon />}
								onClick={handleSendEmail}
							>
								SEND EMAIL
							</Button>
						</Container>
					</Stack>
				</Box>
				<ToConsent open={openTo} onClose={handleCloseToConsent} value={emailSettings.ToConsent} />
				<CCConsent open={openCC} onClose={handleCloseCCConsent} value={emailSettings.ToConsent} />
			</Stack>
		</Box>
	);
}

function ToConsent(props) {
	const { onClose, open, value } = props;

	const handleClose = (value) => {
		onClose(value);
	};

	return (
		<Dialog onClose={handleClose} open={open}>
			<Container sx={{ padding: '1rem', textAlign: 'center' }}>
				<DialogTitle>
					<Typography variant='title'>Consent</Typography>
				</DialogTitle>
				<DialogContent>
					<Typography paragraph>
						Sending emails using the <b>To</b> field is not recommended. Any emails listed in the <b>To</b> field will
						be included on any Reply All and Reply To email responses.
					</Typography>
					<Typography paragraph>
						It is recommend that emails are sent using the <b>BCC</b> field.
					</Typography>
					<Typography paragraph>
						If you want to continue sending via the <b>To</b> field, click the <b>Acknowledge</b> button below,
						otherwise click the <b>Cancel</b> button.
					</Typography>
				</DialogContent>
				<Stack direction={'row'} justifyContent={'center'} alignContent={'center'} spacing={2}>
					<Button variant='contained' color='cancel' onClick={handleClose}>
						Cancel
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={() => {
							handleClose(true);
						}}
					>
						Acknowledge
					</Button>
				</Stack>
			</Container>
		</Dialog>
	);
}

function CCConsent(props) {
	const { onClose, open, value } = props;

	const handleClose = (value) => {
		onClose(value);
	};

	return (
		<Dialog onClose={handleClose} open={open}>
			<Container sx={{ padding: '1rem', textAlign: 'center' }}>
				<DialogTitle>
					<Typography variant='title'>Consent</Typography>
				</DialogTitle>
				<DialogContent>
					<Typography paragraph>
						Sending emails using the <b>CC</b> field is not recommended. Any emails listed in the <b>CC</b> field will
						be included on any Reply All and Reply To email responses.
					</Typography>
					<Typography paragraph>
						It is recommend that emails are sent using the <b>BCC</b> field.
					</Typography>
					<Typography paragraph>
						If you want to continue sending via the <b>CC</b> field, click the <b>Acknowledge</b> button below,
						otherwise click the <b>Cancel</b> button.
					</Typography>
				</DialogContent>
				<Stack direction={'row'} justifyContent={'center'} alignContent={'center'} spacing={2}>
					<Button variant='contained' color='cancel' onClick={handleClose}>
						Cancel
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={() => {
							handleClose(true);
						}}
					>
						Acknowledge
					</Button>
				</Stack>
			</Container>
		</Dialog>
	);
}
