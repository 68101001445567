import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import GetPhotoURL from '../ui/GetPhotoURL';
import LoadingSpinner from '../ui/LoadingSpinner';
import UserImage from '../ui/UserImage';

/**
 * used for multiple members in a group
 * @param {*} props
 * @returns
 */
export default function PersonCard(props) {
	const [loading, setLoading] = useState(false);
	const [photoURL, setPhotoURL] = useState('');

	useEffect(() => {
		const getPhotUrl = async () => {
			setLoading(true);
			const url = await GetPhotoURL(props.person.email, props.token);
			// console.log(url);
			setPhotoURL(url);
			setLoading(false);
		};
		getPhotUrl();
	}, [props]);

	if (loading) {
		return <LoadingSpinner />;
	}

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'center',
				height: '3rem',
				paddingLeft: '10px',
			}}
		>
			<UserImage photoURL={photoURL} name={props.person.name} size={72} />
			<Stack
				display='flex'
				justifyContent='left'
				alignItems='center'
				sx={{ height: '3rem', marginTop: '0.25rem', marginBottom: 'auto', marginLeft: '10px' }}
			>
				<Typography variant='' align='left' sx={{ width: '100%', alignItems: 'left', fontSize: '0.75rem' }}>
					{props.person.name}
				</Typography>
				<Typography variant='' align='left' m={0} sx={{ width: '100%', fontSize: '0.75rem' }}>
					{props.person.positionTitle}
				</Typography>
			</Stack>
		</Box>
	);
}
