import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import PageCards from '../components/ui/PageCards';
import DirectorySettings from '../components/directoryInfo/DirectorySettings';
import SiteSettings from './SiteSettings';
import TickSettings from '../components/tickLogs/TickSettings';

function Settings() {
	const [value, setValue] = useState(0);

	const handleTabChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container spacing={1}>
				{/* page header */}
				<Grid xs={12}>
					<PageCards>
						<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
							Settings
						</Typography>

						{/* tabs */}
						<Box mb={5} sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs
								value={value}
								textColor='secondary'
								indicatorColor='secondary'
								onChange={handleTabChange}
								centered
								aria-label='settings tabs'
							>
								<Tab label='Directory Settings' {...a11yProps(0)} />
								<Tab label='Site Settings' {...a11yProps(1)} />
								<Tab label='Tick Settings' {...a11yProps(2)} />
							</Tabs>
						</Box>

						{/* panel 0 */}
						<TabPanel value={value} index={0}>
							<DirectorySettings />
						</TabPanel>

						{/* panel 1 */}
						<TabPanel value={value} index={1}>
							<SiteSettings />
						</TabPanel>

						{/* panel 2 */}
						<TabPanel value={value} index={2}>
							<TickSettings />
						</TabPanel>
					</PageCards>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Settings;

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <>{children}</>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}
