import React, { useState, Fragment, useEffect } from 'react';

import dayjs from 'dayjs';

import {
	Button,
	Box,
	Checkbox,
	Container,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	Stack,
	Typography,
	TextField,
	MenuItem,
	Select,
	InputLabel,
	ToggleButton,
	ToggleButtonGroup,
	IconButton,
	FormGroup,
	OutlinedInput,
	ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ColorPicker from 'react-pick-color';

import StepperUI from '../ui/StepperUI';
import Tooltip from '../ui/Tooltip';
import Chart_Table from './Chart_Table';
import Chart_BarChart from './Chart_BarChart';
import Chart_IFrame from './Chart_IFrame';
import { MenuProps, restrictedTo } from './RestrictedSettings';

export default function Dashboard_Chart({
	addParameterItem,
	chart,
	chartIndex,
	dashboard,
	dashboardID,
	deleteChart,
	equal,
	handleQueryParamChange,
	props,
	queryFields,
	rollingTimeframe,
	saving,
	saveChanges,
	setDashboard,
	setRollingTimeframe,
	setTempQueryParam,
	tempQueryParam,
	updateChartField,
	removeSeries,
	linkParamsWithOr,
	unpairOrGroup,
}) {
	const [activeStep, setActiveStep] = useState(null);
	const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);

	useEffect(() => {
		if (chart.restrictedTo === undefined) chart.restrictedTo = [];
	}, []);

	// useEffect(() => {
	// 	console.log('chart:', chart);
	// }, [chart]);

	const handleActiveStepChange = (step) => {
		setActiveStep(step);
	};

	return (
		<StepperUI
			steps={['Chart Details', 'Data Query Settings', 'Data Display', 'Chart Settings', 'Preview Chart']}
			handleActiveStepChange={handleActiveStepChange}
			equal={equal}
			saveFunction={saveChanges}
			saveLocation={props.dashboard}
			saving={saving}
		>
			<Grid container>
				<Grid item xs={12} style={{ flexGrow: 1 }}>
					{/* chart details */}
					{activeStep === 0 && (
						<Stack spacing={1}>
							{/* chart title */}
							<TextField
								disabled={saving}
								label='Chart Title'
								variant='outlined'
								size='small'
								margin='none'
								value={chart.title || ''}
								onChange={(e) => updateChartField(chartIndex, e.target.value, 'title')}
								fullWidth
							/>

							{/* chart restricted */}
							<Stack direction={'row'} spacing={1} alignItems={'center'} pt={2} pb={2}>
								<Tooltip text='Select to make the Chart restricted to certain users.' />
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												disabled={saving}
												checked={chart.restricted || false}
												onChange={(e) => updateChartField(chartIndex, e.target.checked, 'restricted')}
											/>
										}
										label='Restrict Chart'
									/>
								</FormGroup>
								<FormControl sx={{ m: 1, width: 300 }} disabled={!chart.restricted}>
									<InputLabel id='restricted-to' label='Restricted To'>
										Restricted To
									</InputLabel>
									<Select
										labelId='restricted-to-label'
										id='restricted-to-checkbox'
										multiple
										value={chart.restrictedTo ? chart.restrictedTo : []}
										onChange={(e) => {
											updateChartField(chartIndex, e.target.value, 'restrictedTo');
										}}
										input={<OutlinedInput label='Restricted To' />}
										renderValue={(selected) => selected.join(', ')}
										MenuProps={MenuProps}
									>
										{restrictedTo.map((name, index) => (
											<MenuItem key={index} value={name}>
												<Checkbox checked={chart.restrictedTo ? chart.restrictedTo.indexOf(name) > -1 : null} />
												<ListItemText primary={name} />
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Stack>

							{/* chart unique id */}
							{/* <TextField
								disabled
								label='Chart Unique ID'
								variant='outlined'
								size='small'
								margin='none'
								value={chart.chartUniqueID}
								onChange={(e) => updateChartField(chartIndex, e.target.value, 'chartUniqueID')}
								fullWidth
							/> */}

							{/* chart active */}
							<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
								<ToggleButtonGroup
									disabled={saving}
									color='success'
									exclusive
									value={chart?.active?.toString() || 'false'}
									onChange={(e) => updateChartField(chartIndex, e.target.value === 'true' ? true : false, 'active')}
									fullWidth
								>
									<ToggleButton value={'false'} key={'false'}>
										Not Active
									</ToggleButton>
									<ToggleButton value={'true'} key={'true'}>
										Active
									</ToggleButton>
								</ToggleButtonGroup>
								<Tooltip text='Select to make this chart active on the Dashboard page for users. Even if it is Not Active, it will still be available to send via Email.' />
							</Stack>

							{/* chart type */}
							<ToggleButtonGroup
								disabled={saving}
								color='success'
								exclusive
								value={chart.type || ''}
								onChange={(e) => {
									updateChartField(chartIndex, e.target.value, 'type');
								}}
								fullWidth
							>
								<ToggleButton value='Bar Chart'>Bar Chart</ToggleButton>
								<ToggleButton value='External Page'>External Page</ToggleButton>
								<ToggleButton value='Table'>Table</ToggleButton>
							</ToggleButtonGroup>

							{/* delete the chart */}
							<Box display='flex' justifyContent='center'>
								<Button
									color='error'
									variant='outlined'
									startIcon={<DeleteIcon />}
									onClick={() => {
										deleteChart(chartIndex);
									}}
								>
									Delete Chart
								</Button>
							</Box>
						</Stack>
					)}

					{/* data query settings */}
					{activeStep === 1 && (
						<Stack spacing={3}>
							{/* external link */}
							{chart.type === 'External Page' && (
								<Stack spacing={1}>
									<Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
										<Tooltip text='Enter the link to the external page.' />
										<TextField
											disabled={saving}
											label='External Link'
											name='value'
											variant='outlined'
											margin='none'
											value={chart?.ExternalLink || ''}
											onChange={(e) => {
												updateChartField(chartIndex, e.target.value, 'ExternalLink');
											}}
											fullWidth
										/>
									</Stack>

									<Stack direction={'row'} spacing={1} alignItems={'center'} pt={2} pb={2}>
										<Tooltip text='Select to add a unique identifier to the External Link.' />
										<FormControl sx={{ m: 1 }} fullWidth>
											<InputLabel id='unique-identifier' label='Unique Identifier'>
												Unique Identifier
											</InputLabel>
											<Select
												labelId='uniquie-identifier-label'
												id='unique-identifier-checkbox'
												value={chart.UniqueIdentifier ? chart.UniqueIdentifier : ''}
												onChange={(e) => {
													updateChartField(chartIndex, e.target.value, 'UniqueIdentifier');
												}}
												label='Unique Identifier'
											>
												<MenuItem value={'HashedNPI'}>HashedNPI</MenuItem>
											</Select>
										</FormControl>
									</Stack>
								</Stack>
							)}

							{/* chart data include phi? */}
							{chart.type !== 'External Page' && (
								<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
									<ToggleButtonGroup
										disabled={saving}
										color='success'
										exclusive
										value={chart.data || ''}
										onChange={(e) => {
											updateChartField(chartIndex, e.target.value, 'data');
										}}
										fullWidth
									>
										<ToggleButton value='Data' key='Data'>
											No PHI
										</ToggleButton>
										<ToggleButton value='Data-PHI' key='Data-PHI'>
											PHI
										</ToggleButton>
									</ToggleButtonGroup>
									<Tooltip text='Select which type of data you want to display.  PHI data is only available to individual users.' />
								</Stack>
							)}

							{/* query parameters (filter) */}
							{chart.type !== 'External Page' && (
								<Stack spacing={1}>
									{/* subsection title */}
									<Typography fontWeight='bold' align='center'>
										Filters
									</Typography>
									<Stack direction='row' spacing={1} alignItems='baseline'>
										{/* parameter */}
										<FormControl fullWidth>
											<InputLabel id='query parameters'>Query Parameters</InputLabel>
											<Select
												labelId='parameter'
												id='parameter'
												name='parameter'
												value={tempQueryParam?.parameter || ''}
												label='Query Parameters'
												onChange={handleQueryParamChange}
											>
												{queryFields.map((param, paramIndex) => {
													if (chart.data === 'Data') {
														if (param.phi !== true)
															return (
																<MenuItem value={param} key={paramIndex}>
																	{param.name}
																</MenuItem>
															);
													} else if (chart.data === 'Data-PHI') {
														return (
															<MenuItem value={param} key={paramIndex}>
																{param.name}
															</MenuItem>
														);
													}
												})}
											</Select>
										</FormControl>

										{/* operation */}
										<FormControl fullWidth>
											<InputLabel id='operation'>Operation</InputLabel>
											<Select
												labelId='operation'
												id='operation'
												name='operation'
												value={tempQueryParam?.operation || ''}
												label='Operation'
												onChange={handleQueryParamChange}
											>
												<MenuItem value='>'>{`>`}</MenuItem>
												<MenuItem value='>='>{`≥`}</MenuItem>
												<MenuItem value='=='>{`=`}</MenuItem>
												<MenuItem value='<'>{`<`}</MenuItem>
												<MenuItem value='<='>{`≤`}</MenuItem>
												<MenuItem value='!='>{`!=`}</MenuItem>
											</Select>
										</FormControl>

										{/* value */}
										{/* boolean type */}
										{tempQueryParam?.parameter?.type === 'Boolean' && (
											<ToggleButtonGroup
												value={tempQueryParam?.value || ''}
												exclusive
												onChange={(e, newValue) => setTempQueryParam({ ...tempQueryParam, value: newValue })}
												fullWidth
												size='large'
												color='success'
											>
												<ToggleButton value={'true'}>True</ToggleButton>
												<ToggleButton value={'false'}>False</ToggleButton>
											</ToggleButtonGroup>
										)}

										{/* date type */}
										{tempQueryParam?.parameter?.type === 'Date' && (
											<Stack spacing={1} width='100%'>
												<FormControlLabel
													control={
														<Checkbox
															checked={rollingTimeframe}
															onChange={(e) => {
																const newQueryParam = {
																	...tempQueryParam,
																	rollingValue: null,
																	rollingUnit: null,
																	rollingTimeframe: !tempQueryParam.rollingTimeframe,
																};
																setTempQueryParam(newQueryParam);
																setRollingTimeframe(e.target.checked);
															}}
														/>
													}
													label='Use Rolling Timeframe'
												/>
											</Stack>
										)}

										{/* number type */}
										{tempQueryParam?.parameter?.type === 'Number' && (
											<TextField
												type='number'
												label='Value'
												variant='outlined'
												name='value'
												margin='none'
												value={tempQueryParam?.value || ''}
												onChange={handleQueryParamChange}
												fullWidth
											/>
										)}

										{/* string type */}
										{tempQueryParam?.parameter?.type !== 'Boolean' &&
											tempQueryParam?.parameter?.type !== 'Number' &&
											tempQueryParam?.parameter?.type !== 'Date' && (
												<TextField
													disabled={saving}
													label='Value'
													name='value'
													variant='outlined'
													margin='none'
													value={tempQueryParam?.value || ''}
													onChange={handleQueryParamChange}
													fullWidth
												/>
											)}

										{/* tooltip for query parameters */}
										<Tooltip text='After selecting which Parameter, Operation, and Value should the data be filtered by, click the ADD FILTER button below.' />
									</Stack>

									{/* date selection */}
									<Stack direction='row' spacing={1} alignItems='center'>
										<TextField
											disabled={tempQueryParam?.parameter?.type !== 'Date' || !rollingTimeframe}
											type='number'
											label='Rolling Value'
											variant='outlined'
											margin='none'
											value={tempQueryParam?.rollingValue || ''}
											onChange={(e) => {
												const newQueryParam = {
													...tempQueryParam,
													rollingValue: e.target.value,
													rollingTimeframe: true,
												};
												setTempQueryParam(newQueryParam);
											}}
											fullWidth
										/>

										{/* date selection - time unit */}
										<FormControl fullWidth>
											<InputLabel id='rolling-unit'>Time Unit</InputLabel>
											<Select
												disabled={tempQueryParam?.parameter?.type !== 'Date' || !rollingTimeframe}
												labelId='rolling-unit'
												label='Time Unit'
												id='rolling-unit-select'
												value={tempQueryParam?.rollingUnit || ''}
												onChange={(e) => {
													const newQueryParam = {
														...tempQueryParam,
														rollingUnit: e.target.value,
														rollingTimeframe: true,
													};
													setTempQueryParam(newQueryParam);
												}}
											>
												<MenuItem value='Days'>Days</MenuItem>
												<MenuItem value='Weeks'>Weeks</MenuItem>
												<MenuItem value='Months'>Months</MenuItem>
												<MenuItem value='Years'>Years</MenuItem>
											</Select>
										</FormControl>

										{/* date selection - date picker */}
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												disabled={tempQueryParam?.parameter?.type !== 'Date' || rollingTimeframe}
												label='Date'
												value={
													tempQueryParam?.parameter?.type !== 'Date'
														? null
														: tempQueryParam?.value
														? dayjs(tempQueryParam?.value)
														: null
												}
												onChange={(newValue) => {
													const date = newValue.$d.toLocaleDateString();
													const e = { target: { value: date, name: 'value' } };
													handleQueryParamChange(e);
												}}
												sx={{
													width: '100%',
												}}
												components={{
													TextField: TextField, // Specifies TextField as the input component
												}}
												componentsProps={{
													textField: {
														label: 'Date',
													}, // Props for TextField
												}}
											/>
										</LocalizationProvider>

										{/* tooltip for date query parameters */}
										<Tooltip
											text={`If a Query Parameter of type Date is selected, this row becomes active automatically.  Selecting 'Use Rolling Timeframe' enables the Rolling Value and Time Unit options.  Otherwise, the Date field is available.`}
										/>
									</Stack>

									{/* add filter button for query parameters  */}
									<Container sx={{ display: 'flex', justifyContent: 'center' }}>
										<Button
											variant='contained'
											color='secondary'
											disabled={
												saving ||
												!tempQueryParam?.parameter ||
												!tempQueryParam?.operation ||
												(!tempQueryParam?.value &&
													tempQueryParam?.value === false &&
													(!tempQueryParam?.rollingValue || !tempQueryParam?.rollingUnit))
											}
											onClick={() => {
												addParameterItem(chartIndex, tempQueryParam);
											}}
										>
											ADD FILTER
										</Button>
									</Container>

									{/* display query parameters */}
									{dashboard?.charts?.[chartIndex]?.queryParameters?.map((param, paramIndex) => {
										if (param.type === 'orGroup') {
											// Render OR group
											return (
												<Stack
													key={paramIndex}
													spacing={1}
													p={1}
													sx={{
														border: '2px solid #71AB7B',
														borderRadius: '8px',
														backgroundColor: '#F2F8F3',
													}}
												>
													<Typography textAlign={'center'} variant='h6'>
														OR Group
													</Typography>

													{param.conditions.map((condition, i) => (
														<Stack
															key={i}
															direction='row'
															spacing={1}
															sx={{
																display: 'flex',
																justifyContent: 'center',
																width: '100%',
																alignItems: 'center',
																backgroundColor: i % 2 === 0 ? '#e4e4e4' : '#bfbfbf',
																borderRadius: '8px',
															}}
														>
															<Typography width='32%' textAlign='center'>
																{condition.parameter.name}
															</Typography>
															<Typography width='32%' textAlign='center'>
																{condition.operation}
															</Typography>
															<Typography width='32%' textAlign='center'>
																{condition.value}
															</Typography>
														</Stack>
													))}

													{/* Button to unpair the OR group */}
													<Box display={'flex'} justifyContent={'center'}>
														<Button
															variant='outlined'
															color='primary'
															onClick={() => unpairOrGroup(chartIndex, paramIndex)}
														>
															Unpair OR Group
														</Button>
													</Box>
												</Stack>
											);
										} else {
											// This is a single parameter
											return (
												<Fragment key={paramIndex}>
													{/* Include "OR" button between all items */}
													{paramIndex !== 0 && (
														<Box display={'flex'} justifyContent={'center'}>
															<Button
																variant='outlined'
																color='primary'
																onClick={() => linkParamsWithOr(chartIndex, paramIndex - 1)}
															>
																OR
															</Button>
														</Box>
													)}

													<Stack
														direction='row'
														spacing={1}
														sx={{
															display: 'flex',
															justifyContent: 'center',
															width: '100%',
															alignItems: 'center',
															backgroundColor: paramIndex % 2 === 0 ? '#f2f2f2' : '#f9f9f9',
															borderRadius: '8px',
														}}
													>
														<Typography width='32%' textAlign='center'>
															{param.parameter.name}
														</Typography>
														<Typography width='32%' textAlign='center'>
															{param.operation}
														</Typography>
														<Typography width='32%' textAlign='center'>
															{param.rollingTimeframe && `${String(param.rollingValue)} ${String(param.rollingUnit)}`}
															{!param.rollingTimeframe && String(param.value)}
														</Typography>
														<Tooltip text='Deletes this data filter.'>
															<IconButton
																color='error'
																onClick={() => {
																	setDashboard((prevState) => {
																		const dashboard = { ...prevState };
																		dashboard.charts[chartIndex].queryParameters.splice(paramIndex, 1);
																		return dashboard;
																	});
																}}
															>
																<DeleteIcon />
															</IconButton>
														</Tooltip>
													</Stack>
												</Fragment>
											);
										}
									})}
								</Stack>
							)}
						</Stack>
					)}

					{/* data display */}
					<>
						{/* bar chart settings */}
						{activeStep === 2 && chart.type === 'Bar Chart' && (
							<Stack spacing={3}>
								{/* group by */}
								<Stack spacing={1} sx={{ alignItems: 'center' }}>
									<Typography fontWeight={'bold'}>Group By</Typography>
									{(chart.barChart_groupBy || []).map((s, index) => (
										<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }} key={index} width={'100%'}>
											<FormControl fullWidth>
												<InputLabel id={`groupBy-label-${index}`}>Group By - {index + 1}</InputLabel>
												<Select
													labelId={`groupBy-label-${index}`}
													id={`groupBy-${index}`}
													label={`Group By - ${index + 1}`}
													value={s}
													onChange={(e) => {
														updateChartField(chartIndex, e.target.value, 'barChart_groupBy', index);
													}}
												>
													{/* Add this line to remove the selection */}
													<MenuItem value={null}>None</MenuItem>
													{queryFields.map((param, paramIndex) => {
														if (chart.data === 'Data') {
															if (param.phi !== true)
																return (
																	<MenuItem value={param.name} key={paramIndex}>
																		{param.name}
																	</MenuItem>
																);
														} else if (chart.data === 'Data-PHI') {
															return (
																<MenuItem value={param.name} key={paramIndex}>
																	{param.name}
																</MenuItem>
															);
														}
													})}
												</Select>
											</FormControl>
											<IconButton
												color='error'
												onClick={() => {
													removeSeries(chartIndex, 'barChart_groupBy', index);
												}}
											>
												<DeleteIcon />
											</IconButton>
										</Stack>
									))}
									<Button
										variant='outlined'
										onClick={() =>
											updateChartField(chartIndex, '', 'barChart_groupBy', (chart.barChart_groupBy || []).length)
										}
									>
										Add New Grouping
									</Button>
								</Stack>

								{/* calculation and calculation field */}
								<Stack spacing={1} sx={{ alignItems: 'center' }}>
									<Typography fontWeight={'bold'}>Calculation</Typography>
									{/* calculation */}
									<Stack direction={'row'} spacing={1} width={'100%'} alignItems={'center'}>
										<FormControl fullWidth>
											<InputLabel id='calculationLabel'>Calculation</InputLabel>
											<Select
												labelId='calculationLabel'
												id='calculation'
												label='calculation'
												value={chart.calculation || ''}
												onChange={(e) => {
													updateChartField(chartIndex, e.target.value, 'calculation');
												}}
											>
												{/* Add this line to remove the selection */}
												<MenuItem value={''}>None</MenuItem>
												<MenuItem value={'count'}>Count</MenuItem>
												<MenuItem value={'mean'}>Mean</MenuItem>
												<MenuItem value={'median'}>Median</MenuItem>
											</Select>
										</FormControl>
										<Tooltip text='Choose the calculation to perform.' />
									</Stack>

									{/* calculation field */}
									<Stack direction={'row'} spacing={1} width={'100%'} alignItems={'center'}>
										<FormControl fullWidth>
											<InputLabel id={`calculationFieldLabel`}>Calculation Field</InputLabel>
											<Select
												disabled={saving || chart.calculation === 'count'}
												labelId={`calculationFieldLabel`}
												id={`calculationField`}
												label={`Calculation Field`}
												value={chart.calculation === 'count' ? '' : chart.calculationField || ''}
												onChange={(e) => {
													updateChartField(chartIndex, e.target.value, 'calculationField');
												}}
											>
												{queryFields.map((param, paramIndex) => {
													if (chart.data === 'Data') {
														if (param.phi !== true)
															return (
																<MenuItem value={param.name} key={paramIndex}>
																	{param.name}
																</MenuItem>
															);
													} else if (chart.data === 'Data-PHI') {
														return (
															<MenuItem value={param.name} key={paramIndex}>
																{param.name}
															</MenuItem>
														);
													}
												})}
											</Select>
										</FormControl>
										<Tooltip text='Choose the field to perform the calculation on.' />
									</Stack>
								</Stack>
							</Stack>
						)}

						{/* table settings */}
						{activeStep === 2 && chart.type === 'Table' && (
							<Stack spacing={1}>
								{/* Display Date Range */}
								<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
									<FormControl fullWidth>
										<InputLabel id='display-date-range-label'>Display Date Range</InputLabel>
										<Select
											labelId='display-date-range-label'
											id='display-date-range'
											label='Display Date Range'
											value={chart.displayDateRange || ''}
											onChange={(e) => {
												updateChartField(chartIndex, e.target.value, 'displayDateRange');
											}}
										>
											<MenuItem value={'All Available'}>All Available</MenuItem>
											<MenuItem value={'Most Recent'}>Most Recent</MenuItem>
										</Select>
									</FormControl>
									<Tooltip text='Choose the range of dates to display in the table.' />
								</Stack>

								{/* Group Data By - First */}
								<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
									<FormControl fullWidth>
										<InputLabel id='group-data-by-first-label'>Group Data By - First</InputLabel>
										<Select
											labelId='group-data-by-first-label'
											id='group-data-by-first'
											label='Group Data By - First'
											value={chart.tableGroupDataByFirst || ''}
											onChange={(e) => {
												updateChartField(chartIndex, e.target.value, 'tableGroupDataByFirst');
											}}
										>
											{/* Add this line to remove the selection */}
											<MenuItem value={null}>None</MenuItem>
											{queryFields.map((param, paramIndex) => {
												if (chart.data === 'Data') {
													if (param.phi !== true)
														return (
															<MenuItem value={param.name} key={paramIndex}>
																{param.name}
															</MenuItem>
														);
												} else if (chart.data === 'Data-PHI') {
													return (
														<MenuItem value={param.name} key={paramIndex}>
															{param.name}
														</MenuItem>
													);
												}
											})}
										</Select>
									</FormControl>
									<Tooltip text='Choose how to group the data in the table.  The system will first group the data by this choice before other groupings.' />
								</Stack>

								{/* Group Data By - Second */}
								<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
									<FormControl fullWidth>
										<InputLabel id='group-data-by-second-label'>Group Data By - Second</InputLabel>
										<Select
											labelId='group-data-by-second-label'
											id='group-data-by-second'
											label='Group Data By - Second'
											value={chart.tableGroupDataBySecond || ''}
											onChange={(e) => {
												updateChartField(chartIndex, e.target.value, 'tableGroupDataBySecond');
											}}
										>
											{/* Add this line to remove the selection */}
											<MenuItem value={null}>None</MenuItem>
											{queryFields.map((param, paramIndex) => {
												if (chart.data === 'Data') {
													if (param.phi !== true)
														return (
															<MenuItem value={param.name} key={paramIndex}>
																{param.name}
															</MenuItem>
														);
												} else if (chart.data === 'Data-PHI') {
													return (
														<MenuItem value={param.name} key={paramIndex}>
															{param.name}
														</MenuItem>
													);
												}
											})}
										</Select>
									</FormControl>
									<Tooltip text='Choose how to group the data in the table.  The system will group the data by this choice second.' />
								</Stack>
							</Stack>
						)}
					</>

					{/* chart settings */}
					<>
						{/* bar chart  settings */}
						{activeStep === 3 && chart.type === 'Bar Chart' && (
							<Stack spacing={3}>
								{/* sidebar for selecting */}
								<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
									<ToggleButtonGroup
										disabled={saving}
										color='success'
										exclusive
										value={chart?.barChart_addSideBar?.toString() || 'false'}
										onChange={(e) =>
											updateChartField(chartIndex, e.target.value === 'true' ? true : false, 'barChart_addSideBar')
										}
										fullWidth
									>
										<ToggleButton value={'false'} key={'false'}>
											No Sidebar
										</ToggleButton>
										<ToggleButton value={'true'} key={'true'}>
											Add Sidebar
										</ToggleButton>
									</ToggleButtonGroup>
									<Tooltip text='Select if a sidebar should display for selecting which dataset should displayed.  Helpful for when there are large amounts of datasets, such as data sliced by provider.' />
								</Stack>

								{/* legend */}
								<Stack spacing={1}>
									<Typography mt={4} fontWeight={'bold'} textAlign={'center'}>
										Legend
									</Typography>
									<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
										<ToggleButtonGroup
											disabled={saving}
											color='success'
											exclusive
											value={chart?.barChart_includeLegend?.toString() || 'false'}
											onChange={(e) => {
												updateChartField(
													chartIndex,
													e.target.value === 'true' ? true : false,
													'barChart_includeLegend'
												);
												if (e.target.value === 'false') updateChartField(chartIndex, '', 'barChart_legendPosition');
											}}
											fullWidth
										>
											<ToggleButton value={'false'} key={'false'}>
												No Legend
											</ToggleButton>
											<ToggleButton value={'true'} key={'true'}>
												Add Legend
											</ToggleButton>
										</ToggleButtonGroup>
										<Tooltip text='Select if a legend should be included.' />
									</Stack>

									{/* legend position */}
									<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
										<ToggleButtonGroup
											disabled={saving || !chart?.barChart_includeLegend}
											color='success'
											exclusive
											value={chart?.barChart_legendPosition || ''}
											onChange={(e) => updateChartField(chartIndex, e.target.value, 'barChart_legendPosition')}
											fullWidth
										>
											<ToggleButton value={'top'}>Top</ToggleButton>
											<ToggleButton value={'right'}>Right</ToggleButton>
											<ToggleButton value={'bottom'}>Bottom</ToggleButton>
											<ToggleButton value={'left'}>Left</ToggleButton>
										</ToggleButtonGroup>
										<Tooltip text='Select the position for the legend.' />
									</Stack>
								</Stack>

								{/* reference line */}
								<Stack spacing={1}>
									<Typography mt={4} fontWeight={'bold'} textAlign={'center'}>
										Reference Line
									</Typography>
									<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
										<ToggleButtonGroup
											disabled={saving}
											color='success'
											exclusive
											value={chart?.barChart_referenceLine?.toString() || 'false'}
											onChange={(e) => {
												updateChartField(
													chartIndex,
													e.target.value === 'true' ? true : false,
													'barChart_referenceLine'
												);
											}}
											fullWidth
										>
											<ToggleButton value={'false'} key={'false'}>
												No Line
											</ToggleButton>
											<ToggleButton value={'true'} key={'true'}>
												Add Line
											</ToggleButton>
										</ToggleButtonGroup>
										<Tooltip text='Select if a reference line should be included.' />
									</Stack>

									{/* reference line settings */}
									<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
										<TextField
											fullWidth
											label='Line Thickness'
											disabled={saving || !chart?.barChart_referenceLine}
											type='number'
											inputProps={{ min: '1', step: '1' }} // allows only positive integer values
											value={chart?.barChart_referenceLineThickness || 1}
											onChange={(e) =>
												updateChartField(chartIndex, Number(e.target.value), 'barChart_referenceLineThickness')
											}
											variant='outlined'
											size='small'
										/>
										<TextField
											fullWidth
											label='Line Value'
											disabled={saving || !chart?.barChart_referenceLine}
											type='number'
											inputProps={{ min: '0', step: '1' }} // allows only positive integer values
											value={chart?.barChart_referenceLineValue || 1}
											onChange={(e) =>
												updateChartField(chartIndex, Number(e.target.value), 'barChart_referenceLineValue')
											}
											variant='outlined'
											size='small'
										/>
										<Stack direction={'row'} alignItems='center' spacing={1}>
											<Typography variant='body2'>Color:</Typography>
											<div
												onClick={() => setIsColorPickerVisible(true)}
												style={{
													width: 24,
													height: 24,
													backgroundColor: chart?.barChart_referenceLineColor || '#000000',
													border: '1px solid #ccc',
													cursor: 'pointer',
												}}
											/>
										</Stack>
										<Dialog open={isColorPickerVisible} onClose={() => setIsColorPickerVisible(false)}>
											<DialogTitle>
												Select Color
												<IconButton
													edge='end'
													color='inherit'
													onClick={() => setIsColorPickerVisible(false)}
													aria-label='close'
												>
													<CloseIcon />
												</IconButton>
											</DialogTitle>
											<DialogContent>
												<ColorPicker
													color={chart?.barChart_referenceLineColor || '#000000'}
													onChange={(color) => updateChartField(chartIndex, color.hex, 'barChart_referenceLineColor')}
												/>
											</DialogContent>
										</Dialog>
										<Tooltip text='Set the thickness of the reference line.' />
									</Stack>
								</Stack>

								{/* y axis max */}
								<Stack spacing={1}>
									<Typography mt={4} fontWeight={'bold'} textAlign={'center'}>
										Y Axis Max
									</Typography>

									<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
										<TextField
											fullWidth
											label='Y Axis Max'
											disabled={saving}
											type='number'
											inputProps={{ min: '0', step: '1' }} // allows only positive integer values
											value={chart?.barChart_yAxisMax || ''}
											onChange={(e) => updateChartField(chartIndex, Number(e.target.value), 'barChart_yAxisMax')}
											variant='outlined'
											size='small'
										/>
										<Tooltip text='Set the y-axis max.  If the data is larger than this, the chart will automatically scale to the data size.  If the data is less than the max then the chart will use this as the maximum y-axis.' />
									</Stack>
								</Stack>
							</Stack>
						)}

						{/* table settings */}
						{activeStep === 3 && chart.type === 'Table' && (
							<Stack spacing={1}>
								{/* settings for Table chart - table threshold */}
								<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
									<TextField
										disabled={saving || chart.type !== 'Table'}
										value={chart.tableThreshold || ''}
										onChange={(e) => {
											updateChartField(chartIndex, e.target.value, 'tableThreshold');
										}}
										label='Table Threshold'
										fullWidth
									/>
									<Tooltip text='For Tables only - turns the column display red if the number is greater than this.  Leave blank if not needed.' />
								</Stack>

								{/* settings for Table chart - table summary column */}
								<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
									<ToggleButtonGroup
										disabled={saving || chart.type !== 'Table'}
										color='success'
										exclusive
										value={chart.type !== 'Table' ? 'false' : chart.tableSummaryColumn || false ? 'true' : 'false'}
										onChange={(e) => {
											updateChartField(chartIndex, e.target.value === 'true' ? true : false, 'tableSummaryColumn');
										}}
										fullWidth
									>
										<ToggleButton value={'false'}>No Summary Column</ToggleButton>
										<ToggleButton value={'true'}>Summary Column</ToggleButton>
									</ToggleButtonGroup>
									<Tooltip text='For Tables only - displays a summary column on the table.' />
								</Stack>

								{/* settings for Table chart - table summary column title */}
								<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
									<TextField
										disabled={saving || chart.type !== 'Table' || !chart.tableSummaryColumn}
										value={chart.tableSummaryColumnTitle || ''}
										onChange={(e) => {
											updateChartField(chartIndex, e.target.value, 'tableSummaryColumnTitle');
										}}
										label='Summary Column Title'
										fullWidth
									/>
								</Stack>

								{/* settings for Table chart - summary column threshold */}
								<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
									<TextField
										disabled={saving || chart.type !== 'Table' || !chart.tableSummaryColumn}
										value={chart.tableSummaryColumnThreshold || ''}
										onChange={(e) => {
											updateChartField(chartIndex, e.target.value, 'tableSummaryColumnThreshold');
										}}
										label='Summary Column Threshold'
										fullWidth
									/>
									<Tooltip text='For Tables only - turns the summary column display red if the number is greater than this.  Leave blank if not needed.' />
								</Stack>

								{/* settings for Table chart - table summary column tooltip */}
								<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
									<TextField
										disabled={saving || chart.type !== 'Table' || !chart.tableSummaryColumn}
										value={chart.tableSummaryColumnTooltip || ''}
										onChange={(e) => {
											updateChartField(chartIndex, e.target.value, 'tableSummaryColumnTooltip');
										}}
										label='Summary Column Tooltip'
										fullWidth
									/>
								</Stack>
							</Stack>
						)}
					</>

					{/* preview chart */}
					<Box display={'flex'} justifyContent={'center'}>
						{/* preview bar chart */}
						{activeStep === 4 && chart.type === 'Bar Chart' && (
							<Chart_BarChart chart={chart} dashboard={{ id: dashboardID }} />
						)}

						{/* preview external page */}
						{activeStep === 4 && chart.type === 'External Page' && (
							<Chart_IFrame chart={chart} dashboard={{ id: dashboardID }} />
						)}

						{/* preview table */}
						{activeStep === 4 && chart.type === 'Table' && (
							<Chart_Table chart={chart} dashboard={{ id: dashboardID }} />
						)}
					</Box>
				</Grid>
			</Grid>
		</StepperUI>
	);
}
