import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, TextField, Tooltip } from '@mui/material';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';

export default function AddDeleteGroups(props) {
	const id = props.group.id;
	const [groupType, setGroupType] = useState('');

	//sets groupType - used to disable memberType for Single Positions
	useEffect(() => {
		props.allPositions.map((position) => {
			if (position.key === props.group.groupKey) {
				setGroupType(position.type);
			}
		});
	}, [props.group]);

	//updates the group change
	const handleGroupChange = (e) => {
		const event = {
			group: e.label,
			groupKey: e.groupKey,
			id: id,
		};
		props.onHandleGroupChange(event);
	};

	//updates the member type change
	const handleMemberTypeChange = (e) => {
		const event = {
			memberType: e,
			id: id,
		};
		props.onHandleMemberTypeChange(event);
	};

	//removes this group
	const handleRemoveGroup = () => {
		props.onHandleRemoveGroup(id);
	};

	return (
		<Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} mb={2}>
			<Autocomplete
				id='positionsAndGroups'
				options={props.allPositions}
				value={props.group.group}
				isOptionEqualToValue={(option, value) => option.groupKey === value.key}
				onChange={(e, newValue) => {
					const event = {
						label: newValue.label,
						groupKey: newValue.key,
						type: newValue.type,
					};
					handleGroupChange(event);
				}}
				sx={{ width: '55%' }}
				renderInput={(params) => <TextField {...params} label='Positions & Groups' />}
			/>
			<Box sx={{ width: '1%' }} />
			<Tooltip
				text="Select the type of member that this position is for the Position or Group.  'Single Position' is used for positions that only have one spot (i.e. President)."
				placement='left'
			>
				<Autocomplete
					id='memberType'
					options={['Chair', 'Ex-Officio', 'Non-Voting Member', 'Single Position', 'Voting Member']}
					value={groupType === 'position' ? 'Single Position' : props.group.memberType}
					// disabled={groupType === 'position' ? true : false}
					isOptionEqualToValue={(option, value) => option.memberType === value.label}
					onChange={(e, newValue) => {
						handleMemberTypeChange(newValue);
					}}
					sx={{ width: '40%' }}
					renderInput={(params) => <TextField {...params} label='Member Type' />}
				/>
			</Tooltip>
			<Box sx={{ width: '4%', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
				<RemoveCircleOutlineRoundedIcon onClick={handleRemoveGroup} />
			</Box>
		</Box>
	);
}
