import React, { useEffect, useState, Fragment, useMemo, useContext } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';

import { Box, Typography } from '@mui/material';

import PageCards from '../components/ui/PageCards';
import {MaterialReactTable} from 'material-react-table';
import AlertContext from '../components/ui/AlertContext';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';
import LoadingSpinner from '../components/ui/LoadingSpinner';

export default function PublicIDs() {
	const alertCtx = useContext(AlertContext);
	const claimsCtx = useContext(CustomClaimsContext)
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState(null);

	//table columns
	const columns = useMemo(
		() => [
			{
				id: 'name',
				accessorKey: 'name.fullName',
				header: 'Name',
			},
			{
				accessorKey: 'customSchemas.EPT_Details.PublicID',
				header: 'Public Directory ID with Link',
				Cell: ({ cell }) => {
					return (
						<a href={`https://portal.ept911.com/people/${cell.getValue()}`} target='_blank'>
							{cell.getValue()}
						</a>
					);
				},
			},
		],
		[]
	);

	//initial load - gets the data
	useEffect(() => {
		if (!data) {
			setIsLoading(true);
			const functions = getFunctions();
			const getMemberInfo = httpsCallable(functions, 'getUniqueIDs');
			getMemberInfo({ admin: claimsCtx?.claims?.admin }).then((result) => {
				if (result.data.code === 200) setData(result.data.data);
				else {
					alertCtx.setSeverity(result.data.status.toLowerCase());
					alertCtx.setMessage(result.data.message);
					alertCtx.setActive(true);
					setIsLoading(false);
				}
			});
		}
	}, []);

	//turns off loading once data set
	useEffect(() => {
		if (data) setIsLoading(false);
	}, [data]);

	//display if loading
	if (isLoading) {
		return (
			<PageCards>
				<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
					Public Directory Link
				</Typography>

				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Typography variant='subtitle1' textAlign='center' sx={{ maxWidth: '70%' }}>
						The links below take you to the public facing directory (does not require EPT log in) for each person in
						EPT. It displays generic EPT information, along with the persons mobile phone, position(s) within EPT, and
						their EPT email address if the person has given consent for those items to be shared.
					</Typography>
				</Box>

				<br />

				<LoadingSpinner />

				<br />
			</PageCards>
		);
	}

	return (
		<Fragment>
			<PageCards>
				<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
					Public Directory Link
				</Typography>

				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Typography variant='subtitle1' textAlign='center' sx={{ maxWidth: '70%' }}>
						The links below take you to the public facing directory (does not require EPT log in) for each person in
						EPT. It displays generic EPT information, along with the persons mobile phone, position(s) within EPT, and
						their EPT email address if the person has given consent for those items to be shared.
					</Typography>
				</Box>

				<br />

				{claimsCtx?.claims?.admin && (
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<MaterialReactTable
							muiTablePaperProps={{
								elevation: 0, //change the mui box shadow
								sx: {
									'& tr:nth-of-type(even)': {
										backgroundColor: '#f5f5f5', //stripe the rows, make even rows a darker color
									},
									maxWidth: '600px',
								},
							}}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									muiTableHeadCellProps: {
										align: 'center',
									},
									size: 120,
								},
							}}
							columns={columns}
							data={data}
							initialState={{
								showGlobalFilter: true,
								density: 'compact',
								expanded: true, //expand all groups by default
								pagination: { pageIndex: 0, pageSize: 25 },
								sorting: [{ id: 'name', asc: true }], //sort by group by default
							}}
						/>
					</Box>
				)}
			</PageCards>
			<br />
		</Fragment>
	);
}
