import { gapi } from 'gapi-script';

const loadGapiClient = () => {
  return new Promise((resolve) => {
    gapi.load('client', () => {
      resolve();
    });
  });
};

const GetOrgUnits = async (token) => {
  try {
    // Load the GAPI client if not already loaded
    if (!gapi.client || !gapi.client.request) {
      await loadGapiClient();
    }

    // Set the access token
    gapi.client.setToken({ access_token: token });

    // Make the API request
    const response = await gapi.client.request({
      method: 'GET',
      path: 'https://admin.googleapis.com/admin/directory/v1/customer/my_customer/orgunits',
      params: {
        type: 'all',
      },
    });

    return response.result;
  } catch (error) {
    console.error('Error in GetOrgUnits:', error?.result?.error?.message || error.message);
    throw new Error(error?.result?.error?.message || 'Unknown error occurred.');
  }
};

export default GetOrgUnits;
