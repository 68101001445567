import React from 'react';
import { Box, Typography, Chip, Stack, Container } from '@mui/material';

import PageCards from '../components/ui/PageCards';
import SendEmailAsTemplate from '../components/sendGrid/SendEmailAsTemplate';

export default function SendEmailAs() {
	return (
		<PageCards>
			<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
				Send Email As
			</Typography>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<Typography variant='subtitle1' textAlign='center' sx={{ maxWidth: '70%' }}>
					Select a group from the <b>From Group</b>, and fill in the other applicable fields, to send an email from the
					desired group.
				</Typography>
			</Box>

			<Container>
				<SendEmailAsTemplate />
			</Container>
		</PageCards>
	);
}
