/**
 * gets the value of a directory item
 * @param {*} item
 * @param {*} data
 */
export default function getDirectoryValue(item, data) {
	if (item.CustomSchema.trim().length > 0) {
		if (item.SubValue) {
			if (
				data &&
				data.customSchemas &&
				data.customSchemas[item.CustomSchema] &&
				data.customSchemas[item.CustomSchema][item.Value] &&
				data.customSchemas[item.CustomSchema][item.Value][item.SubValue]
			)
				return data.customSchemas[item.CustomSchema][item.Value][item.SubValue];
			else return '';
		} else {
			if (
				data &&
				data.customSchemas &&
				data.customSchemas[item.CustomSchema] &&
				data.customSchemas[item.CustomSchema][item.Value]
			)
				return data.customSchemas[item.CustomSchema][item.Value];
			else return '';
		}
	} else {
		if (item.SubValue) {
			if (data && data[item.Value] && data[item.Value][item.SubValue]) return data[item.Value][item.SubValue];
			else return '';
		} else {
			if (data && data[item.Value]) return data[item.Value];
			else return '';
		}
	}
}
