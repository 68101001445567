import React, { useContext } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import AuthContext from './authContext';

export default function SignOut(props) {
	const auth = getAuth();
	const authCtx = useContext(AuthContext);

	const signOutHandler = () => {
		try {
			signOut(auth);
			authCtx.logout();
		} catch (err) {
			console.error(err);
		}
	};

	return <div onClick={signOutHandler}>{props.children}</div>;
}
