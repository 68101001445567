import React, { Fragment } from 'react';
import OrgChartCard from './OrgChartCard';

import { db } from '../../App';

export default function OrgChartItem(props) {
	//passes down handleReload from Leadership.js
	const handleReload = () => {
		props.handleReload();
	};

	return (
		<Fragment>
			<ul>
				{props.positions.map((item) => (
					<li key={item.title}>
						<OrgChartCard
							position={item}
							allPositions={props.allPositions}
							db={db}
							handleReload={handleReload}
							tableData={props.tableData}
						/>

						{item.children?.length > 0 && (
							<OrgChartItem
								positions={item.children}
								handleReload={handleReload}
								allPositions={props.allPositions}
								db={db}
								tableData={props.tableData}
							/>
						)}
					</li>
				))}
			</ul>
		</Fragment>
	);
}
