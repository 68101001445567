import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';

import PageCards from '../ui/PageCards';
import { Alert, Box, Stack, Typography } from '@mui/material';
import LoadingSpinner from '../ui/LoadingSpinner.js';

const Verification = () => {
	const [verificationStatus, setVerificationStatus] = useState({});
	const [loading, setLoading] = useState(true);
	const UniqueVerificationId = useParams();

	useEffect(() => {
		if (UniqueVerificationId) {
			const functions = getFunctions();
			const verifyUser = httpsCallable(functions, 'verifyUser');

			verifyUser(UniqueVerificationId)
				.then((result) => {
					setLoading(false);
					if (result.data.success) {
						setVerificationStatus({
							status: 'success',
							message: 'Verification successful! You may close this window.',
						});
					} else {
						setVerificationStatus({ status: 'error', message: 'Verification failed.' });
					}
				})
				.catch((error) => {
					setLoading(false);
					console.error('Error calling verifyUser function:', error);
					setVerificationStatus({
						status: 'error',
						message: `An error occurred during verification. Please try again later. - ${error}`,
					});
				});
		} else {
			setVerificationStatus('No verification ID found in the URL.');
		}
	}, [UniqueVerificationId]);

	return (
		<PageCards>
			<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
				Verification Page
			</Typography>
			<Box justifyContent={'center'} display={'flex'}>
				{loading && (
					<Stack spacing={2}>
						<Typography variant='h6' textAlign={'center'}>
							Verifying...
						</Typography>
						<LoadingSpinner />
					</Stack>
				)}
				{!loading && verificationStatus?.status && (
					<Alert severity={verificationStatus.status}>{verificationStatus.message}</Alert>
				)}
			</Box>
		</PageCards>
	);
};

export default Verification;
