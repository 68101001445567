import React, { useState, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';

import { v4 as uuidv4 } from 'uuid';

import {
	Button,
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Stack,
	Typography,
	MenuItem,
	Select,
	InputLabel,
	OutlinedInput,
	ListItemText,
} from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

import Tooltip from '../ui/Tooltip';
import TextEditor from '../textEditor/TextEditor';
import AccordionUI from '../ui/AccordionUI';
import AreObjectsEqual from '../localFunctions/AreObjectsEqual';
import LoadingSpinner from '../ui/LoadingSpinner';
import Dashboard_Chart from './Dashboard_Chart';
import { db } from '../../App';
import { MenuProps, restrictedTo } from './RestrictedSettings';

export default function Dashboard_Charts(props) {
	const [dashboard, setDashboard] = useState(JSON.parse(JSON.stringify(props.dashboard || null)));
	const [originalDashboardData, setOriginalDashboardData] = useState(
		JSON.parse(JSON.stringify(props.dashboard || null))
	);
	const [queryFields, setQueryFields] = useState(null);
	const [equal, setEqual] = useState(null);
	const [saving, setSaving] = useState(false);
	const [tempQueryParam, setTempQueryParam] = useState(null);
	const [rollingTimeframe, setRollingTimeframe] = useState(false);

	/**
	 * sets the fields that can be queried off of
	 * uses the sheet(s) name and any fields expected on each sheet
	 */
	useEffect(() => {
		const temp = [];
		// console.log(props?.calculatedColumns);
		props?.expectedSheets?.map((sheet, sheetIndex) => {
			//gets the sheet names
			let exists = false;
			for (const item of temp) {
				if ('Sheet' === item.name) exists = true;
			}
			if (!exists) temp.push({ name: 'Sheet', phi: false, type: 'String' });

			//gets the expected fields
			sheet?.expectedFields?.map((field, fieldIndex) => {
				let exists = false;
				for (const item of temp) {
					if (field.name === item.name) exists = true;
				}
				if (!exists) temp.push(field);
			});

			//gets the calculated columns
			sheet?.calculatedColumns?.map((calcCol, colIndex) => {
				let exists = false;
				for (const item of temp) {
					if (calcCol.ruleName === item.name) exists = true;
				}
				if (!exists) temp.push({ ...calcCol, name: calcCol.ruleName });
			});
		});

		/**
		 * sorts the fields based on name
		 */
		temp.sort(function (a, b) {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});
		// console.log(temp);

		setQueryFields(temp);
	}, [props.expectedSheets]);

	/**
	 * checks if dashboard and originalDashboardData are equal
	 * if not equal, then allow save button to be active
	 */
	useEffect(() => {
		setEqual(AreObjectsEqual(dashboard, originalDashboardData));
	}, [dashboard, originalDashboardData]);

	/**
	 * updates the dashboards settings
	 * @param {*} newItem - The item to be updated.
	 * @param {string} field - The field to update.
	 */
	const updateDashboardSettings = (newItem, field) => {
		setDashboard((prevState) => {
			const dashboard = { ...prevState };
			dashboard[field] = newItem;
			return dashboard;
		});
	};


	/**
	 * deletes the chart
	 * @param {number} chartIndex
	 */
	const deleteChart = (chartIndex) => {
		setDashboard((prevState) => {
			const dashboard = { ...prevState };
			dashboard.charts.splice(chartIndex, 1);
			return dashboard;
		});
	};

	/**
	 * updates the chart fields
	 * @param {number} chartIndex - The index of the chart to update.
	 * @param {*} newItem - The new item.
	 * @param {string} field - The field to update.
	 * @param {number} arrayIndex - the index of the array to update
	 */
	const updateChartField = (chartIndex, newItem, field, arrayIndex = null) => {
		setDashboard((prevState) => {
			const dashboard = { ...prevState };
			if (arrayIndex !== null) {
				// If arrayIndex is provided, we assume it's a array update
				if (!dashboard.charts[chartIndex][field]) {
					dashboard.charts[chartIndex][field] = [];
				}
				dashboard.charts[chartIndex][field][arrayIndex] = newItem;
			} else {
				// General field update
				dashboard.charts[chartIndex][field] = newItem;
			}
			return dashboard;
		});
	};

	/**
	 *
	 * @param {number} chartIndex - The index of the chart to update.
	 * @param {*} field - the field to update
	 * @param {number} arrayIndex - index in the array to remove from the field
	 */
	const removeSeries = (chartIndex, field, arrayIndex) => {
		setDashboard((prevState) => {
			const dashboard = { ...prevState };
			if (dashboard.charts[chartIndex][field]) {
				dashboard.charts[chartIndex][field].splice(arrayIndex, 1);
			}
			return dashboard;
		});
	};

	/**
	 * adds to the parameter fields
	 * @param {number} chartIndex - The index of the chart to update.
	 * @param {object} newItem - The new object to add.
	 */
	const addParameterItem = (chartIndex, newItem) => {
		setDashboard((prevState) => {
			const dashboard = { ...prevState };
			if (!dashboard.charts[chartIndex].queryParameters) {
				dashboard.charts[chartIndex].queryParameters = [];
			}

			// Explicitly set the type to 'single'
			newItem.type = 'single';
			dashboard.charts[chartIndex].queryParameters.push(newItem);

			setTempQueryParam(null);

			return dashboard;
		});
	};

	const linkParamsWithOr = (chartIndex, paramIndex) => {
		setDashboard((prevState) => {
			const dashboard = { ...prevState };
			const queryParameters = dashboard.charts[chartIndex].queryParameters;

			// Extract the relevant parameters (could be single parameters or existing OR groups)
			const currentParam = queryParameters[paramIndex];
			const nextParam = queryParameters[paramIndex + 1];

			// Initialize an empty array to hold all conditions that will be part of the new OR group
			let allConditions = [];

			// Check if the current parameter is already an OR group
			if (currentParam.type === 'orGroup') {
				allConditions.push(...currentParam.conditions);
			} else {
				allConditions.push(currentParam);
			}

			// Check if the next parameter is an OR group
			if (nextParam.type === 'orGroup') {
				allConditions.push(...nextParam.conditions);
			} else {
				allConditions.push(nextParam);
			}

			// Create the new OR group
			const orGroup = {
				type: 'orGroup',
				conditions: allConditions,
			};

			// Remove the current and next parameters and insert the new OR group
			queryParameters.splice(paramIndex, 2, orGroup);

			// Reassign the modified queryParameters array back to the dashboard state
			dashboard.charts[chartIndex].queryParameters = queryParameters;

			return dashboard;
		});
	};

	const unpairOrGroup = (chartIndex, orGroupIndex) => {
		setDashboard((prevState) => {
			const dashboard = { ...prevState };
			const queryParameters = dashboard.charts[chartIndex].queryParameters;

			// Extract the individual conditions from the OR group
			const orGroup = queryParameters[orGroupIndex];
			const individualConditions = orGroup.conditions;

			// Remove the OR group and insert the individual conditions
			queryParameters.splice(orGroupIndex, 1, ...individualConditions);

			// Reassign the modified queryParameters array back to the dashboard state
			dashboard.charts[chartIndex].queryParameters = queryParameters;

			return dashboard;
		});
	};

	/**
	 * adds a new chart to dashboard
	 */
	const handleAddChart = () => {
		setDashboard((prevState) => {
			const dashboard = { ...prevState };
			const newChart = {
				id: `${dashboard.charts.length}`,
				title: 'New Chart',
				chartUniqueID: uuidv4(),
				restricted: false,
				restrictedTo: [],
			};
			dashboard.charts.push(newChart);
			return dashboard;
		});
	};

	/**
	 * sets the tempQueryParam
	 * @param {*} e
	 */
	const handleQueryParamChange = (e) => {
		// console.log(e.target);
		setTempQueryParam((prevState) => {
			const temp = { ...prevState };
			temp[e.target.name] = e.target.value;
			return temp;
		});
	};

	//saves changes to dashboard.dashboard
	const saveChanges = async () => {
		setSaving(true);
		const dashboardRef = doc(db, 'dashboards', props.dashboardID);
		await updateDoc(dashboardRef, { dashboard });
		setOriginalDashboardData(JSON.parse(JSON.stringify(dashboard)));
		setSaving(false);
	};

	return (
		<>
			{dashboard && queryFields && (
				<AccordionUI title='Dashboard Charts' subtitle='Open to adjust the visible charts' parent={true}>
					{/* settings */}
					<Stack display='flex' justifyContent='center'>
						{/* dashboard active */}
						<Stack direction={'row'} spacing={1} alignItems={'center'}>
							<Tooltip text='Select to make the Dashboard active on the Dashboard page for users.' />
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											disabled={saving}
											checked={dashboard.active || false}
											onChange={(e) => updateDashboardSettings(e.target.checked, 'active')}
										/>
									}
									label='Dashboard Active'
								/>
							</FormGroup>
						</Stack>

						{/* dashboard restricted */}
						<Stack direction={'row'} spacing={1} alignItems={'center'} pt={2} pb={2}>
							<Tooltip text='Select to make the Dashboard restricted to certain users.' />
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											disabled={saving}
											checked={dashboard.restricted || false}
											onChange={(e) => updateDashboardSettings(e.target.checked, 'restricted')}
										/>
									}
									label='Restrict Dashboard'
								/>
							</FormGroup>
							<FormControl sx={{ m: 1, width: 300 }} disabled={!dashboard.restricted}>
								<InputLabel id='restricted-to' label='Restricted To'>
									Restricted To
								</InputLabel>
								<Select
									labelId='restricted-to-label'
									id='restricted-to-checkbox'
									multiple
									value={dashboard.restrictedTo || []}
									onChange={(e) => {
										const value = e.target.value;
										updateDashboardSettings(value, 'restrictedTo');
									}}
									input={<OutlinedInput label='Restricted To' />}
									renderValue={(selected) => selected.join(', ')}
									MenuProps={MenuProps}
								>
									{restrictedTo.map((name, index) => (
										<MenuItem key={index} value={name}>
											<Checkbox checked={dashboard.restrictedTo ? dashboard.restrictedTo.indexOf(name) > -1 : null} />
											<ListItemText primary={name} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Stack>

						{/* dashboard info */}
						<AccordionUI title={`Dashboard Info`} subtitle={`Open to adjust the Dashboard Info`} parent={false}>
							<Typography>
								Dashboard Info
								<Tooltip text='Enter helpful information to be displayed to the users when viewing this Dashboard.' />
							</Typography>
							<TextEditor
								initialContent={dashboard.info || ''}
								disabled={saving}
								onChange={(e) => updateDashboardSettings(e.text, 'info')}
								// valid={true}
							/>
						</AccordionUI>

						<Box m={2}></Box>

						{/* charts */}
						{dashboard?.charts?.map((chart, chartIndex) => {
							return (
								<AccordionUI
									title={`${chart.type || 'Diagram'} - ${chart.title}`}
									subtitle={`Open to adjust settings`}
									parent={false}
									key={chartIndex}
								>
									<Dashboard_Chart
										dashboardID={props.dashboardID}
										chart={chart}
										saving={saving}
										updateChartField={updateChartField}
										removeSeries={removeSeries}
										chartIndex={chartIndex}
										deleteChart={deleteChart}
										tempQueryParam={tempQueryParam}
										handleQueryParamChange={handleQueryParamChange}
										queryFields={queryFields}
										setTempQueryParam={setTempQueryParam}
										rollingTimeframe={rollingTimeframe}
										setRollingTimeframe={setRollingTimeframe}
										addParameterItem={addParameterItem}
										dashboard={dashboard}
										setDashboard={setDashboard}
										equal={equal}
										saveChanges={saveChanges}
										props={props}
										linkParamsWithOr={linkParamsWithOr}
										unpairOrGroup={unpairOrGroup}
									/>
								</AccordionUI>
							);
						})}
					</Stack>

					{/* Add a New Chart */}
					<Box m={3} sx={{ display: 'flex', justifyContent: 'center' }}>
						<Tooltip text='Adds a new Chart' placement='right'>
							<Button
								variant='outlined'
								color='secondary'
								startIcon={<AddCircleOutlineRoundedIcon />}
								onClick={() => {
									handleAddChart();
								}}
							>
								NEW CHART
							</Button>
						</Tooltip>
					</Box>

					{/* Save Button */}
					<Box mt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
						{saving ? (
							<LoadingSpinner />
						) : (
							<Button
								disabled={equal}
								variant='contained'
								color='info'
								startIcon={<SaveRoundedIcon />}
								onClick={saveChanges}
							>
								SAVE CHART SETTINGS
							</Button>
						)}
					</Box>
				</AccordionUI>
			)}
		</>
	);
}
