import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import {
	Alert,
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import LoadingSpinner from '../ui/LoadingSpinner';
import Tooltip from '../ui/Tooltip';

export default function LeadershipModalEditPerson(props) {
	const [loading, setLoading] = useState(null);
	const [error, setError] = useState(null);
	const [peopleArray, setPeopleArray] = useState([]);
	const [newPosition, setNewPosition] = useState({ ...props.positionData });

	const handleCurrentPersonChange = (e) => {
		if (e === null) {
			setNewPosition((prevState) => ({
				...prevState,
				currentPerson: { ...prevState.currentPerson, label: '', email: '' },
			}));
		} else {
			setNewPosition((prevState) => ({
				...prevState,
				currentPerson: { ...prevState.currentPerson, label: e.label, email: e.email },
			}));
		}
	};

	const handleCurrentPersonDateChange = (e) => {
		setNewPosition((prevState) => ({
			...prevState,
			currentPerson: { ...prevState.currentPerson, [e.id]: e.value },
		}));
	};

	const handleFuturePersonChange = (e) => {
		if (e === null) {
			setNewPosition((prevState) => ({
				...prevState,
				futurePerson: { ...prevState.futurePerson, label: '', email: '' },
			}));
		} else {
			setNewPosition((prevState) => ({
				...prevState,
				futurePerson: { ...prevState.futurePerson, label: e.label, email: e.email },
			}));
		}
	};

	const handleFuturePersonDateChange = (e) => {
		setNewPosition((prevState) => ({
			...prevState,
			futurePerson: { ...prevState.futurePerson, [e.id]: e.value },
		}));
	};

	//gets list of people for leadership position
	useEffect(() => {
		setLoading(true);
		if (props.allPeople.title === 'Error') {
			setError(props.allPeople.message);
		} else {
			setPeopleArray(props.allPeople);
		}
		setLoading(null);
	}, [props]);

	//submits the person change, sends the email address associated with the change and sends the originalEmail if someone is removed
	const onSubmit = () => {
		props.submit({
			position: newPosition,
			oldPerson: { email: props.positionData.currentPerson.email, name: props.positionData.currentPerson.label },
			newPerson: { email: newPosition.currentPerson.email, name: newPosition.currentPerson.label },
		});
	};

	if (error) {
		return (
			<Dialog open={props.open} onClose={props.cancel} fullWidth>
				<DialogTitle sx={{ fontWeight: 'bold', fontSize: '2rem' }}>Error</DialogTitle>
				<DialogContent>
					<Alert severity='error' sx={{ margin: '1rem' }}>
						<strong>
							There was an error. Logout and log back in. If the error continues to occur, contact IT and provide the
							error message below.
						</strong>
					</Alert>
					<Alert severity='info' sx={{ margin: '1rem' }}>
						{error}
					</Alert>
				</DialogContent>
				<DialogActions>
					<Button variant='contained' onClick={props.cancel} color='error'>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	if (loading) {
		return (
			<Dialog open={props.open} onClose={props.cancel} fullWidth>
				<DialogTitle>{newPosition.positionTitle}</DialogTitle>
				<DialogContent>
					<LoadingSpinner />
				</DialogContent>
				<DialogActions>
					<Button variant='contained' onClick={props.cancel} color='cancel'>
						CANCEL
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<Dialog open={props.open} onClose={props.cancel} fullWidth scroll='body' PaperProps={{ sx: { minHeight: '30vh' } }}>
			<DialogTitle>Current {newPosition.positionTitle}</DialogTitle>
			<DialogContent>
				{/*current person */}
				<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
					<Tooltip text='Select the person who will work this position, if known.  Leave empty if unknown.' />
					<span>&nbsp;</span>
					<Autocomplete
						fullWidth
						ListboxProps={{ style: { maxHeight: '20rem' } }}
						id='currentPerson'
						options={peopleArray}
						sx={{ mt: '0.5rem', mb: '0.75rem' }}
						value={newPosition.currentPerson.email === '' ? null : newPosition.currentPerson}
						isOptionEqualToValue={(option, value) => option.email === value.email} // Compare using the unique email
						getOptionLabel={(option) => option.label} // Display the label in the input
						onChange={(e, newValue) => {
							handleCurrentPersonChange(newValue);
						}}
						renderInput={(params) => <TextField {...params} label='Person' />}
						renderOption={(props, option) => (
							<li {...props} key={option.email}>
								{option.label}
							</li>
						)}
					/>
				</Box>

				{/* start date & end date*/}
				<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
					<Tooltip text='Enter and Start Date and/or End Date if known.' />
					<span>&nbsp;</span>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label='Start Date'
							value={newPosition.currentPerson.startDate ? dayjs(newPosition.currentPerson.startDate) : null}
							onChange={(newValue) => {
								const event = {
									value: newValue === null ? null : newValue.$d,
									id: 'startDate',
								};
								handleCurrentPersonDateChange(event);
							}}
							components={{
								TextField: TextField, // Specifies TextField as the input component
							}}
							componentsProps={{
								textField: { fullWidth: true, label: 'Start Date' }, // Props for TextField
							}}
						/>
					</LocalizationProvider>
					<span>&nbsp;</span>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label='End Date'
							value={newPosition.currentPerson.endDate ? dayjs(newPosition.currentPerson.endDate) : null}
							onChange={(newValue) => {
								const event = {
									value: newValue === null ? null : newValue.$d,
									id: 'endDate',
								};
								handleCurrentPersonDateChange(event);
							}}
							components={{
								TextField: TextField, // Specifies TextField as the input component
							}}
							componentsProps={{
								textField: { fullWidth: true, label: 'End Date' }, // Props for TextField
							}}
						/>
					</LocalizationProvider>
				</Box>
			</DialogContent>

			<DialogTitle>Future {newPosition.positionTitle}</DialogTitle>
			<DialogContent>
				{/*future person */}
				<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
					<Tooltip text='Select the person who will work this position, if known.  Leave empty if unknown.' />
					<span>&nbsp;</span>
					<Autocomplete
						fullWidth
						ListboxProps={{ style: { maxHeight: '20rem' } }}
						id='futurePerson'
						options={peopleArray}
						sx={{ mt: '0.5rem', mb: '0.75rem' }}
						value={newPosition.futurePerson.email === '' ? null : newPosition.futurePerson}
						getOptionLabel={(option) => option.label} // Display the label in the input
						isOptionEqualToValue={(option, value) => option.email === value.email} // Compare using the unique email
						onChange={(e, newValue) => {
							handleFuturePersonChange(newValue);
						}}
						renderInput={(params) => <TextField {...params} label='Person' />}
						renderOption={(props, option) => (
							<li {...props} key={option.email}>
								{option.label}
							</li>
						)}
					/>
				</Box>

				{/* start date & end date*/}
				<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
					<Tooltip text='Enter and Start Date and/or End Date if known.' />
					<span>&nbsp;</span>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label='Start Date'
							value={newPosition.futurePerson.startDate ? dayjs(newPosition.futurePerson.startDate) : null}
							onChange={(newValue) => {
								const event = {
									value: newValue === null ? null : newValue.$d,
									id: 'startDate',
								};
								handleFuturePersonDateChange(event);
							}}
							components={{
								TextField: TextField, // Specifies TextField as the input component
							}}
							componentsProps={{
								textField: { fullWidth: true, label: 'Start Date' }, // Props for TextField
							}}
						/>
					</LocalizationProvider>
					<span>&nbsp;</span>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label='End Date'
							value={newPosition.futurePerson.endDate ? dayjs(newPosition.futurePerson.endDate) : null}
							onChange={(newValue) => {
								const event = {
									value: newValue === null ? null : newValue.$d,
									id: 'endDate',
								};
								handleFuturePersonDateChange(event);
							}}
							components={{
								TextField: TextField, // Specifies TextField as the input component
							}}
							componentsProps={{
								textField: { fullWidth: true, label: 'End Date' }, // Props for TextField
							}}
						/>
					</LocalizationProvider>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={props.cancel} color='cancel'>
					CANCEL
				</Button>
				<Button variant='contained' onClick={onSubmit}>
					SAVE EDITS
				</Button>
			</DialogActions>
		</Dialog>
	);
}
