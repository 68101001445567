import React, { Fragment, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Unstable_Grid2';
import { Container, Typography } from '@mui/material';

import PageCards from '../components/ui/PageCards';
import AuthContext from '../components/auth/authContext';

function Login() {
	const authCtx = useContext(AuthContext);
	const navigate = useNavigate();

	//takes the user to the desired destination if they were not originally signed in
	useEffect(() => {
		const fromLocation =
			(localStorage.getItem('fromLocation') && JSON.parse(localStorage.getItem('fromLocation')).from.pathname) || null;
		if (authCtx.isLoggedIn && fromLocation) {
			localStorage.removeItem('fromLocation');
			navigate(fromLocation, { replace: true });
		}
		//pushes user to home ('/') if logged in but no fromLocation
		else if (authCtx.isLoggedIn) navigate('/', { replace: true });
	}, [authCtx.isLoggedIn]);

	return (
		<Fragment>
			{/* if not logged in */}
			{!authCtx.isLoggedIn && (
				<Grid container spacing={1}>
					<Grid xs={12}>
						<PageCards>
							<Container>
								<Typography variant='h3' color='primary' m={2} fontWeight={400} textAlign={'center'}>
									EPT Portal
								</Typography>
								<Typography textAlign='center' m={2}>
									Sign In to Access Services
								</Typography>
							</Container>
						</PageCards>
						<br></br>
					</Grid>
				</Grid>
			)}

			{/* if logged in */}
			{authCtx.isLoggedIn && (
				<Grid container spacing={1}>
					<Grid xs={12}>
						<PageCards>
							<Container>
								<Typography variant='h3' color='primary' m={2} fontWeight={400}>
									EPT Portal
								</Typography>
								<Typography textAlign='center' m={2}>
									Go to the <a href='/'>Main Page</a> to access services.
								</Typography>
							</Container>
						</PageCards>
						<br></br>
					</Grid>
				</Grid>
			)}
		</Fragment>
	);
}

export default Login;
