import React, { useContext, useEffect, useState } from 'react';
import { collection, getDocs, doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {
	Alert,
	TextField,
	Button,
	Checkbox,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	Switch,
	FormControlLabel,
	Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import PageCards from '../ui/PageCards';
import UpdateMyInfo from './UpdateMyInfo';
import LoadingSpinner from '../ui/LoadingSpinner';
import Tooltip from '../ui/Tooltip';
import UserImage from '../ui/UserImage';
import AlertContext from '../ui/AlertContext';
import formatPhoneNumber from '../localFunctions/formatPhoneNumber';
import getDirectoryValue from '../localFunctions/getDirectoryValue';
import getDirectoryValueFromArray from '../localFunctions/getDirectoryValueFromArray';
import { db } from '../../App';

export default function MyInfo(props) {
	const auth = getAuth();
	const alertCtx = useContext(AlertContext);
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState(null);
	const [thumbnail, setThumbnail] = useState(null);
	const [formData, setFormData] = useState(null);
	const [formState, setFormState] = useState(false);
	const [positions, setPositions] = useState(null);
	const [checkboxChecked, setCheckboxChecked] = useState(false);
	const [showVerificationButton, setShowVerificationButton] = useState(false);

	//loads the data
	useEffect(() => {
		const getData = async () => {
			if (props.loadedData && (!formData || !thumbnail)) {
				setData(props.loadedData.adminResponse);
				setThumbnail(props.loadedData.peopleResponse);
			}
		};
		if (auth) getData();
	}, [props.loadedData, auth?.currentUser?.uid, formData, thumbnail, auth]);

	//loads the userVerification and showVerificationButton
	useEffect(() => {
		const getUserData = async () => {
			if (!positions && auth?.currentUser?.uid) {
				const docRef = doc(db, 'Users', auth.currentUser.uid);
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					const docData = docSnap.data();
					setPositions(docData?.Positions || {});

					const verificationTime = docData.UserVerification?.VerificationTime?.toDate();
					const sixMonthsAgo = new Date(Date.now() - 6 * 30 * 24 * 60 * 60 * 1000);

					if (
						data.orgUnitPath !== '/Miscellaneous' &&
						data.orgUnitPath !== '/Contractors' &&
						data.orgUnitPath !== '/Cloud Identity Users - Residents' &&
						data.orgUnitPath !== '/Cloud Identity Users - Others'
					) {
						if (docData.UserVerification && (!verificationTime || verificationTime < sixMonthsAgo)) {
							setShowVerificationButton(true);
						}
					} else {
						setShowVerificationButton(false);
					}
				} else {
					setPositions({});
				}
			}
		};
		if (data) getUserData();
	}, [data, positions, auth?.currentUser?.uid]);

	//stops loading after initial data load and gets DirectorySettings
	useEffect(() => {
		if (data && positions && isLoading) {
			async function getData() {
				const tempData = [];
				const querySnapshot = await getDocs(collection(db, 'DirectorySettings'));
				querySnapshot.forEach((doc) => {
					tempData.push({ ...doc.data(), ID: doc.id });
				});
				const sortedData = [...tempData].sort((a, b) => a.Order - b.Order);
				setFormData(sortedData);
				setIsLoading(false);
			}
			getData();
		}
	}, [data, positions, isLoading]);

	//handles form change
	const handleFormChange = (e, item, subIndex) => {
		let value = e;

		if (item?.FieldType === 'PHONE') {
			value = formatPhoneNumber(value);
		}

		//added because was getting ResizeObserver loop error from updating too fast
		setTimeout(() => {
			setData((prevData) => {
				const newData = { ...prevData };
				//if array
				if (item.Array) {
					if (item.CustomSchema.trim() > 0) {
						if (subIndex === null) {
							if (!newData.customSchemas || !newData.customSchemas[item.CustomSchema])
								newData.customSchemas = { [item.CustomSchema]: {} };
							if (!newData.customSchemas[item.CustomSchema][item.Value])
								newData.customSchemas[item.CustomSchema][item.Value] = [];
							newData.customSchemas[item.CustomSchema][item.Value].push({ type: item.Type, [item.SubValue]: value });
						} else {
							if (!newData.customSchemas || !newData.customSchemas[item.CustomSchema])
								newData.customSchemas = { [item.CustomSchema]: {} };
							if (!newData.customSchemas[item.CustomSchema][item.Value])
								newData.customSchemas[item.CustomSchema][item.Value] = [];
							newData.customSchemas[item.CustomSchema][item.Value][subIndex][item.SubValue] = value;
						}
					}
					//if not CustomSchema
					else {
						if (subIndex === null) {
							if (!newData[item.Value]) {
								newData[item.Value] = [];
								newData[item.Value].push({ type: item.Type, [item.SubValue]: value });
							} else newData[item.Value].push({ type: item.Type, [item.SubValue]: value });
						} else {
							if (!newData[item.Value]) {
								newData[item.Value] = [];
								newData[item.Value].push({ type: item.Type, [item.SubValue]: value });
							} else {
								newData[item.Value][subIndex][item.SubValue] = value;
							}
						}
					}
				}
				//if not an array
				else {
					if (item.CustomSchema.trim().length > 0) {
						if (item.SubValue) {
							if (!newData.customSchemas || !newData.customSchemas[item.CustomSchema])
								newData.customSchemas = { [item.CustomSchema]: {} };
							if (!newData.customSchemas[item.CustomSchema][item.Value])
								newData.customSchemas[item.CustomSchema][item.Value] = {};
							newData.customSchemas[item.CustomSchema][item.Value][item.SubValue] = value;
						} else {
							if (!newData.customSchemas || !newData.customSchemas[item.CustomSchema]) {
								newData.customSchemas = { [item.CustomSchema]: {} };
							}
							newData.customSchemas[item.CustomSchema][item.Value] = value;
						}
					}
					//if not CustomSchema
					else {
						if (item.SubValue) {
							if (!newData[item.Value]) newData[item.Value] = {};
							newData[item.Value][item.SubValue] = value;
						} else {
							newData[item.Value] = value;
						}
					}
				}
				// console.log(newData);
				return newData;
			});
		}, 100);
	};

	//check data validity
	useEffect(() => {
		if (data) {
			let valid = true;

			//if no address
			if (!data.addresses) valid = false;

			//check addresses
			if (data.addresses) valid = data.addresses.every((address) => address.formatted.trim() !== '');

			//must have a mobile phone
			let foundMobile = false;
			data.phones.forEach((phone) => {
				if (phone['type'] === 'mobile') foundMobile = true;
			});
			if (!foundMobile) valid = false;

			//check phone numbers
			if (data.phones) {
				data.phones.forEach((phone) => {
					if (/^[0-9()-\s]+$/.test(phone.value) && phone.value.replace(/[^0-9]/gi, '').length !== 10) valid = false;
				});
			}

			//check recovery email
			if (data.recoveryEmail) {
				const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{3})+$/;
				if (data.recoveryEmail.match(regex) === null) valid = false;
			} else {
				valid = false;
			}

			setFormState(valid);
		}
	}, [data]);

	//handles form submission
	const handleSubmit = async () => {
		setIsLoading(true);
		const response = await UpdateMyInfo(data);

		if (response === false) {
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage('Your info was <strong>not</strong> updated. Try again.');
			setIsLoading(false);
		} else {
			alertCtx.setActive(true);
			alertCtx.setSeverity('success');
			alertCtx.setMessage('Your info was updated successfully.');
			alertCtx.setTimer(10000);
			props.newInfo(response);
			setIsLoading(false);
		}
	};

	const handleCheckboxChange = (event) => {
		setCheckboxChecked(event.target.checked);
	};

	const handleVerification = async () => {
		// Ensure there is a logged-in user
		if (!auth.currentUser) {
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage('You must be logged in to verify information.');
			return;
		}

		const userDocRef = doc(db, 'Users', auth.currentUser.uid);

		try {
			// Update the VerificationTime in Firestore to the current server timestamp
			await updateDoc(userDocRef, {
				'UserVerification.VerificationTime': serverTimestamp(),
				'UserVerification.UniqueVerificationId': null,
			});

			// Hide the verification button as the user has just verified
			setShowVerificationButton(false);

			// Show a success message
			alertCtx.setActive(true);
			alertCtx.setSeverity('success');
			alertCtx.setMessage('Your information has been successfully verified.');
			alertCtx.setTimer(10000);
		} catch (error) {
			console.error('Failed to update verification time:', error);
			// Show an error message
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage('Failed to verify your information. Please try again later.');
		}
	};

	//if loading return loader
	if (isLoading)
		return (
			<PageCards>
				<Typography variant='h3' textAlign='center' color={'primary'}>
					My Info
				</Typography>
				<LoadingSpinner />
			</PageCards>
		);

	//if done loading
	return (
		<PageCards>
			<Typography variant='h3' textAlign='center' mb={2} color={'primary'}>
				My Info
			</Typography>
			<Stack direction='row' justifyContent='center'>
				<UserImage photoURL={thumbnail} name={data.name.fullName} />
			</Stack>

			{showVerificationButton && (
				<Stack
					spacing={2}
					justifyContent={'center'}
					alignContent={'center'}
					alignItems={'center'}
					style={{ border: '2px solid orange', borderRadius: '8px', padding: '10px', margin: '20px 0' }}
				>
					<Typography textAlign={'center'}>
						EPT requires that your contact information be verified every 6 months. Review the information below and make
						changes to the editable fields if necessary.
					</Typography>
					<Typography textAlign={'center'}>
						When done, click the SAVE button if changes were made, then attest that your information is correct by
						clicking the checkbox below and then the 'Verify Information' button.
					</Typography>

					<FormControlLabel
						control={<Checkbox checked={checkboxChecked} onChange={handleCheckboxChange} color='primary' />}
						label='I confirm that my information is correct.'
					/>

					<Button variant='contained' color='warning' disabled={!checkboxChecked} onClick={handleVerification}>
						Verify Information
					</Button>
				</Stack>
			)}

			{/* save button */}
			<Stack spacing={2} justifyContent='center' mt={3} mb={2} alignItems={'center'}>
				<Button
					variant='contained'
					startIcon={<SaveRoundedIcon />}
					color={formState ? 'saveButton' : 'error'}
					disabled={!formState}
					onClick={handleSubmit}
				>
					SAVE
				</Button>
				{!formState && (
					<Alert severity='warning'>
						Fill out the required elements - a home address, a recovery email, a mobile phone, and all other phone numbers must be valid.
					</Alert>
				)}
			</Stack>

			{/* form */}
			{formData &&
				formData.map((item, index) => {
					if (item.DisplayOnMyInfo) {
						if (item.Array) {
							let itemFound = getDirectoryValueFromArray(data, item);
							if (
								item.InputType === undefined ||
								item.InputType === null ||
								item.InputType === 'STRING' ||
								item.InputType === 'PHONE'
							) {
								return (
									<TextField
										key={index}
										disabled={!item.EditableOnMyInfo}
										margin='dense'
										id={item.Label}
										label={item.Label}
										fullWidth
										variant='outlined'
										color='secondary'
										value={itemFound.value}
										onChange={(e) => {
											handleFormChange(e.target.value, item, itemFound.index);
										}}
										type={'text'}
										InputLabelProps={{
											shrink: getDirectoryValueFromArray(data, item)?.value?.length > 0,
										}}
									/>
								);
							} else if (item.InputType === 'DATE') {
								return (
									<LocalizationProvider dateAdapter={AdapterDayjs} key={index}>
										<DatePicker
											disabled={!item.EditableOnMyInfo}
											label={item.Label}
											value={itemFound.value ? dayjs(itemFound.value) : null}
											onChange={(e) => {
												if (e === null) handleFormChange('', item);
												else {
													const newDate = `${e.$y}-${('0' + (e.$M + 1)).slice(-2)}-${('0' + e.$D).slice(-2)}`;
													handleFormChange(newDate, item, itemFound.index);
												}
											}}
											sx={{
												width: '100%',
												marginTop: '0.75rem',
												marginBottom: '0.5rem',
											}}
											components={{
												TextField: TextField,
											}}
											componentsProps={{
												textField: {
													label: item.Label,
												},
											}}
										/>
									</LocalizationProvider>
								);
							} else if (item.InputType === 'SELECTION') {
								return (
									<FormControl fullWidth margin='dense' key={index} disabled={!item.EditableOnMyInfo}>
										<InputLabel id='inputType'>{item.Label}</InputLabel>
										<Select
											color='secondary'
											fullWidth
											labelId={item.Label}
											id={item.Label}
											value={itemFound.value}
											label='Input Type'
											onChange={(e) => {
												handleFormChange(e.target.value, item, itemFound.index);
											}}
											margin='dense'
										>
											{item.DropDownChoices &&
												item.DropDownChoices.map((choice, choiceIndex) => {
													return (
														<MenuItem key={choiceIndex} value={choice}>
															{choice}
														</MenuItem>
													);
												})}
										</Select>
									</FormControl>
								);
							}
						} else {
							if (
								item.InputType === undefined ||
								item.InputType === null ||
								item.InputType === 'STRING' ||
								item.InputType === 'PHONE'
							) {
								return (
									<TextField
										key={index}
										disabled={!item.EditableOnMyInfo}
										margin='dense'
										id={item.Label}
										label={item.Label}
										fullWidth
										variant='outlined'
										color='secondary'
										value={getDirectoryValue(item, data)}
										onChange={(e) => {
											handleFormChange(e.target.value, item);
										}}
										type={'text'}
										InputLabelProps={{ shrink: getDirectoryValue(item, data).length > 0 }}
									/>
								);
							} else if (item.InputType === 'DATE') {
								return (
									<LocalizationProvider dateAdapter={AdapterDayjs} key={index}>
										<DatePicker
											disabled={!item.EditableOnMyInfo}
											label={item.Label}
											value={getDirectoryValue(item, data) ? dayjs(getDirectoryValue(item, data)) : null}
											onChange={(e) => {
												if (e === null) handleFormChange('', item);
												else {
													const newDate = `${e.$y}-${('0' + (e.$M + 1)).slice(-2)}-${('0' + e.$D).slice(-2)}`;
													handleFormChange(newDate, item);
												}
											}}
											sx={{ width: '100%', marginTop: '0.75rem', marginBottom: '0.5rem' }}
											components={{
												TextField: TextField,
											}}
											componentsProps={{
												textField: { label: item.Label },
											}}
										/>
									</LocalizationProvider>
								);
							} else if (item.InputType === 'BOOL') {
								return (
									<FormControlLabel
										key={index}
										control={
											<Switch
												disabled={!item.EditableOnMyInfo}
												color='secondary'
												checked={getDirectoryValue(item, data) === '' ? false : getDirectoryValue(item, data)}
												onChange={(event) => {
													const e = getDirectoryValue(item, data) === '' ? true : !getDirectoryValue(item, data);
													handleFormChange(e, item);
												}}
											/>
										}
										label={item.Label}
										labelPlacement='start'
									/>
								);
							} else if (item.InputType === 'SELECTION') {
								return (
									<FormControl fullWidth margin='dense' key={index} disabled={!item.EditableOnMyInfo}>
										<InputLabel id='inputType'>{item.Label}</InputLabel>
										<Select
											color='secondary'
											fullWidth
											labelId={item.Label}
											id={item.Label}
											value={getDirectoryValue(item, data)}
											label='Input Type'
											onChange={(e) => {
												handleFormChange(e.target.value, item);
											}}
											margin='dense'
										>
											{item.DropDownChoices &&
												item.DropDownChoices.map((choice, choiceIndex) => {
													return (
														<MenuItem key={choiceIndex} value={choice}>
															{choice}
														</MenuItem>
													);
												})}
										</Select>
									</FormControl>
								);
							}
						}
					}
				})}

			{/* Positions */}
			{positions &&
				Object.keys(positions).map((position, index) => {
					return (
						<TextField
							key={index}
							disabled
							margin='dense'
							id={position}
							label='Position'
							fullWidth
							variant='outlined'
							color='secondary'
							value={position}
						/>
					);
				})}
		</PageCards>
	);
}
