import React from 'react';
import classes from './AvatarInitials.module.css';
import Avatar from '@mui/material/Avatar';

export default function AvatarInitials(props) {
	const size = props.size + 'px';
	const fontSize = Number(props.size) / 50 + 'rem';

	if (props.name) {
		const initials =
			props.name.split(' ')[0].charAt(0).toUpperCase() + props.name.split(' ')[1].charAt(0).toUpperCase();
		return (
			<div
				className={classes.avatarElement}
				style={{ height: size, width: size, lineHeight: size, fontSize: fontSize }}
			>
				{initials}
			</div>
		);
	} else {
		return (
			<Avatar sx={{ width: size, height: size }} />
			// <div
			// 	className={classes.avatarElement}
			// 	style={{ height: size, width: size, lineHeight: size, fontSize: fontSize }}
			// ></div>
		);
	}
}
