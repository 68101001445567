import React, { useEffect, useState, useContext } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import { Box, FormGroup, FormControlLabel, Typography, Switch, Stack } from '@mui/material';

import AlertContext from '../ui/AlertContext';
import PageCards from '../ui/PageCards';
import LoadingSpinner from '../ui/LoadingSpinner';
import { db } from '../../App';

export default function PublicInfoConsent(props) {
	const auth = getAuth();
	const alertCtx = useContext(AlertContext);
	const [isLoading, setIsLoading] = useState(true);
	const [isUploading, setIsUploading] = useState(false);
	const [data, setData] = useState(null);
	const [publicInfoConsent, setPublicInfoConsent] = useState(null);
	const [publicInfoPositions, setPublicInfoPositions] = useState(null);
	const [link, setLink] = useState('');

	//gets info on member once logged in
	useEffect(() => {
		const getData = async () => {
			if (props.loadedData) if (!data) setData(props.loadedData.adminResponse);

			if (!publicInfoConsent && auth?.currentUser?.uid) {
				const docRef = doc(db, 'Users', auth.currentUser.uid);
				const docSnap = await getDoc(docRef);

				if (docSnap.exists()) {
					const data = docSnap.data();
					setPublicInfoConsent(data?.PublicInfoConsent || {});
					setPublicInfoPositions(data?.Positions || {});
					// console.log('Document data:', docSnap.data());
				} else {
					setPublicInfoConsent({});
					setPublicInfoPositions({});
					// docSnap.data() will be undefined in this case
					// console.log('No such document!');
				}
			}
		};
		if (auth) getData();
	}, [props.loadedData, auth?.currentUser?.uid]);

	//setIsLoading
	useEffect(() => {
		if (data && publicInfoConsent && publicInfoPositions && isLoading) {
			setIsLoading(false);
		}
	}, [data, publicInfoConsent, publicInfoPositions, isLoading]);

	const handleConsent = async (toggleName) => {
		setIsUploading(true);

		const newData = { ...publicInfoConsent };
		newData[toggleName] = !newData[toggleName];

		try {
			const ref = doc(db, 'Users', auth.currentUser.uid);
			await updateDoc(ref, { PublicInfoConsent: newData });

			setIsUploading(false);
		} catch (error) {
			setIsUploading(false);
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage(error);
			alertCtx.setTimer(10000);
			newData[toggleName] = !newData[toggleName]; //resets consent if it was not successfully saved
			console.log(error);
		}

		setPublicInfoConsent(newData);
	};

	const handlePositions = async (toggleName) => {
		setIsUploading(true);

		const newData = { ...publicInfoPositions };
		newData[toggleName].DisplayPublically = !newData[toggleName].DisplayPublically;

		try {
			const ref = doc(db, 'Users', auth.currentUser.uid);
			await updateDoc(ref, { Positions: newData });

			setIsUploading(false);
		} catch (error) {
			setIsUploading(false);
			alertCtx.setActive(true);
			alertCtx.setSeverity('error');
			alertCtx.setMessage(error);
			alertCtx.setTimer(10000);
			newData[toggleName].DisplayPublically = !newData[toggleName].DisplayPublically; //resets consent if it was not successfully saved
			console.log(error);
		}

		setPublicInfoPositions(newData);
	};

	//setLink
	useEffect(() => {
		if (data) setLink(`/people/${data.customSchemas.EPT_Details.PublicID}`);
	}, [data]);

	//if loading return loader...
	if (isLoading) {
		return (
			<PageCards>
				<Typography variant='h3' textAlign='center' color={'primary'}>
					Public Info Consent
				</Typography>
				<LoadingSpinner />
			</PageCards>
		);
	}

	//if done loading return...
	return (
		<PageCards>
			<Typography variant='h3' textAlign='center' mb={2} color={'primary'}>
				Public Info Consent
			</Typography>

			<Box sx={{ display: 'block', justifyContent: 'left' }}>
				{/* phone and email consents */}
				<FormGroup sx={{ display: 'block', justifyContent: 'left' }}>
					<Typography mt={2} mb={2} textAlign={'center'} fontWeight={'bold'}>
						Select which items you would like to be displayed on the public info page.
					</Typography>
					<Stack>
						<FormControlLabel
							control={
								<Switch
									checked={publicInfoConsent?.DisplayMobilePublically || false}
									disabled={isUploading}
									onChange={() => {
										handleConsent('DisplayMobilePublically');
									}}
									color='primary'
								/>
							}
							label='I consent to my mobile phone number being displayed.'
							labelPlacement='end'
						/>
						<FormControlLabel
							control={
								<Switch
									checked={publicInfoConsent?.DisplayEmailPublically || false}
									disabled={isUploading}
									onChange={() => {
										handleConsent('DisplayEmailPublically');
									}}
									color='primary'
								/>
							}
							label='I consent to my EPT email being displayed.'
							labelPlacement='end'
						/>
					</Stack>
				</FormGroup>

				{/* include positions */}
				<FormGroup sx={{ display: 'block', justifyContent: 'left' }}>
					<Typography mt={4} textAlign={'center'} fontWeight={'bold'}>
						Select which Positions you hold that you want displayed on the public info page.
					</Typography>
					<Stack>
						{publicInfoPositions &&
							Object.keys(publicInfoPositions).map((key) => {
								return (
									<FormControlLabel
										key={key}
										control={
											<Switch
												checked={publicInfoPositions[key].DisplayPublically || false}
												onChange={() => {
													handlePositions(key);
												}}
												color='primary'
											/>
										}
										disabled={isUploading}
										label={key}
										labelPlacement='end'
									/>
								);
							})}
					</Stack>
				</FormGroup>
			</Box>

			{isUploading && <LoadingSpinner />}

			{!isUploading && (
				<Typography textAlign='center' m={2}>
					You may view your public information{' '}
					<a href={link} target='_blank'>
						here
					</a>
					.
				</Typography>
			)}

			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<Typography variant='caption' m={2} textAlign='center'>
					At a minimum, all will have their Google profile photo, name, EPT phone number, EPT fax number, EPT address
					and EPT website displayed publically.
				</Typography>
			</Box>
		</PageCards>
	);
}
