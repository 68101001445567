import React, { useEffect, useState, Fragment, useContext } from 'react';
import { Stack, TextField, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import ArrowCircleUpSharpIcon from '@mui/icons-material/ArrowCircleUpSharp';
import ArrowCircleDownSharpIcon from '@mui/icons-material/ArrowCircleDownSharp';

import DirectoryCard from './DirectoryCard';
import LoadingSpinner from '../ui/LoadingSpinner';
import CustomClaimsContext from '../auth/CustomClaimsContext';

export default function DirectoryList(props) {
	// const [orderedList, setOrderedList] = useState([]);
	const [filteredList, setFilteredList] = useState([]);
	const [keyword, setKeyword] = useState('');
	const [enteredName, setEnteredName] = useState(''); // the value of the search field
	const claimsCtx = useContext(CustomClaimsContext)

	//set keyword
	const handleKeyword = (event) => {
		setKeyword(event.target.value);
		setEnteredName(event.target.value);
	};

	//filters list
	useEffect(() => {
		if (props.list) {
			if (props.orderBy === 'EMAIL') {
				if (keyword !== '') {
					const results = props.list.filter((member) => {
						// Use the toLowerCase() method to make it case-insensitive
						return member.email.toLowerCase().includes(keyword.toLowerCase());
					});
					setFilteredList(results);
				} else {
					// If the text field is empty, show all providers
					setFilteredList(props.list);
				}
			} else {
				if (keyword !== '') {
					const results = props.list.filter((member) => {
						// Use the toLowerCase() method to make it case-insensitive
						return member.name.toLowerCase().includes(keyword.toLowerCase());
					});
					setFilteredList(results);
				} else {
					// If the text field is empty, show all providers
					setFilteredList(props.list);
				}
			}
		}
	}, [props.list, props.orderBy, keyword]);

	//passed member from MemberCard passed to here, passes member up to Directory
	const handleSelection = (email) => {
		props.onMemberSelection(email);
	};

	//handle order by change
	const handleOrderByChange = (e) => {
		props.onOrderByChange(e.target.value);
	};

	//handle sort order change
	const handleSortOrderChange = (e) => {
		props.onSortOrderChange(e.target.value);
	};

	//handles member type change
	const handleMemberTypeChange = (e) => {
		props.onMemberTypeChange(e.target.value);
	};

	//if loading return loader...
	if (props.isLoading) {
		return (
			<Fragment>
				<Typography variant='h4' color={'primary'} textAlign='center'>
					Members
				</Typography>
				{/* search email account */}
				{props.orderBy === 'EMAIL' && (
					<TextField
						margin='dense'
						id='search'
						label='Search'
						fullWidth
						variant='outlined'
						color='secondary'
						value={enteredName}
						onChange={handleKeyword}
						placeholder='Search for email...'
						autoFocus
						onKeyUp={(e) => {
							if (e.key === 'Escape') {
								const event = {
									target: {
										value: '',
									},
								};
								handleKeyword(event);
							}
						}}
					/>
				)}

				{/* search by name */}
				{props.orderBy !== 'EMAIL' && (
					<TextField
						margin='dense'
						id='search'
						label='Search'
						fullWidth
						variant='outlined'
						color='secondary'
						value={enteredName}
						onChange={handleKeyword}
						placeholder='Search for name...'
						autoFocus
						onKeyUp={(e) => {
							if (e.key === 'Escape') {
								const event = {
									target: {
										value: '',
									},
								};
								handleKeyword(event);
							}
						}}
					/>
				)}

				{/* sorts the directory list */}
				<Stack direction='row' margin='dense' mt={1} mb={1} spacing={1}>
					<FormControl sx={{ width: '75%' }} size='small'>
						<InputLabel id='orderByLabel'>Sort</InputLabel>
						<Select
							labelId='orderByLabel'
							id='orderBy'
							value={props.orderBy}
							label='Sort'
							onChange={handleOrderByChange}
						>
							<MenuItem value={'EMAIL'}>Email</MenuItem>
							<MenuItem value={'GIVEN_NAME'}>First Name</MenuItem>
							<MenuItem value={'FAMILY_NAME'}>Last Name</MenuItem>
						</Select>
					</FormControl>

					<FormControl sx={{ width: '25%' }} size='small'>
						<Select id='sortBy' value={props.sortOrder} onChange={handleSortOrderChange}>
							<MenuItem value={'ASCENDING'}>
								<ArrowCircleUpSharpIcon fontSize='small' />
							</MenuItem>
							<MenuItem value={'DESCENDING'}>
								<ArrowCircleDownSharpIcon fontSize='small' />
							</MenuItem>
						</Select>
					</FormControl>
				</Stack>

				{/* filters the directory list */}
				<Stack mb={1}>
					<FormControl size='small'>
						<InputLabel id='filterLabel'>Filter</InputLabel>
						<Select
							labelId='filterLabel'
							id='memberType'
							value={props.memberType}
							label='Filter'
							onChange={handleMemberTypeChange}
						>
							<MenuItem value={'ALL'}>All</MenuItem>
							<MenuItem value={'APP'}>APP</MenuItem>
							<MenuItem value={'OFFICE'}>Office</MenuItem>
							<MenuItem value={'PHYSICIAN'}>Physician</MenuItem>
							<MenuItem value={'UNCLASSIFIED'}>Unclassified</MenuItem>
						</Select>
					</FormControl>
				</Stack>

				{/* view unsuspended members, suspended members, or deleted members */}
				{claimsCtx?.claims?.admin && (
					<Stack mb={1} mt={1}>
						<FormControl size='small'>
							<InputLabel id='selection'>Suspended or Deleted</InputLabel>
							<Select
								labelId='selection'
								id='selection'
								value={props.selection}
								label='Suspended or Deleted'
								onChange={(e, newValue) => {
									props.onSelectionChange(newValue.props.value);
								}}
							>
								<MenuItem value={'Not Suspended/Deleted'}>Not Suspended/Deleted</MenuItem>
								<MenuItem value={'Suspended'}>Suspended</MenuItem>
								<MenuItem value={'Deleted'}>Deleted</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				)}

				<br />

				<LoadingSpinner />
			</Fragment>
		);
	}

	//if done loading, then return this
	return (
		<Fragment>
			<Stack>
				<Typography variant='h4' color={'primary'} textAlign='center'>
					Members
				</Typography>
				{/* search email account */}
				{props.orderBy === 'EMAIL' && (
					<TextField
						margin='dense'
						id='search'
						label='Search'
						fullWidth
						variant='outlined'
						color='secondary'
						value={enteredName}
						onChange={handleKeyword}
						placeholder='Search for email...'
						autoFocus
						onKeyUp={(e) => {
							if (e.key === 'Escape') {
								const event = {
									target: {
										value: '',
									},
								};
								handleKeyword(event);
							}
						}}
					/>
				)}

				{/* search by name */}
				{props.orderBy !== 'EMAIL' && (
					<TextField
						margin='dense'
						id='search'
						label='Search'
						fullWidth
						variant='outlined'
						color='secondary'
						value={enteredName}
						onChange={handleKeyword}
						placeholder='Search for name...'
						autoFocus
						onKeyUp={(e) => {
							if (e.key === 'Escape') {
								const event = {
									target: {
										value: '',
									},
								};
								handleKeyword(event);
							}
						}}
					/>
				)}

				{/* sorts the directory list */}
				<Stack direction='row' margin='dense' mt={1} mb={1} spacing={1}>
					<FormControl sx={{ width: '75%' }} size='small'>
						<InputLabel id='orderByLabel'>Sort</InputLabel>
						<Select
							labelId='orderByLabel'
							id='orderBy'
							value={props.orderBy}
							label='Sort'
							onChange={handleOrderByChange}
						>
							<MenuItem value={'EMAIL'}>Email</MenuItem>
							<MenuItem value={'GIVEN_NAME'}>First Name</MenuItem>
							<MenuItem value={'FAMILY_NAME'}>Last Name</MenuItem>
						</Select>
					</FormControl>

					<FormControl sx={{ width: '25%' }} size='small'>
						<Select id='sortBy' value={props.sortOrder} onChange={handleSortOrderChange}>
							<MenuItem value={'ASCENDING'}>
								<ArrowCircleUpSharpIcon fontSize='small' />
							</MenuItem>
							<MenuItem value={'DESCENDING'}>
								<ArrowCircleDownSharpIcon fontSize='small' />
							</MenuItem>
						</Select>
					</FormControl>
				</Stack>

				{/* filters the directory list */}
				<Stack mb={1}>
					<FormControl size='small'>
						<InputLabel id='filterLabel'>Filter</InputLabel>
						<Select
							labelId='filterLabel'
							id='memberType'
							value={props.memberType}
							label='Filter'
							onChange={handleMemberTypeChange}
						>
							<MenuItem value={'ALL'}>All</MenuItem>
							<MenuItem value={'APP'}>APP</MenuItem>
							<MenuItem value={'OFFICE'}>Office</MenuItem>
							<MenuItem value={'PHYSICIAN'}>Physician</MenuItem>
							<MenuItem value={'UNCLASSIFIED'}>Unclassified</MenuItem>
						</Select>
					</FormControl>
				</Stack>

				{/* view unsuspended members, suspended members, or deleted members */}
				{claimsCtx?.claims?.admin && (
					<Stack mb={1} mt={1}>
						<FormControl size='small'>
							<InputLabel id='selection'>Suspended or Deleted</InputLabel>
							<Select
								labelId='selection'
								id='selection'
								value={props.selection}
								label='Suspended or Deleted'
								onChange={(e, newValue) => {
									props.onSelectionChange(newValue.props.value);
								}}
							>
								<MenuItem value={'Not Suspended/Deleted'}>Not Suspended/Deleted</MenuItem>
								<MenuItem value={'Suspended'}>Suspended</MenuItem>
								<MenuItem value={'Deleted'}>Deleted</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				)}

				{filteredList.map((member, key) => (
					<DirectoryCard
						key={key}
						id={member.id}
						member={member}
						name={member.name}
						givenName={member.givenName}
						familyName={member.familyName}
						email={member.email}
						photo={member.photo}
						suspended={member.suspended}
						memberType={member.memberType}
						mobile={member.mobile}
						onHandleSelection={handleSelection}
						orderBy={props.orderBy}
						deleted={member.deleted}
					/>
				))}
			</Stack>
		</Fragment>
	);
}
