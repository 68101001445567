import React, { useEffect, useState, Fragment, useContext } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import { Grid, Button, Stack } from '@mui/material';

import classes from '../components/leadership/OrgChartCard.module.css';
import CreatePositionModal from '../components/leadership/CreatePositionModal';
import OrgChartItem from '../components/leadership/OrgChartItem';
import GetOrgTree from '../components/leadership/GetOrgTree';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import { db } from '../App';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';

export default function OrgChart() {
	const claimsCtx = useContext(CustomClaimsContext);
	const [displayCreatePositionModal, setDisplayCreatePositionModal] = useState(null);
	const [allPositions, setAllPositions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingTableData, setLoadingTableData] = useState(false);
	const [reload, setReload] = useState(false);
	const [tableData, setTableData] = useState([]);

	//gets the leadership positions & sets tableData
	useEffect(() => {
		if (db && !reload) {
			const getData = async () => {
				setLoadingTableData(true);
				setTableData([]);

				const tableData = [];
				const querySnapshot = await getDocs(collection(db, 'leadership'));
				querySnapshot.forEach((document) => {
					let data = document.data();
					if (data.currentPerson.startDate && data.currentPerson.startDate !== null) {
						data.currentPerson.startDate = data.currentPerson.startDate.toDate();
					}
					if (data.currentPerson.endDate && data.currentPerson.endDate !== null) {
						data.currentPerson.endDate = data.currentPerson.endDate.toDate();
					}
					if (data.futurePerson.startDate && data.futurePerson.startDate !== null) {
						data.futurePerson.startDate = data.futurePerson.startDate.toDate();
					}
					if (data.futurePerson.endDate && data.futurePerson.endDate !== null) {
						data.futurePerson.endDate = data.futurePerson.endDate.toDate();
					}
					if (data.active) {
						tableData.push({ id: document.id, ...data });
					}
				});

				setTableData(() => tableData);
				setLoadingTableData(false);
			};

			getData();
		}
	}, [db, reload]);

	//gets the positions & groups; sets allPositions
	useEffect(() => {
		if (db && !reload) {
			const getData = async () => {
				setIsLoading(true);
				// const data = await getRootData();

				const data = await GetOrgTree('org_chart', db);

				// console.log(data);
				setAllPositions(data);
				setIsLoading(false);
			};

			getData();
		}
	}, [db, reload]);

	//button for adding a position or a group
	const handleCreatePositionButton = () => {
		setDisplayCreatePositionModal(true);
	};

	//cancels the modal
	const cancelModal = () => {
		setDisplayCreatePositionModal(null);
	};

	//handles the page reloading when a change is made
	const handleReload = () => {
		setReload(true);
		setTimeout(() => {
			setReload(false);
		}, 100);
	};

	//display if loading
	if (isLoading || loadingTableData) {
		return <LoadingSpinner />;
	}

	return (
		<Fragment>
			{displayCreatePositionModal && (
				<CreatePositionModal
					open={displayCreatePositionModal}
					onClose={cancelModal}
					db={db}
					onReload={handleReload}
					allPositions={allPositions}
				/>
			)}

			<Grid sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
				{claimsCtx?.claims?.admin && (
					<Stack direction='row' spacing={2} justifyContent='right'>
						<Button variant='contained' color='saveButton' onClick={handleCreatePositionButton}>
							ADD POSITION
						</Button>
					</Stack>
				)}
			</Grid>
			<div className={classes.orgTree}>
				<OrgChartItem
					allPositions={allPositions}
					positions={allPositions}
					handleReload={handleReload}
					db={db}
					tableData={tableData}
				/>
			</div>
		</Fragment>
	);
}
