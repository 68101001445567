import React from 'react';
import { Stack, Chip, Box } from '@mui/material';

export default function WETColumnsTotalBiannual(props) {
	const row = props.row;
	const year = props.year;
	const firstYearMonth = `${year}-02`;
	const secondYearMonth = `${year}-03`;
	const thirdYearMonth = `${year}-04`;
	const fourthYearMonth = `${year}-05`;
	const fifthYearMonth = `${year}-06`;
	const sixthYearMonth = `${year}-07`;
	const seventhYearMonth = `${year}-08`;
	const eighthYearMonth = `${year}-09`;
	const ninthYearMonth = `${year}-10`;
	const tenthYearMonth = `${year}-11`;
	const eleventhYearMonth = `${year}-12`;
	const twelvethYearMonth = `${year + 1}-01`;

	const monthArray = [
		firstYearMonth,
		secondYearMonth,
		thirdYearMonth,
		fourthYearMonth,
		fifthYearMonth,
		sixthYearMonth,
		seventhYearMonth,
		eighthYearMonth,
		ninthYearMonth,
		tenthYearMonth,
		eleventhYearMonth,
		twelvethYearMonth,
	];

	let totalHours = 0;
	let allottedHours = 0;
	for (const month of monthArray) {
		if (row.original.tickLogs[`${month}`] && row.original.tickLogs[`${month}`].length > 0) {
			for (const log of row.original.tickLogs[`${month}`]) {
				totalHours += Number(log.totalHours);
				allottedHours += Number(log.allottedHours);
			}
		}
	}

	totalHours = Math.round(totalHours * 10) / 10;
	allottedHours = Math.round(allottedHours * 10) / 10;

	return (
		<Box display='flex' justifyContent='center'>
			<Stack direction='row' spacing={1}>
				<Chip
					label={`${
						allottedHours === 0
							? 'N/A'
							: `${(totalHours * 10) / 10}  of
							 ${(allottedHours * 10) / 10} hours`
					}`}
					variant={allottedHours === 0 ? 'outlined' : 'filled'}
					color={
						allottedHours === 0
							? 'primary'
							: (totalHours / allottedHours) * 100 < 90
							? 'lessThan90Percent'
							: (totalHours / allottedHours) * 100 < 100
							? 'within10Percent'
							: 'greaterThan100Percent'
					}
					sx={{
						display: 'flex',
						minHeight: '2rem',
						height: 'auto',
						justifyContent: 'center',
						textAlign: 'center',
						alignItems: 'center',
						'& .MuiChip-label': {
							display: 'flex',
							wordWrap: 'normal',
							whiteSpace: 'normal',
							textOverflow: 'clip',
							textAlign: 'center',
							justifyContent: 'center',
							width: '100px',
						},
						padding: '2px',
					}}
				/>
			</Stack>
		</Box>
	);
}
