import React, { useEffect, useState } from 'react';
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	InputLabel,
	InputAdornment,
	MenuItem,
	FormControl,
	Select,
	TextField,
} from '@mui/material';

import LoadingSpinner from '../ui/LoadingSpinner';
import Tooltip from '../ui/Tooltip';

export default function LeadershipModalEditPosition(props) {
	const [loading, setLoading] = useState(null);
	const [groups, setGroups] = useState([]);
	const [newPosition, setNewPosition] = useState({ ...props.positionData });

	//handles changes
	const handleChange = (e) => {
		setNewPosition((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}));
	};

	//gets the list of groups
	useEffect(() => {
		setLoading(true);
		const groupData = [];
		props.tableData.forEach((position) => {
			if (position.group !== undefined) {
				let found = false;
				for (let i = 0; i < groupData.length; i++) {
					if (position.group === groupData[i]) {
						found = true;
					}
				}
				if (found === false) {
					groupData.push(position.group);
				}
			}
		});
		setGroups(groupData);
		setLoading(null);
	}, [props.tableData]);

	//submits the newPosition
	const onSubmit = () => {
		props.submit({
			position: newPosition,
			originalEmail: props.positionData.currentPerson.email,
		});
	};

	if (loading) {
		return (
			<Dialog open={props.open} onClose={props.cancel} fullWidth>
				<DialogTitle>Edit Position</DialogTitle>
				<DialogContent>
					<LoadingSpinner />
				</DialogContent>
				<DialogActions>
					<Button variant='contained' onClick={props.cancel} color='cancel'>
						CANCEL
					</Button>
					{/* <Button onClick={props.cancelModal}>Create</Button> */}
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<Dialog open={props.open} onClose={props.cancel} fullWidth scroll='body' PaperProps={{ sx: { minHeight: '5vh' } }}>
			<DialogTitle>Edit Position</DialogTitle>
			<DialogContent>
				{/* position title */}
				{newPosition.active && (
					<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
						<Tooltip text='Title of the position to create.' />
						<span>&nbsp;</span>
						<TextField
							id='positionTitle'
							label='Position Title'
							fullWidth
							margin='normal'
							variant='outlined'
							defaultValue={newPosition.positionTitle}
							onChange={handleChange}
							required
						/>
					</Box>
				)}

				{/* grouping */}
				{newPosition.active && (
					<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
						<Tooltip text='If applicable, select a group for the position.  A new group can be entered if needed.  The grouping only applies to how the position is displayed on the Leadership Positions table.' />
						<span>&nbsp;</span>
						<Autocomplete
							fullWidth
							id='group'
							options={groups}
							freeSolo
							sx={{ mt: '0.5rem', mb: '0.75rem' }}
							value={newPosition.group}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Group'
									InputProps={{
										...params.InputProps,
										type: 'search',
									}}
								/>
							)}
							onInputChange={(e, newValue) => {
								const event = {
									target: {
										value: newValue.slice(0, 1).toUpperCase() + newValue.slice(1),
										id: 'group',
									},
								};
								handleChange(event);
							}}
						/>
					</Box>
				)}

				{/*compensation type */}
				{newPosition.active && (
					<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
						<Tooltip text='Select the compensation type.  Select "None" if there is no compensation.' />
						<span>&nbsp;</span>
						<FormControl fullWidth required>
							<InputLabel id='compensationType'>Compensation Type</InputLabel>
							<Select
								displayEmpty
								labelId='compensationType'
								id='compensationType'
								value={newPosition.compensationType}
								label='Compensation Type'
								onChange={(e) => {
									const event = {
										target: {
											value: e.target.value,
											id: 'compensationType',
										},
									};

									handleChange(event);
								}}
								sx={{ mt: '0.5rem', mb: '0.5rem' }}
							>
								<MenuItem value={'None'}>None</MenuItem>
								<MenuItem value={'CAT'}>CAT</MenuItem>
								<MenuItem value={'Stipend'}>Stipend</MenuItem>
								<MenuItem value={'WET'}>WET</MenuItem>
							</Select>
						</FormControl>
					</Box>
				)}

				{/* compensation amount */}
				{newPosition.active && newPosition.compensationType !== '' && newPosition.compensationType !== 'None' && (
					<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
						<Tooltip text='Enter the amount of compensation for the position.' />
						<span>&nbsp;</span>
						<TextField
							id='compensationAmount'
							label='Compensation Amount'
							fullWidth
							margin='dense'
							variant='outlined'
							color='secondary'
							defaultValue={newPosition.compensationAmount}
							onChange={handleChange}
							required
							type='number'
							InputProps={{
								endAdornment:
									newPosition.compensationType !== 'Stipend' ? (
										<InputAdornment position='end'>hours per month</InputAdornment>
									) : (
										<InputAdornment position='end'>per month</InputAdornment>
									),
								startAdornment:
									newPosition.compensationType === 'Stipend' ? <InputAdornment position='start'>$</InputAdornment> : '',
							}}
						/>
					</Box>
				)}

				{/* tick  project id */}
				{newPosition.active && (
					<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
						<Tooltip text='Enter the Tick project ID, if known.' />
						<span>&nbsp;</span>
						<TextField
							id='tickProjectID'
							label='Tick Project ID'
							fullWidth
							margin='dense'
							variant='outlined'
							value={newPosition.tickProjectID}
							onChange={handleChange}
						/>
					</Box>
				)}

				{/* active */}
				<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
					<Tooltip text='Uncheck this box to deactive this position.' />
					<span>&nbsp;</span>
					<FormControlLabel
						control={
							<Checkbox
								checked={newPosition.active}
								onChange={(e) => {
									const event = {
										target: {
											value: !newPosition.active,
											id: 'active',
										},
									};

									handleChange(event);
								}}
							/>
						}
						label={newPosition.active ? 'Active' : 'Inactive'}
						labelPlacement='end'
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button variant='contained' onClick={props.cancel} color='cancel'>
					CANCEL
				</Button>
				<Button variant='contained' onClick={onSubmit}>
					SAVE EDITS
				</Button>
			</DialogActions>
		</Dialog>
	);
}
