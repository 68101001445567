import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../App';

/**
 * gets Firestore document data if it exists
 * @param {string} path to the Firestore document, beginning with the collection
 * @returns an object with status, message, and data
 */
const getFirestoreDocument = async (path) => {
	const docRef = doc(db, path);
	const docSnap = await getDoc(docRef);

	if (docSnap.exists()) {
		return { status: 'success', message: 'Data obtained.', data: docSnap.data() };
	} else {
		// docSnap.data() will be undefined in this case
		return { status: 'error', message: 'No such document!', data: null };
	}
};

export default getFirestoreDocument;
