import React from 'react';
import classes from './DirectoryCard.module.css';
import AvatarSuspended from '../ui/AvatarSuspended';
import AvatarDeleted from '../ui/AvatarDeleted';
import UserImage from '../ui/UserImage';
import { Stack, Box } from '@mui/material';

export default function DirectoryCard(props) {
	//passes selected member up to MemberList
	const selectMember = () => {
		props.onHandleSelection(props.email);
	};

	//stops event propogation for clicking on the file attachments in a fileCard
	const stopPropagation = (event) => {
		event.stopPropagation();
	};

	//if order by is First Name
	if (props.orderBy === 'GIVEN_NAME') {
		return (
			<div className={classes.container} onClick={selectMember}>
				<div className={classes.justify}>
					<Box
						display={'flex'}
						alignContent={'center'}
						alignItems={'center'}
						margin={'auto'}
					>
						{/* if suspended then display suspended avatar */}
						{props.suspended ? <AvatarSuspended /> : ''}

						{/* if deleted then display deleted avatar */}
						{props.deleted ? <AvatarDeleted /> : ''}

						{/* if not suspended and not deleted and have a photo, display photo, else display initials avatar */}
						{!props.suspended && !props.deleted ? (
							<UserImage photoURL={props.photo} name={props.name} size={100} />
						) : (
							''
						)}
					</Box>

					<div className={classes.memberCard}>
						<div className={classes.memberName}>{props.name}</div>
						{props.memberType ? (
							<div className={classes.memberType}>
								{props.memberType}
								{props.member.memberType === 'Office' &&
									props.member.officePosition !== '' &&
									` - ${props.member.officePosition}`}
							</div>
						) : (
							<div>
								<br></br>
							</div>
						)}
						{/* <div className={classes.infoGroup}> */}
						<Stack sx={{ display: 'block', justifyContent: 'left', fontWeight: '500' }}>
							{/* <div className={classes.memberInfo}> */}
							<Box m={1} sx={{ fontSize: '0.8rem' }}>
								Mobile: <a href={`tel:${props.mobile}`}>{props.mobile}</a>
							</Box>
							{/* </div> */}
							{/* <div className={classes.memberEmail}> */}
							<Box m={1} sx={{ fontSize: '0.8rem' }}>
								Email:&#160;
								<a
									href={`mailto:${props.email}`}
									target='_blank'
									rel='noreferrer'
									onClick={stopPropagation}
									className={classes.email}
								>
									{props.email}
								</a>
								{/* </div> */}
							</Box>
						</Stack>
						{/* </div> */}
					</div>
				</div>
			</div>
		);
	}

	//if order by is Last Name
	if (props.orderBy === 'FAMILY_NAME') {
		return (
			<div className={classes.container} onClick={selectMember}>
				<div className={classes.justify}>
					<div className={classes.imgContainer}>
						{/* if suspended then display suspended avatar */}
						{props.suspended ? <AvatarSuspended /> : ''}

						{/* if deleted then display deleted avatar */}
						{props.deleted ? <AvatarDeleted /> : ''}

						{/* if not suspended and not deleted and have a photo, display photo, else display initials avatar */}
						{!props.suspended && !props.deleted ? (
							<UserImage photoURL={props.photo} name={props.name} size={100} />
						) : (
							''
						)}
					</div>
					<div className={classes.memberCard}>
						<div className={classes.memberName}>
							{props.familyName}, {props.givenName}
						</div>
						{props.memberType ? (
							<div className={classes.memberType}>{props.memberType}</div>
						) : (
							<div>
								<br></br>
							</div>
						)}
						<div className={classes.infoGroup}>
							<div className={classes.memberInfo}>Mobile: {props.mobile}</div>
							<div className={classes.memberEmail}>
								Email:&#160;
								<a
									href={`mailto:${props.email}`}
									target='_blank'
									rel='noreferrer'
									onClick={stopPropagation}
									className={classes.email}
								>
									{props.email}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	//if order by is Email
	if (props.orderBy === 'EMAIL') {
		return (
			<div className={classes.container} onClick={selectMember}>
				<div className={classes.justify}>
					<div className={classes.imgContainer}>
						{/* if suspended then display suspended avatar */}
						{props.suspended ? <AvatarSuspended /> : ''}

						{/* if deleted then display deleted avatar */}
						{props.deleted ? <AvatarDeleted /> : ''}

						{/* if not suspended and not deleted and have a photo, display photo, else display initials avatar */}
						{!props.suspended && !props.deleted ? (
							<UserImage photoURL={props.photo} name={props.name} size={100} />
						) : (
							''
						)}
					</div>
					<div className={classes.memberCard}>
						<div className={classes.memberEmailOrderBy}>
							<a
								href={`mailto:${props.email}`}
								target='_blank'
								rel='noreferrer'
								onClick={stopPropagation}
								className={classes.email}
							>
								{props.email}
							</a>
						</div>
						{props.memberType ? (
							<div className={classes.memberType}>{props.memberType}</div>
						) : (
							<div>
								<br></br>
							</div>
						)}
						<div className={classes.infoGroup}>
							<div className={classes.memberNameOrderBy}>Name: {props.name}</div>
							<div className={classes.memberInfo}>Mobile: {props.mobile}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
