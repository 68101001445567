import React from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import PropTypes from 'prop-types';

const ReusableTable = ({ columns = [], data = [], sortBy = '', sortAscending = true }) => {
	const table = useMaterialReactTable({
		columns: columns,
		data: data,
		getRowId: (row) => row.ID,
		muiTablePaperProps: {
			elevation: 0,
		},
		// enableExpanding: true, // Ensure this is true to allow row expansion
		enableExpandAll: false,
		paginationDisplayMode: 'pages',
		initialState: {
			sorting: [{ id: sortBy, asc: sortAscending }],
			columnVisibility: { ID: false, Group: false },
		},
	});

	return <MaterialReactTable table={table} />;
};

ReusableTable.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			accessorKey: PropTypes.string.isRequired,
			header: PropTypes.string.isRequired,
		})
	).isRequired,
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	title: PropTypes.string,
};

export default ReusableTable;
