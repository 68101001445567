//gets the parent positions and their paths
export default function GetParentPositions(positions) {
	return new Promise(async (resolveGetParentPositions) => {
		const positionArray = [];

		const loopThrough = async (positions) => {
			return new Promise(async (resolveLoopThrough) => {
				for (let i = 0; i < positions.length; i++) {
					if (positions[i].children) {
						await loopThrough([...positions[i].children]);
					}
					const obj = {
						key: positions[i].path,
						name: positions[i].title,
						id: positions[i].id,
						type: positions[i].type,
					};
					positionArray.push(obj);
				}

				resolveLoopThrough();
			});
		};

		await loopThrough(positions);

		resolveGetParentPositions(positionArray);
	});
}
