/**
 *removes the last version of "string" from the parent string
 * @param {string} string - the string to remove from parent
 * @param {string} parent - the string to have something removed from
 * @return returns the parent string with the last iteration of string removed
 */
export default function RemoveString(string, parent) {
	const regex = new RegExp(string, 'g');
	let lastIndex;

	while (regex.test(parent)) {
		lastIndex = regex.lastIndex;
	}

	const newParent = parent.slice(0, lastIndex - string.length) + parent.slice(lastIndex);

	return newParent;
}
