import React, { useEffect, useState, Fragment, useMemo, useContext } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, addDoc, doc, getDocs, updateDoc } from 'firebase/firestore';

import { Alert, Box, Button, Grid, IconButton } from '@mui/material';
import { Edit, AddRounded, Person, GroupsRounded } from '@mui/icons-material';

import moment from 'moment';

import LeadershipModalCreateNewPosition from '../components/leadership/LeadershipModalCreateNewPosition';
import LeadershipModalEditPosition from '../components/leadership/LeadershipModalEditPosition';
import LeadershipModalEditPerson from '../components/leadership/LeadershipModalEditPerson';
import LeadershipModalEditGroups from '../components/leadership/LeadershipModalEditGroups';
import { MaterialReactTable } from 'material-react-table';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';
import Tooltip from '../components/ui/Tooltip';
import LogOnlyChangedData from '../components/leadership/LogOnlyChangedData';
import GetAllPeopleList from '../components/ui/GetAllPeopleList';
import { db } from '../App';
import AuthContext from '../components/auth/authContext';

export default function Leadership() {
	const claimsCtx = useContext(CustomClaimsContext);
	const [displayCreateModal, setDisplayCreateModal] = useState(null);
	const [displayEditModal, setDisplayEditModal] = useState(null);
	const [displayPersonModal, setDisplayPersonModal] = useState(null);
	const [displayGroupsModal, setDisplayGroupsModal] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [inactiveTableData, setInactiveTableData] = useState([]);
	const [positionData, setPositionData] = useState({});
	const [newEmailAlert, setNewEmailAlert] = useState({ alert: null, email: '' });
	const [originalEmailAlert, setOriginalEmailAlert] = useState({ alert: null, email: '' });
	const [tickLogEmailAlert, setTickLogEmailAlert] = useState({ alert: null, message: null });
	const [allPeople, setAllPeople] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 15,
	});
	const authCtx = useContext(AuthContext);

	//table columns
	const columns = useMemo(
		() => [
			{
				accessorKey: 'id',
				header: 'ID',
			},
			{
				accessorKey: 'group',
				header: 'Group',
			},
			{
				accessorKey: 'positionTitle',
				header: 'Position',
			},
			{
				accessorKey: 'currentPerson.label',
				header: 'Name',
			},
			{
				accessorKey: 'currentPerson.email',
				header: 'Email',
				id: 'email',
			},
			{
				accessorKey: 'compensationType',
				header: 'CAT/Stipend/WET',
			},
			{
				accessorKey: 'compensationAmount',
				header: 'per Month',
			},
			{
				accessorFn: (row) =>
					row.currentPerson.startDate ? moment(row.currentPerson.startDate).format('MMM D, YYYY') : '',
				header: 'Start Date',
				id: 'startDate',
			},
			{
				accessorFn: (row) => (row.currentPerson.endDate ? moment(row.currentPerson.endDate).format('MMM D, YYYY') : ''),
				header: 'End Date',
				id: 'endDate',
			},
			{
				accessorFn: (row) =>
					!row.groups[0]
						? ''
						: !row.groups[1]
						? row.groups[0].group
						: !row.groups[2]
						? `${row.groups[0].group}, ${row.groups[1].group}`
						: !row.groups[4]
						? `${row.groups[0].group}, ${row.groups[1].group}, ${row.groups[2].group}`
						: `${row.groups[0].group}, ${row.groups[1].group}, ${row.groups[2].group}, ${row.groups[3].group}`,
				header: 'Committees',
				id: 'committees',
			},
			{
				accessorKey: 'tickProjectID',
				header: 'Tick Project ID',
			},
		],
		[]
		// [getCommonEditTextFieldProps]
	);

	//initial load - gets the leadership positions & sets tableData, loads allPeople
	useEffect(() => {
		if (db && authCtx.gapiToken) {
			const getData = async () => {
				setIsLoading(true);

				const tableData = [];
				const inactiveTableData = [];
				const querySnapshot = await getDocs(collection(db, 'leadership'));
				querySnapshot.forEach((document) => {
					let data = document.data();
					// console.log(data);
					if (data.currentPerson.startDate && data.currentPerson.startDate !== null) {
						data.currentPerson.startDate = data.currentPerson.startDate.toDate();
					}
					if (data.currentPerson.endDate && data.currentPerson.endDate !== null) {
						data.currentPerson.endDate = data.currentPerson.endDate.toDate();
					}
					if (data.futurePerson.startDate && data.futurePerson.startDate !== null) {
						data.futurePerson.startDate = data.futurePerson.startDate.toDate();
					}
					if (data.futurePerson.endDate && data.futurePerson.endDate !== null) {
						data.futurePerson.endDate = data.futurePerson.endDate.toDate();
					}
					if (data.active) {
						tableData.push({ id: document.id, ...data });
					} else {
						inactiveTableData.push({ id: document.id, ...data });
					}
				});

				setTableData(() => tableData);
				setInactiveTableData(() => inactiveTableData);

				const allPeople = await GetAllPeopleList(authCtx.gapiToken);
				setAllPeople(allPeople);

				setIsLoading(false);
			};

			getData();
		}
	}, [authCtx.gapiToken]);

	//button for adding a leadership position
	const handleCreateLeadershipPosition = () => {
		setDisplayCreateModal(true);
	};

	//cancels the modals
	const cancelModal = () => {
		setDisplayCreateModal(null);
		setDisplayEditModal(null);
		setDisplayPersonModal(null);
		setDisplayGroupsModal(null);
	};

	//handles the page reloading when a change is made
	const handleReload = async () => {
		setIsLoading(true);

		const tableData = [];
		const inactiveTableData = [];
		const querySnapshot = await getDocs(collection(db, 'leadership'));
		querySnapshot.forEach((document) => {
			let data = document.data();
			// console.log(data);
			if (data.currentPerson.startDate && data.currentPerson.startDate !== null) {
				data.currentPerson.startDate = data.currentPerson.startDate.toDate();
			}
			if (data.currentPerson.endDate && data.currentPerson.endDate !== null) {
				data.currentPerson.endDate = data.currentPerson.endDate.toDate();
			}
			if (data.futurePerson.startDate && data.futurePerson.startDate !== null) {
				data.futurePerson.startDate = data.futurePerson.startDate.toDate();
			}
			if (data.futurePerson.endDate && data.futurePerson.endDate !== null) {
				data.futurePerson.endDate = data.futurePerson.endDate.toDate();
			}
			if (data.active) {
				tableData.push({ id: document.id, ...data });
			} else {
				inactiveTableData.push({ id: document.id, ...data });
			}
		});

		setTableData(() => tableData);
		setInactiveTableData(() => inactiveTableData);

		const allPeople = await GetAllPeopleList(authCtx.gapiToken);
		setAllPeople(allPeople);

		setIsLoading(false);
	};

	//submits the createModal
	const handleOnSubmitCreate = async (event) => {
		const e = event.position;

		cancelModal();
		setIsLoading(true);

		const timestamp = new Date().toISOString();

		const data = e;
		data.history = {
			[timestamp]: {
				user: claimsCtx?.claims?.email,
				note: 'Leadership Position Created',
				data: {
					...data,
				},
			},
		};
		await addDoc(collection(db, 'leadership'), data);

		const functions = getFunctions();
		const updatePositionsV2 = httpsCallable(functions, 'updatePositionsV2');
		updatePositionsV2({
			newPosition: e,
			originalPosition: null,
		}).then((result) => {
			if (result.data.status === 'success') {
				setOriginalEmailAlert({ alert: true, email: e?.currentPerson?.email });
			} else {
				setOriginalEmailAlert({ alert: false, email: e?.currentPerson?.email });
			}
			setTimeout(() => {
				setOriginalEmailAlert({ alert: null, email: '' });
			}, 5000);
		});

		//if currentPerson for Position, and tick log ID, send email to get them access to the tick log
		if (e?.currentPerson?.email !== '' && e?.tickProjectID) {
			const giveTickLogAccessEmail = httpsCallable(functions, 'giveTickLogAccessEmail');
			giveTickLogAccessEmail({
				personEmail: e.currentPerson.email,
				personName: e.currentPerson.label,
				position: e.positionTitle,
				projectID: e.tickProjectID,
				template: 'giveAccess',
			})
				.then((response) => {
					if (response.data.code === 200)
						setTickLogEmailAlert({
							alert: true,
							message: 'An email has been sent to IT to grant access to the Tick Log associated with this project.',
						});
					else setTickLogEmailAlert({ alert: false, message: response.data.message });
					setTimeout(() => {
						setTickLogEmailAlert({ alert: null, message: null });
					}, 5000);
				})
				.catch((error) => {
					console.log(error);
					setTickLogEmailAlert({ alert: false, message: error });
					setTimeout(() => {
						setTickLogEmailAlert({ alert: null, message: null });
					}, 5000);
				});
		}

		setTimeout(() => {
			handleReload();
		}, 1000);
	};

	//handles the edit button
	const handleEditPosition = (row) => {
		setPositionData(row);
		setDisplayEditModal(true);
	};

	//handles the edit submit button
	const handleOnEditPosition = async (event) => {
		const e = event.position;

		cancelModal();
		setIsLoading(true);

		const timestamp = new Date().toISOString();
		const id = e.id;
		delete e.id; //removes id, this is already passed to each object when the list is pulled
		const data = { ...e };

		data.history[timestamp] = {
			user: claimsCtx?.claims?.email,
			note: 'Position Edited',
			data: {
				...LogOnlyChangedData(e, positionData),
			},
		};

		const documentRef = doc(db, 'leadership', id);
		await updateDoc(documentRef, data);

		const functions = getFunctions();
		const updatePositionsV2 = httpsCallable(functions, 'updatePositionsV2');
		updatePositionsV2({
			newPosition: e,
			originalPosition: positionData,
		}).then((result) => {
			if (result.data.status === 'success') {
				setOriginalEmailAlert({ alert: true, email: e?.currentPerson?.email });
			} else {
				setOriginalEmailAlert({ alert: false, email: e?.currentPerson?.email });
			}
			setTimeout(() => {
				setOriginalEmailAlert({ alert: null, email: '' });
			}, 5000);
		});

		//if currentPerson for Position, and tick log ID, and tick log ID doesn't match original (so as to not send an email for a project they have access to already), send email to get them access to the tick log
		if (e?.currentPerson?.email !== '' && e?.tickProjectID !== positionData.tickProjectID) {
			const giveTickLogAccessEmail = httpsCallable(functions, 'giveTickLogAccessEmail');
			giveTickLogAccessEmail({
				personEmail: e.currentPerson.email,
				personName: e.currentPerson.label,
				position: e.positionTitle,
				projectID: e.tickProjectID,
				template: 'giveAccess',
			})
				.then((response) => {
					if (response.data.code === 200)
						setTickLogEmailAlert({
							alert: true,
							message: `An email has been sent to IT to grant access for ${e.currentPerson.email} to the Tick Log associated with this project.`,
						});
					else setTickLogEmailAlert({ alert: false, message: response.data.message });
					setTimeout(() => {
						setTickLogEmailAlert({ alert: null, message: null });
					}, 5000);
				})
				.catch((error) => {
					console.log(error);
					setTickLogEmailAlert({ alert: false, message: error });
					setTimeout(() => {
						setTickLogEmailAlert({ alert: null, message: null });
					}, 5000);
				});
		}

		setTimeout(() => {
			handleReload();
		}, 1000);
	};

	//handles the person button
	const handlePerson = (row) => {
		setPositionData(row);
		setDisplayPersonModal(true);
	};

	//handles the edit person submit button
	const handleOnEditPerson = async (event) => {
		const e = event.position;
		const oldPerson = event.oldPerson;
		const newPerson = event.newPerson;

		cancelModal();
		setIsLoading(true);

		//if old person exists, and is different from the new person, move them to priorPerson
		if (positionData.currentPerson.email !== '' && positionData.currentPerson.email !== newPerson.email) {
			let priorPerson = [];
			if (positionData.priorPerson) {
				priorPerson = [...positionData.priorPerson];
			}
			priorPerson.push(positionData.currentPerson);
			e.priorPerson = priorPerson;
		}

		const timestamp = new Date().toISOString();
		const id = e.id;
		delete e.id; //removes id, this is already passed to each object when the list is pulled
		const data = { ...e };

		data.history[timestamp] = {
			user: claimsCtx?.claims?.email,
			note: 'Position Holder Edited',
			data: {
				...LogOnlyChangedData(e, positionData),
			},
		};

		const documentRef = doc(db, 'leadership', id);
		await updateDoc(documentRef, data);

		const functions = getFunctions();
		const updatePositionsV2 = httpsCallable(functions, 'updatePositionsV2');
		updatePositionsV2({
			newPosition: e,
			originalPosition: positionData,
		}).then((result) => {
			if (result.data.status === 'success') {
				setOriginalEmailAlert({ alert: true, email: e?.currentPerson?.email });
			} else {
				setOriginalEmailAlert({ alert: false, email: e?.currentPerson?.email });
			}
			setTimeout(() => {
				setOriginalEmailAlert({ alert: null, email: '' });
			}, 5000);
		});

		//if currentPerson for Position, and tick log ID, send email to get them access to the tick log
		if (e.currentPerson?.email !== '' && e.currentPerson?.email !== oldPerson?.email && e.tickProjectID) {
			const giveTickLogAccessEmail = httpsCallable(functions, 'giveTickLogAccessEmail');
			giveTickLogAccessEmail({
				personEmail: e.currentPerson.email,
				personName: e.currentPerson.label,
				position: e.positionTitle,
				projectID: e.tickProjectID,
				template: 'giveAccess',
			})
				.then((response) => {
					if (response.data.code === 200)
						setTickLogEmailAlert({
							alert: true,
							message: `An email has been sent to IT to grant access for ${e.currentPerson.email} to the Tick Log associated with this project.`,
						});
					else setTickLogEmailAlert({ alert: false, message: response.data.message });
					setTimeout(() => {
						setTickLogEmailAlert({ alert: null, message: null });
					}, 5000);
				})
				.catch((error) => {
					console.log(error);
					setTickLogEmailAlert({ alert: false, message: error });
					setTimeout(() => {
						setTickLogEmailAlert({ alert: null, message: null });
					}, 5000);
				});
		}

		setTimeout(() => {
			handleReload();
		}, 1000);
	};

	//handles the edit groups button
	const handleEditGroups = (row) => {
		setPositionData(row);
		setDisplayGroupsModal(true);
	};

	//handles the edit groups submit button
	const handleOnSubmitGroups = async (e) => {
		cancelModal();
		setIsLoading(true);

		const timestamp = new Date().toISOString();
		const id = e.id;
		delete e.id; //removes id, this is already passed to each object when the list is pulled
		const data = { ...e };

		data.history[timestamp] = {
			user: claimsCtx?.claims?.email,
			note: 'Groups Edited',
			data: {
				...LogOnlyChangedData(e, positionData),
			},
		};

		const documentRef = doc(db, 'leadership', id);
		await updateDoc(documentRef, data);

		setTimeout(() => {
			handleReload();
			// setIsLoading(false);
		}, 1000);
	};

	return (
		<Fragment>
			{/* create new position modal */}
			{displayCreateModal && (
				<LeadershipModalCreateNewPosition
					submit={handleOnSubmitCreate}
					cancel={cancelModal}
					open={displayCreateModal}
					tableData={tableData}
					allPeople={allPeople}
					positionData={positionData}
					db={db}
				/>
			)}

			{/* edit position modal */}
			{displayEditModal && (
				<LeadershipModalEditPosition
					submit={handleOnEditPosition}
					cancel={cancelModal}
					open={displayEditModal}
					tableData={tableData}
					positionData={positionData}
				/>
			)}

			{/* edit person modal */}
			{displayPersonModal && (
				<LeadershipModalEditPerson
					submit={handleOnEditPerson}
					cancel={cancelModal}
					open={displayPersonModal}
					tableData={tableData}
					positionData={positionData}
					allPeople={allPeople}
				/>
			)}

			{/* edit groups modal */}
			{displayGroupsModal && (
				<LeadershipModalEditGroups
					submit={handleOnSubmitGroups}
					cancel={cancelModal}
					open={displayGroupsModal}
					tableData={tableData}
					positionData={positionData}
					db={db}
				/>
			)}

			{/* ACTIVE TABLE - Admin & Not Mobile*/}
			<Grid sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
				{claimsCtx?.claims?.admin && (
					<MaterialReactTable
						muiTablePaperProps={{
							elevation: 0, //change the mui box shadow
							sx: {
								'& tr:nth-of-type(even)': {
									backgroundColor: '#f5f5f5', //stripe the rows, make even rows a darker color
								},
							},
						}}
						displayColumnDefOptions={{
							'mrt-row-actions': {
								muiTableHeadCellProps: {
									align: 'center',
								},
								size: 120,
							},
						}}
						columns={columns}
						data={tableData}
						enableGrouping
						// editingMode='modal' //default
						enableEditing
						autoResetPageIndex={false}
						state={{ showProgressBars: isLoading, pagination }}
						onPaginationChange={setPagination}
						initialState={{
							density: 'compact',
							expanded: true, //expand all groups by default
							pagination: pagination,
							sorting: [{ id: 'group', desc: false }], //sort by group by default
							columnVisibility: {
								id: false,
								email: false,
								startDate: false,
								endDate: false,
								committees: false,
								tickProjectID: false,
							},
						}}
						/* sets the action column settings */
						renderRowActions={({ row, table }) => (
							<Box sx={{ display: 'flex', gap: '1rem' }}>
								<Tooltip text='Manage the Person in this position.'>
									<IconButton onClick={() => handlePerson(row.original)}>
										<Person />
									</IconButton>
								</Tooltip>
								<Tooltip text='Edit this positions settings.'>
									<IconButton onClick={() => handleEditPosition(row.original)}>
										<Edit />
									</IconButton>
								</Tooltip>
								<Tooltip text='Manage the Positions & Committees this role is associated with.'>
									<IconButton onClick={() => handleEditGroups(row.original)}>
										<GroupsRounded />
									</IconButton>
								</Tooltip>
							</Box>
						)}
						/* sets the top toolbar actions */
						renderTopToolbarCustomActions={() => (
							<>
								<Button
									color='primary'
									onClick={handleCreateLeadershipPosition}
									variant='contained'
									startIcon={<AddRounded />}
								>
									NEW POSITION
								</Button>
								{/* alerts */}
								<div>
									{originalEmailAlert.alert === true && (
										<Alert severity='success'>
											The positions associated with {originalEmailAlert.email} have been updated.
										</Alert>
									)}
									{originalEmailAlert.alert === false && (
										<Alert severity='error'>
											The positions associated with {originalEmailAlert.email} have <strong>not</strong> been updated.
										</Alert>
									)}
									{newEmailAlert.alert === true && (
										<Alert severity='success'>
											The positions associated with {newEmailAlert.email} have been updated.
										</Alert>
									)}
									{newEmailAlert.alert === false && (
										<Alert severity='error'>
											The positions associated with {newEmailAlert.email} have <strong>not</strong> been updated.
										</Alert>
									)}
									{tickLogEmailAlert.alert === true && <Alert severity='success'>{tickLogEmailAlert.message}</Alert>}
									{tickLogEmailAlert.alert === false && <Alert severity='error'>{tickLogEmailAlert.message}</Alert>}
								</div>
							</>
						)}
					/>
				)}
			</Grid>

			{/* ACTIVE TABLE - Admin & Mobile*/}
			<Grid sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
				{claimsCtx?.claims?.admin && (
					<MaterialReactTable
						muiTablePaperProps={{
							elevation: 0, //change the mui box shadow
							sx: {
								'& tr:nth-of-type(even)': {
									backgroundColor: '#f5f5f5', //stripe the rows, make even rows a darker color
								},
							},
						}}
						displayColumnDefOptions={{
							'mrt-row-actions': {
								muiTableHeadCellProps: {
									align: 'center',
								},
								size: 120,
							},
						}}
						columns={columns}
						data={tableData}
						enableGrouping
						state={{ showProgressBars: isLoading }}
						initialState={{
							density: 'compact',
							expanded: true, //expand all groups by default
							pagination: { pageIndex: 0, pageSize: 25 },
							sorting: [{ id: 'group', desc: false }], //sort by group by default
							columnVisibility: {
								id: false,
								email: false,
								startDate: false,
								endDate: false,
								committees: false,
								tickProjectID: false,
							},
						}}
					/>
				)}
			</Grid>

			{/* ACTIVE TABLE - Not Admin & EPT Physician*/}
			{!claimsCtx?.claims?.admin && claimsCtx?.claims?.isEPT && claimsCtx?.claims?.memberType === 'Physician' && (
				<MaterialReactTable
					muiTablePaperProps={{
						elevation: 0, //change the mui box shadow
						sx: {
							'& tr:nth-of-type(even)': {
								backgroundColor: '#f5f5f5', //stripe the rows, make even rows a darker color
							},
						},
					}}
					displayColumnDefOptions={{
						'mrt-row-actions': {
							muiTableHeadCellProps: {
								align: 'center',
							},
							size: 120,
						},
					}}
					columns={columns}
					data={tableData}
					enableGrouping
					state={{ showProgressBars: isLoading }}
					initialState={{
						density: 'compact',
						expanded: true, //expand all groups by default
						pagination: { pageIndex: 0, pageSize: 25 },
						sorting: [{ id: 'group', desc: false }], //sort by group by default
						columnVisibility: {
							id: false,
							email: false,
							startDate: false,
							endDate: false,
							committees: false,
							tickProjectID: false,
							// compensationType: false,
							// compensationAmount: false,
						},
					}}
				/>
			)}

			{/* ACTIVE TABLE - Not Admin & Not EPT Physician (i.e. OPT and EPT APPs) */}
			{!claimsCtx?.claims?.admin &&
				(claimsCtx?.claims?.isOPT || (claimsCtx?.claims?.isEPT && claimsCtx?.claims?.memberType !== 'Physician')) && (
					<MaterialReactTable
						muiTablePaperProps={{
							elevation: 0, //change the mui box shadow
							sx: {
								'& tr:nth-of-type(even)': {
									backgroundColor: '#f5f5f5', //stripe the rows, make even rows a darker color
								},
							},
						}}
						displayColumnDefOptions={{
							'mrt-row-actions': {
								muiTableHeadCellProps: {
									align: 'center',
								},
								size: 120,
							},
						}}
						columns={columns}
						data={tableData}
						enableGrouping
						state={{ showProgressBars: isLoading }}
						initialState={{
							density: 'compact',
							expanded: true, //expand all groups by default
							pagination: { pageIndex: 0, pageSize: 25 },
							sorting: [{ id: 'group', desc: false }], //sort by group by default
							columnVisibility: {
								id: false,
								email: false,
								startDate: false,
								endDate: false,
								committees: false,
								tickProjectID: false,
								compensationType: false,
								compensationAmount: false,
							},
						}}
					/>
				)}

			{/* Deactivated Positions */}
			{/* only visible on Not Mobile */}
			<Grid sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
				<h2>Deactivated Positions</h2>

				{/* INACTIVE TABLE - Admin & Not Mobile*/}
				{claimsCtx?.claims?.admin && (
					<MaterialReactTable
						muiTablePaperProps={{
							elevation: 0, //change the mui box shadow
							sx: {
								'& tr:nth-of-type(even)': {
									backgroundColor: '#f5f5f5', //stripe the rows, make even rows a darker color
								},
							},
						}}
						displayColumnDefOptions={{
							'mrt-row-actions': {
								muiTableHeadCellProps: {
									align: 'center',
								},
								size: 120,
							},
						}}
						columns={columns}
						data={inactiveTableData}
						enableGrouping
						state={{ showProgressBars: isLoading }}
						// editingMode='modal' //default
						enableEditing
						initialState={{
							density: 'compact',
							expanded: true, //expand all groups by default
							pagination: { pageIndex: 0, pageSize: 25 },
							sorting: [{ id: 'group', desc: false }], //sort by group by default
							columnVisibility: {
								id: false,
								email: false,
								startDate: false,
								endDate: false,
								committees: false,
								tickProjectID: false,
							},
						}}
						/* sets the action column settings */
						renderRowActions={({ row, table }) => (
							<Box sx={{ display: 'flex', gap: '1rem' }}>
								<Tooltip text='Edit this positions settings.'>
									<IconButton onClick={() => handleEditPosition(row.original)}>
										<Edit />
									</IconButton>
								</Tooltip>
							</Box>
						)}
					/>
				)}

				{/* INACTIVE TABLE -  Not Admin & EPT Physician */}
				{!claimsCtx?.claims?.admin && claimsCtx?.claims?.isEPT && claimsCtx?.claims?.memberType === 'Physician' && (
					<MaterialReactTable
						muiTablePaperProps={{
							elevation: 0, //change the mui box shadow
							sx: {
								'& tr:nth-of-type(even)': {
									backgroundColor: '#f5f5f5', //stripe the rows, make even rows a darker color
								},
							},
						}}
						displayColumnDefOptions={{
							'mrt-row-actions': {
								muiTableHeadCellProps: {
									align: 'center',
								},
								size: 120,
							},
						}}
						columns={columns}
						data={inactiveTableData}
						enableGrouping
						state={{ showProgressBars: isLoading }}
						initialState={{
							density: 'compact',
							expanded: true, //expand all groups by default
							pagination: { pageIndex: 0, pageSize: 25 },
							sorting: [{ id: 'group', desc: false }], //sort by group by default
							columnVisibility: {
								id: false,
								email: false,
								startDate: false,
								endDate: false,
								committees: false,
								tickProjectID: false,
								// compensationAmount: false,
								// compensationType: false,
							},
						}}
					/>
				)}

				{/* INATIVE TABLE - Not Admin & Not EPT Physician (i.e. OPT and EPT APPs) */}
				{!claimsCtx?.claims?.admin &&
					(claimsCtx?.claims?.isOPT || (claimsCtx?.claims?.isEPT && claimsCtx?.claims?.memberType !== 'Physician')) && (
						<MaterialReactTable
							muiTablePaperProps={{
								elevation: 0, //change the mui box shadow
								sx: {
									'& tr:nth-of-type(even)': {
										backgroundColor: '#f5f5f5', //stripe the rows, make even rows a darker color
									},
								},
							}}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									muiTableHeadCellProps: {
										align: 'center',
									},
									size: 120,
								},
							}}
							columns={columns}
							data={inactiveTableData}
							enableGrouping
							state={{ showProgressBars: isLoading }}
							initialState={{
								density: 'compact',
								expanded: true, //expand all groups by default
								pagination: { pageIndex: 0, pageSize: 25 },
								sorting: [{ id: 'group', desc: false }], //sort by group by default
								columnVisibility: {
									id: false,
									email: false,
									startDate: false,
									endDate: false,
									committees: false,
									tickProjectID: false,
									compensationAmount: false,
									compensationType: false,
								},
							}}
						/>
					)}
			</Grid>
		</Fragment>
	);
}
