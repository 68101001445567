import React, { useContext, useEffect, useState } from 'react';
import AuthContext from './authContext';
import RefreshToken from './RefreshToken';
import PageCards from '../ui/PageCards';
import { Box } from '@mui/material';
import LoadingSpinner from '../ui/LoadingSpinner';

export default function CheckToken(props) {
	const authCtx = useContext(AuthContext);
	const { isLoggedIn, gapiToken, isLoading } = authCtx;
	const [needToken, setNeedToken] = useState(false);

	// Check if we need to refresh the token
	useEffect(() => {
		if (isLoggedIn && !gapiToken) {
			setNeedToken(true);
		} else setNeedToken(false);
	}, [isLoggedIn, gapiToken]);

	return (
		<>
			{isLoading && <LoadingSpinner />}
			{needToken && (
				<PageCards>
					<Box m={3}>
						<RefreshToken />
					</Box>
				</PageCards>
			)}
			{!needToken && <div>{props.children}</div>}
		</>
	);
}
