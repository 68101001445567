import { gapi } from 'gapi-script';
import { v4 as uuidv4 } from 'uuid';
import { API_KEY } from '../../apiKey';

/**
 * Fetches a list of all people from the Google People API.
 *
 * @param {string} token - The access token for the user.
 * @returns {Promise<Array>} - A promise that resolves to an array of people.
 */
export default async function GetAllPeopleList(token) {
	try {
		// Load the GAPI client if not already loaded
		if (!gapi.client || !gapi.client.request) {
			await new Promise((resolve, reject) => {
				gapi.load('client', {
					callback: resolve,
					onerror: () => reject(new Error('Failed to load GAPI client')),
					timeout: 5000,
					ontimeout: () => reject(new Error('Timed out loading GAPI client')),
				});
			});
		}

		// Set the access token
		gapi.client.setToken({ access_token: token });

		let pageToken = null;
		const peopleArray = [];

		do {
			const params = {
				pageSize: 100,
				readMask: 'names,emailAddresses',
				sources: 'DIRECTORY_SOURCE_TYPE_DOMAIN_PROFILE',
				key: API_KEY,
			};
			if (pageToken) {
				params.pageToken = pageToken;
			}

			const response = await gapi.client.request({
				method: 'GET',
				path: 'https://people.googleapis.com/v1/people:listDirectoryPeople',
				params: params,
			});

			if (response && response.result) {
				pageToken = response.result.nextPageToken || null;
				const people = response.result.people || [];
				people.forEach((person) => {
					if (person.emailAddresses && person.names) {
						peopleArray.push({
							email: person.emailAddresses[0].value,
							label: person.names[0].displayName,
							name: person.names[0].displayName,
							id: `${person.emailAddresses[0].value}-${uuidv4()}`,
						});
					}
				});
			} else {
				pageToken = null;
			}
		} while (pageToken);

		// Sort the array by label (name)
		const sortedArray = peopleArray.sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));

		return sortedArray;
	} catch (err) {
		console.error('Error in GetAllPeopleList:', err?.result?.error?.message || err.message);
		throw new Error(err?.result?.error?.message || 'Unknown error occurred.');
	}
}
