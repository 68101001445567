import React, { useContext, useEffect, useMemo, useState } from 'react';

import * as Yup from 'yup';

import { collection, addDoc, Timestamp, getDocs } from 'firebase/firestore';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
	Autocomplete,
	Typography,
	TextField,
	Button,
	Box,
	Container,
	Stack,
	Link,
	Chip,
	Tab,
	Tabs,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';

import PageCards from '../ui/PageCards';
import { db } from '../../App';
import GetAllPeopleList from '../ui/GetAllPeopleList';
import AlertContext from '../ui/AlertContext';
import CustomClaimsContext from '../auth/CustomClaimsContext';
import LoadingSpinner from '../ui/LoadingSpinner';
import MRT_TABLE from '../ui/MRT_TABLE';
import Tooltip from '../ui/Tooltip';
import EvaluationSettings from './EvaluationSettings';
import GetUserData from '../auth/GetUserData';
import AuthContext from '../auth/authContext';

export default function Evaluations() {
	const [loading, setLoading] = useState(true);
	const [evaluatees, setEvaluatees] = useState([]);
	const [evaluations, setEvaluations] = useState([]);
	const [EvaluationVisibleTo, setEvaluationVisibleTo] = useState([]);
	const [evaluationSettings, setEvaluationSettings] = useState([]);
	const [tabValue, setTabValue] = useState(0);
	const alertCtx = useContext(AlertContext);
	const claimsCtx = useContext(CustomClaimsContext);
	const authCtx = useContext(AuthContext);

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	// gets all the people, evaluations, and evaluation settings
	useEffect(() => {
		async function getData() {
			try {
				const [tempPeople, tempEvaluations, tempEvaluationSettings, tempEvaluationVisibleTo] = await Promise.all([
					GetAllPeopleList(authCtx.gapiToken),
					getEvaluations(),
					getEvaluationSettings(),
					GetUserData('EvaluationVisibleTo'),
				]);

				setEvaluatees(tempPeople);
				setEvaluations(tempEvaluations);
				setEvaluationSettings(tempEvaluationSettings);
				setEvaluationVisibleTo(tempEvaluationVisibleTo);
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setLoading(false);
			}
		}

		getData();
	}, []);

	async function getEvaluations() {
		try {
			const evaluationsCollection = collection(db, 'Evaluations');
			const snapshotEvaluations = await getDocs(evaluationsCollection);
			const evaluations = snapshotEvaluations.docs.map((doc) => ({
				id: doc.id,
				...doc.data(),
			}));
			return evaluations;
		} catch (error) {
			console.error('Error fetching evaluations:', error);
			return [];
		}
	}

	async function getEvaluationSettings() {
		try {
			const evaluationSettingsCollection = collection(db, 'EvaluationSettings');
			const snapshotEvaluationSettings = await getDocs(evaluationSettingsCollection);
			const evaluationSettings = snapshotEvaluationSettings.docs
				.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}))
				.sort((a, b) => a.EvaluateePosition.localeCompare(b.EvaluateePosition));
			return evaluationSettings;
		} catch (error) {
			console.error('Error fetching evaluation settings:', error);
			return [];
		}
	}

	async function fetchEvaluationSettings() {
		const tempEvaluationSettings = await getEvaluationSettings();
		setEvaluationSettings(tempEvaluationSettings);
	}

	// displays link if the person is the Evaluatee or if the person is in a group who should see the evaluation
	const isLinkVisible = (row) => {
		let match = row?.EvaluateeEmail === claimsCtx?.claims?.email ? true : false;
		for (const VisibleTo of row.VisibleTo) {
			for (const UserVisibility of EvaluationVisibleTo) {
				if (VisibleTo.email === UserVisibility.email) match = true;
			}
		}

		if (match)
			return (
				<Link href={row?.URL_PublishingSummary} color={'info.main'} target='_blank' rel='noreferrer'>
					Evaluation Link
				</Link>
			);
		else return 'No Access';
	};

	const columns = useMemo(
		() => [
			{
				header: 'ID',
				accessorFn: (row) => row?.id,
				id: 'id',
			},
			{
				header: 'Closed',
				accessorFn: (row) => (row?.Closed ? 'true' : 'false'),
				Cell: ({ row }) =>
					row?.original?.Closed ? (
						<Chip label={'True'} variant='contained' color='success' />
					) : (
						<Chip label={'False'} variant='outlined' color='error' />
					),
				id: 'closed',
			},
			{
				header: 'Close Date',
				accessorFn: (row) => (row?.CloseDate ? moment(row.CloseDate.toDate()).format('MMM DD YYYY, h:mm A') : ''),
				id: 'closed_date',
			},
			{
				header: 'Year',
				accessorFn: (row) => (row?.CloseDate ? moment(row.CloseDate.toDate()).format('YYYY') : ''),
				id: 'year',
			},
			{
				header: 'Position',
				accessorFn: (row) => row?.Position,
				id: 'position',
			},
			{
				header: 'Evaluatee',
				accessorFn: (row) => row?.Evaluatee,
				id: 'evaluatee',
			},
			{
				header: 'Visible To',
				accessorFn: (row) => row?.VisibleTo?.map((item, index) => item.name),
				Cell: ({ row }) =>
					row?.original?.VisibleTo?.map((item, index) => (
						<Tooltip text={item.email} key={index}>
							<Chip label={item.name} variant='outlined' color='primary' sx={{ mr: 1 }} />
						</Tooltip>
					)),
				id: 'visible_to',
			},
			{
				header: 'Link',
				accessorFn: (row) => (row?.Closed && row?.URL ? isLinkVisible(row) : 'Pending'),
				id: 'link',
			},
		],
		[EvaluationVisibleTo, isLinkVisible]
	);

	const handleSubmit = async (values, { resetForm }) => {
		setLoading(true);

		// Trim the URL
		const trimmedUrl = values.url.trim();

		try {
			// Extract year, month, and day from the input value
			const closeDate = new Date(values.closeDate);
			closeDate.setUTCHours(4, 0, 0, 0);

			// Add the new evaluation document to Firestore
			await addDoc(collection(db, 'Evaluations'), {
				URL: trimmedUrl,
				CloseDate: Timestamp.fromDate(closeDate),
				Closed: false,
				CreatedByEmail: claimsCtx.claims.email,
				CreatedByName: claimsCtx.claims.name,
				Transferred: false,
				Evaluatee: values.evaluatee,
				EvaluateeEmail: values.evaluateeEmail,
				Position: values.position,
				VisibleTo: values.visibleTo,
			});

			// Retrieve updated evaluations and settings
			const [tempEvaluations, tempEvaluationSettings] = await Promise.all([getEvaluations(), getEvaluationSettings()]);

			setEvaluations(tempEvaluations);
			setEvaluationSettings(tempEvaluationSettings);

			resetForm();

			// Display success message
			alertCtx.setMessage('Evaluation added successfully');
			alertCtx.setSeverity('success');
			alertCtx.setTimer(5000);
			alertCtx.setTitle('Success');
			alertCtx.setActive(true);
		} catch (error) {
			// Display error message
			alertCtx.setMessage(`Error adding evaluation: ${error}`);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('Error');
			alertCtx.setActive(true);
		}

		setLoading(false);
	};

	return (
		<PageCards>
			<Typography variant='h3' color={'primary'} textAlign={'center'} mb={3}>
				Evaluations
			</Typography>

			{/* tabs */}
			<Box mb={5} sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={tabValue}
					textColor='secondary'
					indicatorColor='secondary'
					onChange={handleTabChange}
					centered
					aria-label='tabs'
				>
					<Tab label='Evaluations' {...a11yProps(0)} />
					<Tab label='Evaluation Settings' {...a11yProps(1)} />
					<Tab label='Add Evaluation' {...a11yProps(2)} />
				</Tabs>
			</Box>

			{/* evaluations */}
			<TabPanel value={tabValue} index={0}>
				<Typography variant='h5' color={'primary'} textAlign={'center'}>
					Evaluations Table
				</Typography>

				<MRT_TABLE
					data={evaluations}
					columns={columns}
					loading={loading}
					sortBy={[
						{ Id: 'closed', Direction: 'asc', TrueFalse: true },
						{ Id: 'closed_date', Direction: 'desc', TrueFalse: true },
						{ Id: 'position', Direction: 'asc', TrueFalse: true },
						{ Id: 'evaluatee', Direction: 'asc', TrueFalse: true },
					]}
					hiddenColumns={['id', 'Closed']}
				/>
			</TabPanel>

			{/* settings */}
			<TabPanel value={tabValue} index={1}>
				<EvaluationSettings onSettingsChange={fetchEvaluationSettings} />
			</TabPanel>

			{/* add evaluation */}
			<TabPanel value={tabValue} index={2}>
				<Typography variant='h5' color={'primary'} textAlign={'center'}>
					Add Evaluation
				</Typography>

				<Formik
					initialValues={{ url: '', closeDate: '', evaluatee: '', evaluateeEmail: '', position: '', visibleTo: [] }}
					validationSchema={Yup.object({
						url: Yup.string()
							.matches(/^\S*$/, 'Whitespace is not allowed in the URL') // Ensures no whitespace anywhere
							.required('URL is required'),
						closeDate: Yup.date().required('Close Date is required'),
						evaluatee: Yup.string().required('Evaluatee is required'),
						position: Yup.string().required('Position is required'),
					})}
					onSubmit={handleSubmit}
				>
					{({ handleChange, setFieldValue, setFieldTouched, values, errors, touched, isValid, dirty }) => (
						<Form>
							<Box width={'500px'} maxWidth={'90%'} margin={'0 auto'} mb={3}>
								<Stack direction={'row'} alignItems={'center'}>
									<Field
										name='url'
										as={TextField}
										label='Evaluation URL'
										variant='outlined'
										fullWidth
										margin='normal'
										disabled={!claimsCtx.claims.admin}
										error={touched.url && Boolean(errors.url)}
										helperText={touched.url && errors.url}
									/>
									<Tooltip text='The URL of the Google Form.' />
								</Stack>

								<Stack direction={'row'} alignItems={'center'}>
									<Field
										name='closeDate'
										as={TextField}
										label='Close Date'
										type='date'
										InputLabelProps={{ shrink: true }}
										fullWidth
										margin='normal'
										disabled={!claimsCtx.claims.admin}
										error={touched.closeDate && Boolean(errors.closeDate)}
										helperText={touched.closeDate && errors.closeDate}
									/>
									<Tooltip text='Closes at 12:00 AM on this date.' />
								</Stack>

								<Autocomplete
									options={evaluatees || []}
									getOptionLabel={(option) => {
										if (typeof option === 'string') {
											return option;
										}
										return `${option.name} (${option.email})`;
									}}
									value={
										values.evaluatee ? evaluatees.find((e) => e.name === values.evaluatee) || values.evaluatee : null
									}
									onChange={(event, value) => {
										setFieldValue('evaluatee', value ? value.name : '');
										setFieldValue('evaluateeEmail', value ? value.email : '');
										setFieldTouched('evaluatee', true);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label='Person Being Evaluated'
											variant='outlined'
											fullWidth
											margin='normal'
											error={touched.evaluatee && Boolean(errors.evaluatee)}
											helperText={touched.evaluatee && errors.evaluatee}
										/>
									)}
									disabled={loading || !claimsCtx.claims.admin}
									isOptionEqualToValue={(option, value) =>
										typeof value === 'string' ? option.name === value : option.id === value?.id
									}
								/>

								<Field
									name='evaluateeEmail'
									as={TextField}
									label='Evaluatee Email'
									variant='outlined'
									fullWidth
									margin='normal'
									disabled
								/>

								<Autocomplete
									options={evaluationSettings || []}
									getOptionLabel={(option) => option.EvaluateePosition}
									value={
										values.position
											? evaluationSettings.find((e) => e.EvaluateePosition === values.position) || values.position
											: null
									}
									onChange={(event, value) => {
										setFieldValue('position', value ? value.EvaluateePosition : '');
										setFieldValue('visibleTo', value ? value.VisibleTo : []);
									}}
									onBlur={() => setFieldTouched('position', true)}
									renderInput={(params) => (
										<TextField
											{...params}
											label='Position'
											variant='outlined'
											fullWidth
											margin='normal'
											error={touched.position && Boolean(errors.position)}
											helperText={touched.position && errors.position}
										/>
									)}
									disabled={loading || !claimsCtx.claims.admin}
								/>

								<Container sx={{ display: 'flex', justifyContent: 'center' }}>
									{loading && <LoadingSpinner />}
									{!loading && (
										<Button
											type='submit'
											variant='contained'
											color='primary'
											disabled={!claimsCtx.claims.admin || !isValid || !dirty}
										>
											Submit
										</Button>
									)}
								</Container>
							</Box>
						</Form>
					)}
				</Formik>
			</TabPanel>
		</PageCards>
	);
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <>{children}</>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}
