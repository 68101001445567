import React, { useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';

import GetPhotoURL from '../ui/GetPhotoURL';
import LoadingSpinner from '../ui/LoadingSpinner';
import UserImage from '../ui/UserImage';

/**
 * used for single positions
 * @param {*} props
 * @returns
 */
export default function PersonCardSinglePosition(props) {
	const [loading, setLoading] = useState(false);
	const [photoURL, setPhotoURL] = useState('');

	useEffect(() => {
		const getPhotUrl = async () => {
			setLoading(true);
			const url = await GetPhotoURL(props.person.email, props.token);
			// console.log(url);
			setPhotoURL(url);
			setLoading(false);
		};
		getPhotUrl();
	}, [props]);

	if (loading) {
		return <LoadingSpinner />;
	}

	return (
		<Stack
			sx={{
				width: '100%',
				alignItems: 'center',
			}}
		>
			<UserImage photoURL={photoURL} name={props.person.name} size={72} />

			<Typography
				variant=''
				align='center'
				color='primary'
				sx={{ width: '100%', alignItems: 'left', fontSize: '1rem' }}
			>
				{props.person.name}
			</Typography>
		</Stack>
	);
}
