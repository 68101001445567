/**
 * deals with customSchemas
 * @param {object} user The user object.
 * @param {string} customField The type of custom data to look for.
 * @return The user's data or null if not found.
 */
function customSchemas(user, customField) {
	// console.log(user);
	if (user.customSchemas === undefined) {
		return null;
	} else if (user.customSchemas.EPT_Details === undefined) {
		return null;
	} else if (user.customSchemas.EPT_Details[customField] === undefined) {
		return null;
	} else {
		return user.customSchemas.EPT_Details[customField];
	}
}

export default customSchemas;
