import React, { useContext, useEffect, useState } from 'react';
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	Stack,
	TextField,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	InputLabel,
	Select,
	MenuItem,
} from '@mui/material';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import DeleteIcon from '@mui/icons-material/Delete';

import GetParentPositions from './GetParentPositions';
import ParentPath from './ParentPath';
import GetOrgTree from './GetOrgTree';
import MoveChildren from './MoveChildren';
import LoadingSpinner from '../ui/LoadingSpinner';
import Tooltip from '../ui/Tooltip';
import CustomClaimsContext from '../auth/CustomClaimsContext';

export default function ModifyPositionModal(props) {
	const position = props.position;
	const claimsCtx = useContext(CustomClaimsContext);
	const [loading, setLoading] = useState(true);
	const originalData = props.position;
	const [isPositionOrGroup, setIsPositionOrGroup] = useState(position.type);
	const [title, setTitle] = useState(position.title);
	const [groupEmail, setGroupEmail] = useState(position.groupEmail);
	const [ccEmailAddresses, setCCEmailAddresses] = useState(position.ccEmailAddresses);
	const [chairVotesInTie, setChairVotesInTie] = useState(position.chairVotesInTie || false);
	const [allowGroupVoting, setAllowGroupVoting] = useState(position.allowGroupVoting);
	const [votingDays, setVotingDays] = useState(position.votingDays);
	const [path, setPath] = useState(position.path);
	const [isValid, setIsValid] = useState({
		create: true,
		title: true,
		parent: true,
		form: true,
		touched: false,
	});
	const [parentArray, setParentArray] = useState([
		{ name: '---', key: '---' },
		{ name: 'Root (top level)', key: 'org_chart' },
	]);

	// useEffect(() => {
	// 	console.log('position:', props.position);
	// }, [props.position]);

	//handles closing the modal
	const handleClose = () => {
		props.onClose();
	};

	//gets the list of path positions; sets setParentArray
	useEffect(() => {
		const getPositions = async () => {
			// console.log(...allPositions);
			setLoading(true);
			const response = await GetParentPositions(props.allPositions);
			// console.log(response);
			const newArray = [...response];
			const sortedChoices = newArray.sort((a, b) =>
				a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
			);
			setParentArray((prevState) => [...prevState, ...sortedChoices]);
			setLoading(false);
		};

		getPositions();
	}, [props.allPositions]);

	//sets if a position or a group
	const handlePositionOrGroupChange = (e) => {
		setIsPositionOrGroup(e.target.value);
		setIsValid((prevState) => ({
			...prevState,
			create: true,
		}));
	};

	//sets allow group voting
	const handleAllowGroupVotingChange = (e) => {
		setAllowGroupVoting(e.target.value);
	};

	//sets chair breaks tie
	const handleChairVotesInTieChange = (e) => {
		setChairVotesInTie(e.target.value);
	};

	//sets the voting days
	const handleVotingDaysChange = (e) => {
		setVotingDays(e.target.value);
	};

	//sets the group email address
	const handleGroupEmailAddressChange = (e) => {
		setGroupEmail(e.target.value);
	};

	//sets the cc email addresses
	const handleCCEmailAddressesChange = (e) => {
		setCCEmailAddresses(e.target.value);
	};

	//sets the title
	const handleTitleChange = (e) => {
		setTitle(e.target.value);
		if (e.target.value.trim() !== '') {
			setIsValid((prevState) => ({
				...prevState,
				title: true,
			}));
			if (ParentPath(path) === 'org_chart') {
				setPath(`${ParentPath(path)}/${e.target.value}`);
			} else {
				setPath(`${ParentPath(path)}/children/${e.target.value}`);
			}
		} else {
			setIsValid((prevState) => ({
				...prevState,
				title: false,
			}));
		}
	};

	//sets the path
	const handlePathChange = (e) => {
		if (e.target.value === 'org_chart') {
			setPath(`${e.target.value}/${title}`);
		} else {
			setPath(`${e.target.value}/children/${title}`);
		}
	};

	//checks path validity
	useEffect(() => {
		if (path !== 'null' && path !== undefined) {
			setIsValid((prevState) => ({
				...prevState,
				path: true,
			}));
		} else {
			setIsValid((prevState) => ({
				...prevState,
				path: false,
			}));
		}
	}, [path]);

	//checks form validity
	useEffect(() => {
		if (isValid.create && isValid.title && isValid.path) {
			setIsValid((prevState) => ({
				...prevState,
				form: true,
				touched: true,
			}));
		} else {
			setIsValid((prevState) => ({
				...prevState,
				form: false,
				touched: true,
			}));
		}
	}, [isValid.create, isValid.title, isValid.path]);

	//handles form submission
	const handleOnSubmit = async (e) => {
		e.preventDefault();
		const timestamp = new Date().toISOString();

		const newData = {
			type: isPositionOrGroup,
			title: title,
			path: path,
			allowGroupVoting: JSON.parse(allowGroupVoting),
			groupEmail: groupEmail,
			votingDays: votingDays,
			chairVotesInTie: JSON.parse(chairVotesInTie),
			ccEmailAddresses: ccEmailAddresses,
		};

		//defining initial data object to log
		let objToLog = {
			history: {
				[timestamp]: {
					user: claimsCtx?.claims?.email,
					note: 'Updated',
					data: {},
				},
			},
		};

		//loop through original position data, if it has changed then log the changes; exclude history
		for (const originalProp in originalData) {
			if (originalProp !== 'history') {
				for (const newProp in newData) {
					if (originalProp === newProp) {
						if (originalData[originalProp] !== newData[newProp]) {
							objToLog[newProp] = newData[newProp];
							objToLog.history[timestamp].data[newProp] = newData[newProp];
						}
					}
				}
			}
		}

		const db = props.db;
		const originalPath = ParentPath(originalData.path);
		const newPath = ParentPath(newData.path);
		let docRef, docRefNewPath;

		//adds new data to the document
		//if top level, then no children subfolder
		if (originalPath === 'org_chart') {
			docRef = doc(db, `${originalPath}`, originalData.title);
		}
		//else add children subfolder
		else {
			docRef = doc(db, `${originalPath}/children`, originalData.title);
		}

		//logs the history
		await setDoc(docRef, objToLog, { merge: true });
		//logs the data
		await setDoc(docRef, newData, { merge: true });

		//defines new path in case needed below for path change
		//if top level, then no children subfolder
		if (newPath === 'org_chart') {
			docRefNewPath = doc(db, `${newPath}`, title);
		}
		//else add children subfolder
		else {
			docRefNewPath = doc(db, `${newPath}/children`, title);
		}

		//if path change (or title, as title updates the path), copy all data and move to new document with correct title
		if (newData.path !== originalData.path) {
			const docSnap = await getDoc(docRef);
			const allData = docSnap.data();
			await setDoc(docRefNewPath, allData);

			//verify if docRef has children before deleting
			const orgTree = await GetOrgTree(`${originalData.path}/children`, db);
			await MoveChildren(orgTree, db, `/children/${originalData.title}`, claimsCtx?.claims?.email);
			await deleteDoc(docRef);
		}

		props.onReload();
		handleClose();
		window.scrollTo({
			top: 0,
			behavior: 'smooth', // Optional: smooth scrolling effect
		});
	};

	//handles delete button
	const handleOnDelete = async (e) => {
		e.preventDefault();
		const db = props.db;

		const parentPath = ParentPath(originalData.path);
		const originalPath = `${originalData.path}/children`;

		const subColRef = collection(db, originalPath);
		const colSnap = await getDocs(subColRef);

		//if position has children, then move the children up
		if (colSnap.docs.length > 0) {
			const orgTree = await GetOrgTree(`${originalData.path}/children`, db);
			await MoveChildren(orgTree, db, `/children/${originalData.title}`, claimsCtx?.claims?.email);
		}

		//delete the document
		const docRef = doc(db, `${parentPath}/children`, originalData.title);
		await deleteDoc(docRef);

		props.onReload();
		handleClose();
	};

	//display if loading
	if (loading) {
		return (
			<Dialog
				open={props.open}
				onClose={handleClose}
				fullWidth
				scroll='body'
				PaperProps={{ sx: { minHeight: '30vh' } }}
			>
				<LoadingSpinner />
			</Dialog>
		);
	}

	//if not loading, display this
	return (
		<Dialog
			open={props.open}
			onClose={handleClose}
			fullWidth
			scroll='body'
			PaperProps={{ sx: { minHeight: '30vh', padding: '0.5rem' } }}
		>
			<DialogTitle>Modify {position.title}</DialogTitle>

			{/* position or group */}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
				}}
			>
				<Tooltip text="A position is a job held by one individual. Select 'Group' for jobs held by more than one individual." />
				<span>&nbsp;</span>
				<FormControl>
					<FormLabel id='type'>Type</FormLabel>
					<RadioGroup
						aria-labelledby='type'
						value={isPositionOrGroup}
						name='radio-buttons-group'
						row
						onChange={handlePositionOrGroupChange}
					>
						<FormControlLabel value='group' control={<Radio />} label='Group' />
						<FormControlLabel value='position' control={<Radio />} label='Position' />
					</RadioGroup>
				</FormControl>
			</Box>

			{/* title */}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
				}}
			>
				<Tooltip text='Title of the position.' />
				<span>&nbsp;</span>
				<TextField
					id='positionTitle'
					label='Title'
					fullWidth
					margin='normal'
					variant='outlined'
					defaultValue={title}
					onChange={handleTitleChange}
					required
				/>
			</Box>

			{/* path */}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
					marginTop: '1rem',
				}}
			>
				<Tooltip text='Select who this position or group reports to.' />
				<span>&nbsp;</span>
				<FormControl fullWidth>
					<InputLabel id='reports-to'>Reports To</InputLabel>
					<Select
						labelId='reports-to'
						id='reports-to'
						value={ParentPath(path)}
						label='Reports To'
						onChange={handlePathChange}
					>
						{parentArray.map((item) => (
							<MenuItem key={item.key} value={item.key}>
								{item.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>

			{/* group email address */}
			{isPositionOrGroup === 'group' && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}
				>
					<Tooltip text='Enter the groups email address.' />
					<span>&nbsp;</span>
					<TextField
						id='groupEmailAddress'
						label='Group Email Address'
						fullWidth
						margin='normal'
						variant='outlined'
						defaultValue={groupEmail}
						onChange={handleGroupEmailAddressChange}
					/>
				</Box>
			)}

			{/* CC email addresses */}
			{isPositionOrGroup === 'group' && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}
				>
					<Tooltip text="Enter any email addresses that should be CC'd on the votes. Separate multiple emails with commas." />
					<span>&nbsp;</span>
					<TextField
						id='ccEmailAddresses'
						label='CC Email Addresses'
						fullWidth
						margin='normal'
						variant='outlined'
						defaultValue={ccEmailAddresses}
						onChange={handleCCEmailAddressesChange}
					/>
				</Box>
			)}

			{/* allow group voting */}
			{isPositionOrGroup === 'group' && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}
				>
					<Tooltip text='Select if voting should be allowed for this group.' />
					<span>&nbsp;</span>
					<FormControl disabled={groupEmail === '' || groupEmail === undefined || groupEmail === null}>
						<FormLabel id='type'>Voting</FormLabel>
						<RadioGroup
							aria-labelledby='type'
							value={allowGroupVoting?.toString() || ''}
							name='radio-buttons-group'
							row
							onChange={handleAllowGroupVotingChange}
						>
							<FormControlLabel value={'true'} control={<Radio />} label='Allowed' />
							<FormControlLabel value={'false'} control={<Radio />} label='Not Allowed' />
						</RadioGroup>
					</FormControl>
				</Box>
			)}

			{/* default days for voting */}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
				}}
			>
				<Tooltip text='Enter the number of days allowed for a non-urgent vote.' />
				<span>&nbsp;</span>
				<TextField
					id='votingDuration'
					label='Voting Days'
					disabled={!allowGroupVoting}
					fullWidth
					margin='normal'
					variant='outlined'
					defaultValue={votingDays || ''}
					onChange={handleVotingDaysChange}
				/>
			</Box>

			{/* chair breaks tie only */}
			{isPositionOrGroup === 'group' && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}
				>
					<Tooltip text='Select if the Chair only votes if there is a tie.' />
					<span>&nbsp;</span>
					<FormControl disabled={groupEmail === '' || groupEmail === undefined || groupEmail === null}>
						<FormLabel id='type'>Chair Votes in Tie Only</FormLabel>
						<RadioGroup
							aria-labelledby='type'
							value={chairVotesInTie?.toString() || ''}
							name='radio-buttons-group'
							row
							onChange={handleChairVotesInTieChange}
						>
							<FormControlLabel value={'true'} control={<Radio />} label='True' />
							<FormControlLabel value={'false'} control={<Radio />} label='False' />
						</RadioGroup>
					</FormControl>
				</Box>
			)}

			<Stack direction='row' spacing={2} justifyContent='center' mt='2rem' mb='2rem'>
				<Button variant='contained' startIcon={<SaveRoundedIcon />} color='saveButton' onClick={handleOnSubmit}>
					SAVE
				</Button>
				<Tooltip text='This action cannot be undone.'>
					<Button variant='contained' startIcon={<DeleteIcon />} color='error' onClick={handleOnDelete}>
						DELETE
					</Button>
				</Tooltip>
			</Stack>
		</Dialog>
	);
}
