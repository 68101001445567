import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import {
	Alert,
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputAdornment,
	TextField,
	Stack,
	Stepper,
	Step,
	StepLabel,
	Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Add } from '@mui/icons-material';

import LoadingSpinner from '../ui/LoadingSpinner';
import Tooltip from '../ui/Tooltip';
import GetOrgTree from './GetOrgTree';
import GetParentPositions from './GetParentPositions';
import AddDeleteGroups from './AddDeleteGroups';

const steps = ['Position Settings', 'Set Person', 'Set Committees'];

export default function LeadershipModalCreateNewPosition(props) {
	const [loading, setLoading] = useState(null);
	const [loadingGroups, setLoadingGroups] = useState(null);
	const [error, setError] = useState(null);

	const [groups, setGroups] = useState([]);
	const [newPosition, setNewPosition] = useState({
		positionTitle: '',
		active: true,
		group: '',
		groups: [],
		currentPerson: {
			label: '',
			email: '',
			startDate: null,
			endDate: null,
		},
		futurePerson: {
			label: '',
			email: '',
			startDate: null,
			endDate: null,
		},
		compensationType: '',
		compensationAmount: null,
		tickProjectID: '',
	});
	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set());
	const [peopleArray, setPeopleArray] = useState([]);
	const [allPositions, setAllPositions] = useState([]);

	//allows step 1 and 2 to be option -- uses zero order numbering
	const isStepOptional = (step) => {
		return step === 1 ? true : step === 2 ? true : false;
	};

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleSkip = () => {
		if (!isStepOptional(activeStep)) {
			// You probably want to guard against something like this,
			// it should never occur unless someone's actively trying to break something.
			throw new Error("You can't skip a step that isn't optional.");
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(activeStep);
			return newSkipped;
		});
	};

	const handleCurrentPersonChange = (e) => {
		if (e === null) {
			setNewPosition((prevState) => ({
				...prevState,
				currentPerson: { ...prevState.currentPerson, label: '', email: '' },
			}));
		} else {
			setNewPosition((prevState) => ({
				...prevState,
				currentPerson: { ...prevState.currentPerson, label: e.label, email: e.email },
			}));
		}
	};

	const handleCurrentPersonDateChange = (e) => {
		setNewPosition((prevState) => ({
			...prevState,
			currentPerson: { ...prevState.currentPerson, [e.id]: e.value },
		}));
	};

	const handleFuturePersonChange = (e) => {
		if (e === null) {
			setNewPosition((prevState) => ({
				...prevState,
				futurePerson: { ...prevState.futurePerson, label: '', email: '' },
			}));
		} else {
			setNewPosition((prevState) => ({
				...prevState,
				futurePerson: { ...prevState.futurePerson, label: e.label, email: e.email },
			}));
		}
	};

	const handleFuturePersonDateChange = (e) => {
		setNewPosition((prevState) => ({
			...prevState,
			futurePerson: { ...prevState.futurePerson, [e.id]: e.value },
		}));
	};

	//handles newPosition changes
	const handleChange = (e) => {
		setNewPosition((prevState) => ({
			...prevState,
			[e.target.id]: e.target.value,
		}));
	};

	//gets the list of groups
	useEffect(() => {
		setLoading(true);
		const groupData = [];
		props.tableData.forEach((position) => {
			if (position.group !== undefined) {
				let found = false;
				for (let i = 0; i < groupData.length; i++) {
					if (position.group === groupData[i]) {
						found = true;
					}
				}
				if (found === false) {
					groupData.push(position.group);
				}
			}
		});
		setGroups(groupData);
		setLoading(null);
	}, [props.tableData]);

	//gets list of people for leadership position
	useEffect(() => {
		setLoading(true);
		if (props.allPeople.title === 'Error') {
			setError(props.allPeople.message);
		} else {
			setPeopleArray(props.allPeople);
		}
		setLoading(null);
	}, [props]);

	//sets groups if not already defined
	useEffect(() => {
		if (newPosition.groups === undefined || newPosition.groups[0] === undefined || newPosition.groups[0] === '') {
			setNewPosition((prevState) => ({
				...prevState,
				groups: [{ id: `${new Date().toISOString()}`, group: '', groupKey: '', memberType: '' }],
			}));
		}
	}, [newPosition]);

	//gets the positions & groups; sets allPositions
	useEffect(() => {
		if (props.db) {
			const getData = async () => {
				setLoadingGroups(true);
				setAllPositions([]);
				const data = await GetOrgTree('org_chart', props.db);
				const response = await GetParentPositions(data);
				const newArray = [...response];
				const sortedChoices = newArray.sort((a, b) =>
					a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
				);
				const items = [];
				sortedChoices.map((choice, index) => {
					return items.push({ label: choice.name, key: choice.id, type: choice.type });
				});

				setAllPositions((prevState) => [...prevState, ...items]);

				setLoadingGroups(false);
			};

			getData();
		}
	}, [props.db]);

	//adds a group
	const addGroup = () => {
		setNewPosition((prevState) => ({
			...prevState,
			groups: [...prevState.groups, { id: `${new Date().toISOString()}`, memberType: '', group: '', groupKey: '' }],
		}));
	};

	//removes the group with the corresponding id
	const handleRemoveGroup = (id) => {
		setNewPosition((prevState) => ({
			...prevState,
			groups: newPosition.groups.filter((item) => item.id !== id),
		}));
	};

	//updates the group for the position
	const handleGroupChange = (e) => {
		const tempArray = [...newPosition.groups];

		for (let object of tempArray) {
			if (object.id === e.id) {
				object.group = e.group;
				object.groupKey = e.groupKey;
			}
		}

		setNewPosition((prevState) => ({
			...prevState,
			groups: [...tempArray],
		}));
	};

	//updates the member type for the position
	const handleMemberTypeChange = (e) => {
		const tempArray = [...newPosition.groups];

		for (let object of tempArray) {
			if (object.id === e.id) {
				object.memberType = e.memberType;
			}
		}

		setNewPosition((prevState) => ({
			...prevState,
			groups: [...tempArray],
		}));
	};

	//submits the newPosition
	const onSubmit = () => {
		props.submit({
			position: newPosition,
			newEmail: newPosition.currentPerson.email,
		});
	};

	if (error) {
		return (
			<Dialog open={props.open} onClose={props.cancel} fullWidth>
				<DialogTitle sx={{ fontWeight: 'bold', fontSize: '2rem' }}>Error</DialogTitle>
				<DialogContent>
					<Alert severity='error' sx={{ margin: '1rem' }}>
						<strong>
							There was an error. Logout and log back in. If the error continues to occur, contact IT and provide the
							error message below.
						</strong>
					</Alert>
					<Alert severity='info' sx={{ margin: '1rem' }}>
						{error}
					</Alert>
				</DialogContent>
				<DialogActions>
					<Button variant='contained' onClick={props.cancel} color='error'>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	if (loading || loadingGroups) {
		return (
			<Dialog
				open={props.open}
				onClose={props.cancel}
				fullWidth
				scroll='body'
				PaperProps={{ sx: { minWidth: '60vw', minHeight: '30vh', padding: '1.5rem' } }}
			>
				<DialogTitle>Create a New Position</DialogTitle>
				<Box sx={{ width: '100%' }}>
					{/* stepper icons */}
					<Stepper activeStep={activeStep} alternativeLabel sx={{ mt: '1rem', mb: '1rem' }}>
						{steps.map((label, index) => {
							const stepProps = {};
							const labelProps = {};
							if (isStepOptional(index)) {
								labelProps.optional = <Typography variant='caption'>Optional</Typography>;
							}
							if (isStepSkipped(index)) {
								stepProps.completed = false;
							}
							return (
								<Step key={label} {...stepProps}>
									<StepLabel {...labelProps}>{label}</StepLabel>
								</Step>
							);
						})}
					</Stepper>

					<LoadingSpinner />
				</Box>
			</Dialog>
		);
	}

	return (
		<Dialog
			open={props.open}
			onClose={props.cancel}
			fullWidth
			scroll='body'
			PaperProps={{ sx: { minWidth: '60vw', minHeight: '30vh', padding: '1.5rem' } }}
		>
			<DialogTitle>Create a New Position</DialogTitle>

			<Box sx={{ width: '100%' }}>
				{/* stepper icons */}
				<Stepper activeStep={activeStep} alternativeLabel sx={{ mt: '1rem', mb: '1rem' }}>
					{steps.map((label, index) => {
						const stepProps = {};
						const labelProps = {};
						if (isStepOptional(index)) {
							labelProps.optional = <Typography variant='caption'>Optional</Typography>;
						}
						if (isStepSkipped(index)) {
							stepProps.completed = false;
						}
						return (
							<Step key={label} {...stepProps}>
								<StepLabel {...labelProps}>{label}</StepLabel>
							</Step>
						);
					})}
				</Stepper>

				{/* Step 0 -  Position Settings */}
				{activeStep === 0 && (
					<DialogContent>
						{/* position title */}
						<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
							<Tooltip text='Title of the position to create.' />
							<span>&nbsp;</span>
							<TextField
								id='positionTitle'
								label='Position Title'
								fullWidth
								margin='normal'
								variant='outlined'
								defaultValue={newPosition.positionTitle}
								onChange={handleChange}
								required
							/>
						</Box>

						{/* grouping */}
						<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
							<Tooltip text='If applicable, select a group for the position.  A new group can be entered if needed.  The grouping only applies to how the position is displayed on the Leadership Positions table.' />
							<span>&nbsp;</span>
							<Autocomplete
								fullWidth
								id='group'
								options={groups}
								freeSolo
								sx={{ mt: '0.5rem', mb: '0.75rem', color: 'secondary' }}
								value={newPosition.group}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Group'
										InputProps={{
											...params.InputProps,
											type: 'search',
										}}
									/>
								)}
								onInputChange={(e, newValue) => {
									const event = {
										target: {
											value: newValue.slice(0, 1).toUpperCase() + newValue.slice(1),
											id: 'group',
										},
									};
									handleChange(event);
								}}
							/>
						</Box>

						{/*compensation type */}
						<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
							<Tooltip text='Select the compensation type.  Select "None" if there is no compensation.' />
							<span>&nbsp;</span>
							<Autocomplete
								id='compensationType'
								options={['None', 'CAT', 'Stipend', 'WET']}
								value={newPosition.compensationType}
								isOptionEqualToValue={(option, value) => option.key === value.key}
								onChange={(e, newValue) => {
									const event = {
										target: {
											value: newValue,
											id: 'compensationType',
										},
									};

									handleChange(event);
								}}
								sx={{ width: '100%' }}
								renderInput={(params) => <TextField {...params} label='Compensation Type' />}
							/>
						</Box>

						{/* compensation amount - compensation type empty or None */}
						{(newPosition.compensationType === '' || newPosition.compensationType === 'None') && (
							<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
								<Tooltip text='Enter the amount of compensation for the position.' />
								<span>&nbsp;</span>
								<TextField
									id='compensationAmount'
									label='Compensation Amount'
									fullWidth
									margin='dense'
									variant='outlined'
									color='primary'
									defaultValue={newPosition.compensationAmount}
									onChange={handleChange}
									required
									disabled
									type='number'
								/>
							</Box>
						)}

						{/* compensation amount - compensation type not empty nor None */}
						{newPosition.compensationType !== '' && newPosition.compensationType !== 'None' && (
							<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
								<Tooltip text='Enter the amount of compensation for the position.' />
								<span>&nbsp;</span>
								<TextField
									id='compensationAmount'
									label='Compensation Amount'
									fullWidth
									margin='dense'
									variant='outlined'
									color='primary'
									defaultValue={newPosition.compensationAmount}
									onChange={handleChange}
									required
									type='number'
									InputProps={{
										endAdornment:
											newPosition.compensationType !== 'Stipend' ? (
												<InputAdornment position='end'>hours per month</InputAdornment>
											) : (
												<InputAdornment position='end'>per month</InputAdornment>
											),
										startAdornment:
											newPosition.compensationType === 'Stipend' ? (
												<InputAdornment position='start'>$</InputAdornment>
											) : (
												''
											),
									}}
								/>
							</Box>
						)}

						{/* tick  project id */}
						<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
							<Tooltip text='Enter the Tick project ID, if known.' />
							<span>&nbsp;</span>
							<TextField
								id='tickProjectID'
								label='Tick Project ID'
								fullWidth
								margin='dense'
								variant='outlined'
								value={newPosition.tickProjectID}
								onChange={handleChange}
							/>
						</Box>
					</DialogContent>
				)}

				{/* Step 1 - Set Person */}
				{/*current person */}
				{activeStep === 1 && <DialogTitle mb='-1rem'>Current Position Holder</DialogTitle>}
				{activeStep === 1 && (
					<DialogContent>
						<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
							<Tooltip text='Select the person who will work this position, if known.  Leave empty if unknown.' />
							<span>&nbsp;</span>
							<Autocomplete
								fullWidth
								ListboxProps={{ style: { maxHeight: '20rem' } }}
								id='currentPerson'
								options={peopleArray}
								sx={{ mt: '0.5rem', mb: '0.75rem' }}
								renderInput={(params) => <TextField {...params} label='Person' />}
								value={newPosition.currentPerson.email === '' ? '' : newPosition.currentPerson}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								onChange={(e, newValue) => {
									handleCurrentPersonChange(newValue);
								}}
							/>
						</Box>

						{/* start date & end date*/}
						<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
							<Tooltip text='Enter and Start Date and/or End Date if known.' />
							<span>&nbsp;</span>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									label='Start Date'
									value={newPosition.currentPerson.startDate ? dayjs(newPosition.currentPerson.startDate) : null}
									onChange={(newValue) => {
										const event = {
											value: newValue === null ? null : newValue.$d,
											id: 'startDate',
										};
										handleCurrentPersonDateChange(event);
									}}
									sx={{
										width: '100%',
									}}
									components={{
										TextField: TextField,
									}}
									componentsProps={{
										textField: {
											label: 'Start Date',
										},
									}}
								/>
							</LocalizationProvider>
							<span>&nbsp;</span>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									label='End Date'
									value={newPosition.currentPerson.endDate ? dayjs(newPosition.currentPerson.endDate) : null}
									onChange={(newValue) => {
										const event = {
											value: newValue === null ? null : newValue.$d,
											id: 'endDate',
										};
										handleCurrentPersonDateChange(event);
									}}
									sx={{
										width: '100%',
									}}
									components={{
										TextField: TextField,
									}}
									componentsProps={{
										textField: {
											label: 'End Date',
										},
									}}
								/>
							</LocalizationProvider>
						</Box>
					</DialogContent>
				)}
				{/*future person */}
				{activeStep === 1 && <DialogTitle mb='-1rem'>Future Position Holder</DialogTitle>}
				{activeStep === 1 && (
					<DialogContent>
						<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
							<Tooltip text='Select the person who will work this position, if known.  Leave empty if unknown.' />
							<span>&nbsp;</span>
							<Autocomplete
								fullWidth
								ListboxProps={{ style: { maxHeight: '20rem' } }}
								id='futurePerson'
								options={peopleArray}
								sx={{ mt: '0.5rem', mb: '0.75rem' }}
								renderInput={(params) => <TextField {...params} label='Person' />}
								value={newPosition.futurePerson.email === '' ? '' : newPosition.futurePerson}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								onChange={(e, newValue) => {
									handleFuturePersonChange(newValue);
								}}
							/>
						</Box>

						{/* start date & end date*/}
						<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
							<Tooltip text='Enter and Start Date and/or End Date if known.' />
							<span>&nbsp;</span>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									label='Start Date'
									value={newPosition.futurePerson.startDate ? dayjs(newPosition.futurePerson.startDate) : null}
									onChange={(newValue) => {
										const event = {
											value: newValue === null ? null : newValue.$d,
											id: 'startDate',
										};
										handleFuturePersonDateChange(event);
									}}
									sx={{
										width: '100%',
									}}
									components={{
										TextField: TextField,
									}}
									componentsProps={{
										textField: {
											label: 'Start Date',
										},
									}}
								/>
							</LocalizationProvider>
							<span>&nbsp;</span>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									label='End Date'
									value={newPosition.futurePerson.endDate ? dayjs(newPosition.futurePerson.endDate) : null}
									onChange={(newValue) => {
										const event = {
											value: newValue === null ? null : newValue.$d,
											id: 'endDate',
										};
										handleFuturePersonDateChange(event);
									}}
									sx={{
										width: '100%',
									}}
									components={{
										TextField: TextField,
									}}
									componentsProps={{
										textField: {
											label: 'End Date',
										},
									}}
								/>
							</LocalizationProvider>
						</Box>
					</DialogContent>
				)}

				{/* Step 2 - Set Committeess */}
				{activeStep === 2 && (
					<DialogContent>
						<Stack display='flex' justifyContent='center' alignItems='center' color='secondary' mt={1}>
							{newPosition.groups.map((group, key) => {
								return (
									<AddDeleteGroups
										key={key}
										allPositions={allPositions}
										group={group}
										onHandleRemoveGroup={handleRemoveGroup}
										onHandleGroupChange={handleGroupChange}
										onHandleMemberTypeChange={handleMemberTypeChange}
									/>
								);
							})}

							<Button color='primary' variant='outlined' startIcon={<Add />} onClick={addGroup}>
								Add Group
							</Button>
							{/* </ButtonGroup> */}
						</Stack>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}></Box>
					</DialogContent>
				)}

				{/* stepper buttons */}
				<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
					{/* cancel button */}
					<Button variant='contained' onClick={props.cancel} color='inherit' sx={{ mr: 1 }}>
						CANCEL
					</Button>

					<Box sx={{ flex: '1 1 auto' }} />

					{/* back button */}
					<Button variant='contained' color='inherit' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
						BACK
					</Button>

					{/* skip button */}
					<Button variant='contained' disabled={activeStep === 0} color='inherit' onClick={handleSkip} sx={{ mr: 1 }}>
						SKIP
					</Button>

					{/* if not the last step, show Next button */}
					{activeStep !== steps.length - 1 && (
						<Button
							disabled={activeStep === 0 && newPosition.positionTitle === ''}
							variant='contained'
							onClick={handleNext}
						>
							NEXT
						</Button>
					)}

					{/* if the last step, show Create button */}
					{activeStep === steps.length - 1 && (
						<Button variant='contained' onClick={onSubmit}>
							CREATE
						</Button>
					)}
				</Box>
			</Box>
		</Dialog>
	);
}
