import React, { useContext, useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import moment from 'moment';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Typography, Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import PageCards from '../components/ui/PageCards';
import GetUserData from '../components/auth/GetUserData';
import { db, functions } from '../App';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import CustomClaimsContext from '../components/auth/CustomClaimsContext';
import AlertContext from '../components/ui/AlertContext';

export default function Vacation() {
	const claimsCtx = useContext(CustomClaimsContext);
	const alertCtx = useContext(AlertContext);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [maxDays, setMaxDays] = useState(null);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [loading, setLoading] = useState(true);
	const [submitting, setSubmitting] = useState(false);

	// Get the user data and MaxDays
	useEffect(() => {
		const fetchMaxDays = async () => {
			const docRef = doc(db, 'settings', 'Outside_US_OU');
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				setMaxDays(docSnap.data().MaxDays);
			} else {
				console.log('No such document!');
			}
		};

		const getUserData = async () => {
			const tempData = await GetUserData('OutsideUS');
			setStartDate(tempData?.StartDate ? moment(tempData.StartDate.toDate()) : null);
			setEndDate(tempData?.EndDate ? moment(tempData.EndDate.toDate()) : null);
			setLoading(false); // Set loading to false after fetching data
		};

		fetchMaxDays();
		getUserData();
	}, []);

	useEffect(() => {
		if (startDate && endDate && maxDays) {
			const isEndDateAfterStartDate = endDate.isAfter(startDate);
			const isWithinMaxDays = endDate.diff(startDate, 'days') <= maxDays;
			setIsSubmitDisabled(!(isEndDateAfterStartDate && isWithinMaxDays));
		} else {
			setIsSubmitDisabled(true);
		}
	}, [startDate, endDate, maxDays]);

	const switchUserOU = async () => {
		setSubmitting(true);
		const OutsideUS_SwitchUsers = httpsCallable(functions, 'OutsideUS_SwitchUsers');
		try {
			const resp = await OutsideUS_SwitchUsers({
				startDate: startDate.toISOString(),
				endDate: endDate.toISOString(),
				uid: claimsCtx.claims.user_id,
			});

			alertCtx.setMessage(resp.data.message);
			alertCtx.setSeverity(resp.data.success ? 'success' : 'error');
			alertCtx.setTimer(10000);
			alertCtx.setTitle(resp.data.success ? 'User Updated Successfully' : 'User Not Updated');
			alertCtx.setActive(true);
		} catch (err) {
			console.error('Error updating user OU:', err);
			alertCtx.setMessage(err);
			alertCtx.setSeverity('error');
			alertCtx.setTitle('User Not Updated');
			alertCtx.setActive(true);
		}
		setSubmitting(false);
	};

	if (loading) {
		return (
			<Box sx={{ width: '100%' }}>
				<Grid container spacing={1}>
					{/* page header */}
					<Grid xs={12}>
						<PageCards>
							<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
								Vacation
							</Typography>

							<Typography
								variant='h7'
								color='primary'
								mb={2}
								textAlign={'center'}
								justifyContent={'center'}
								display={'block'}
							>
								Utilize this option if you are traveling outside of the US and want access to your EPT Google Workspace
								for the following items:
								<br /> - Email
								<br /> - Calendar
								<br /> - Google Chat
								<br /> - Google Meet
								<br /> - Keep
								<br /> - Tasks
								<br />
								<br />
								No additional Google Workspace items are available when outside the US.
							</Typography>

							<LoadingSpinner />
						</PageCards>
					</Grid>
				</Grid>
			</Box>
		);
	}

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container spacing={1}>
				{/* user input */}
				<Grid xs={12}>
					<PageCards>
						<Typography variant='h3' color='primary' mb={2} textAlign={'center'}>
							Vacation
						</Typography>

						<Typography
							variant='h7'
							color='primary'
							mb={2}
							textAlign={'center'}
							justifyContent={'center'}
							display={'block'}
						>
							Utilize this option if you are traveling outside of the US and want access to your EPT Google Workspace
							for the following items:
							<br /> - Email
							<br /> - Calendar
							<br /> - Google Chat
							<br /> - Google Meet
							<br /> - Keep
							<br /> - Tasks
							<br />
							<br />
							No additional Google Workspace items are available when outside the US.
						</Typography>

						<Typography textAlign={'center'} fontWeight={'bold'}>
							Notes:
						</Typography>
						<Typography textAlign={'center'}>
							Access can take several hours to take affect.
							<br />
							The max number of days a user can request is 14. If you need more than 14 days you will need to log in
							after your prior request has finished and re-request another time period.
						</Typography>

						<Box mt={3}>
							<LocalizationProvider dateAdapter={AdapterMoment}>
								<Grid container spacing={2} justifyContent='center'>
									<Grid>
										<DatePicker
											label='Start Day'
											value={startDate}
											onChange={(newValue) => setStartDate(newValue)}
											slots={{ textField: TextField }}
										/>
									</Grid>
									<Grid>
										<DatePicker
											label='Last Day'
											value={endDate}
											onChange={(newValue) => setEndDate(newValue)}
											slots={{ textField: TextField }}
										/>
									</Grid>
								</Grid>
							</LocalizationProvider>
						</Box>

						<Grid container justifyContent='center' mt={2}>
							{submitting && <LoadingSpinner />}
							{!submitting && (
								<Button variant='contained' color='primary' onClick={switchUserOU} disabled={isSubmitDisabled}>
									Submit
								</Button>
							)}
						</Grid>
					</PageCards>
				</Grid>
			</Grid>
		</Box>
	);
}
