import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../App';

/**
 *
 * Input the starting string path to search through Firestore, looks for children and returns a tree
 * @param {string} startingPath - must start from a collection
 * @returns an array tree from the starting path
 */
export default async function GetOrgTree(startingPath) {
	//gets the root items
	const getRootData = async () => {
		return new Promise(async (finish) => {
			const dataArray = [];
			const snapShot = await getDocs(collection(db, startingPath));
			const documents = snapShot.docs;

			for (let i = 0; i < documents.length; i++) {
				const document = documents[i].data();
				const children = await getSubItems(`${startingPath}/${document.title}`);
				if (children.length > 0) {
					document.children = [];
					document.children.push(...children);
				}
				dataArray.push(document);
			}

			finish(dataArray);
		});
	};

	//gets the sub-items
	const getSubItems = async (subitem) => {
		return new Promise(async (finish) => {
			const dataArray = [];
			let subfolder = `${subitem}/children`;
			const snapShot = await getDocs(collection(db, `${subfolder}`));
			const documents = snapShot.docs;

			for (let i = 0; i < documents.length; i++) {
				const document = documents[i].data();
				const children = await getSubItems(`${subfolder}/${document.title}`);
				if (children.length > 0) {
					document.children = [];
					document.children.push(...children);
				}
				dataArray.push(document);
			}

			finish(dataArray);
		});
	};

	const tree = await getRootData(startingPath);
	return tree;
}
