import React, { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Typography, Button, Chip, Stack } from '@mui/material';
import { functions } from '../../App';
import PageCards from '../ui/PageCards';
import { Timestamp } from 'firebase/firestore';
import LoadingSpinner from '../ui/LoadingSpinner';
import ReusableTable from '../ui/ReusableTable';

export default function VacationAdmin() {
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [removing, setRemoving] = useState(false);

	useEffect(() => {
		const fetchVacationRequests = async () => {
			setLoading(true);
			const OutsideUS_GetAllUsers = httpsCallable(functions, 'OutsideUS_GetAllUsers');
			try {
				const result = await OutsideUS_GetAllUsers();
				// Sort users by name
				const sortedUsers = result.data.data.sort((a, b) => a.name.localeCompare(b.name));
				setUsers(sortedUsers);
			} catch (error) {
				console.error('Error fetching vacation requests:', error);
				setError(error.message);
			}
			setLoading(false);
		};

		fetchVacationRequests();
	}, []);

	const handleRemoveAccess = async (uid) => {
		setRemoving(true);
		const OutsideUS_RemoveAccess = httpsCallable(functions, 'OutsideUS_RemoveAccess');
		try {
			await OutsideUS_RemoveAccess({ uid });
			setUsers(users.filter((user) => user.id !== uid)); // Update the list by removing the user
		} catch (error) {
			console.error('Error removing outside US access:', error);
			setError(error.message);
		}
		setRemoving(false);
	};

	if (loading) {
		return (
			<Box sx={{ width: '100%' }}>
				<Grid container spacing={1}>
					<Grid xs={12}>
						<PageCards>
							<Typography variant='h3' color='primary' mb={2} textAlign='center'>
								Admin Vacation View
							</Typography>
							<LoadingSpinner />
						</PageCards>
					</Grid>
				</Grid>
			</Box>
		);
	}

	if (error) {
		return (
			<Box sx={{ width: '100%' }}>
				<Grid container spacing={1}>
					<Grid xs={12}>
						<PageCards>
							<Typography variant='h3' color='primary' mb={2} textAlign='center'>
								Admin Vacation View
							</Typography>
							<Typography color='error'>{error}</Typography>
							<Typography mb={2} textAlign='center'>
								Note: Access will remain for 1 full day after the user's Last Day to allow for differences in timezones
								and travel delays.
							</Typography>
						</PageCards>
					</Grid>
				</Grid>
			</Box>
		);
	}

	const columns = [
		{ accessorKey: 'name', header: 'Name' },
		{ accessorKey: 'startDate', header: 'Start Date' },
		{ accessorKey: 'endDate', header: 'End Date' },
		{ accessorKey: 'status', header: 'Status' },
		{ accessorKey: 'actions', header: 'Actions' },
	];

	const data = users.map((user) => {
		const startDate = new Timestamp(user.StartDate._seconds, user.StartDate._nanoseconds).toDate();
		const endDate = new Timestamp(user.EndDate._seconds, user.EndDate._nanoseconds).toDate();

		return {
			name: user.name,
			startDate: startDate.toLocaleDateString(),
			endDate: endDate.toLocaleDateString(),
			status: user.Active ? <Chip label={'Active'} color='success' variant='outlined' /> : '',
			actions: removing ? (
				<LoadingSpinner />
			) : (
				<Button variant='contained' color='secondary' onClick={() => handleRemoveAccess(user.id)}>
					Remove Outside US Access
				</Button>
			),
		};
	});

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container spacing={1}>
				<Grid xs={12}>
					<PageCards>
						<Typography variant='h3' color='primary' mb={2} textAlign='center'>
							Admin Vacation View
						</Typography>
						<Typography mb={2} textAlign='center'>
							Note: Access will remain for 1 full day after the user's Last Day to allow for differences in timezones
							and travel delays.
						</Typography>

						<ReusableTable columns={columns} data={data} sortBy='startDate' sortAscending='true' />
					</PageCards>
				</Grid>
			</Grid>
		</Box>
	);
}
