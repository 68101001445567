import React from 'react';
import {
	Stack,
	Button,
	Box,
	Typography,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function ConditionGroup({
	conditionNode,
	sheetIndex,
	calculatedColumnIndex,
	handleConditionChange,
	deleteCondition,
	addCondition,
	addGroup,
	handleOperatorChange,
	saving,
	calculatedColumn,
}) {
	if (conditionNode.type === 'condition') {
		// Render a single condition
		return (
			<Stack direction='row' spacing={2} alignItems='center'>
				{/* Condition Fields */}
				<TextField
					disabled={saving || !calculatedColumn.editable}
					label='Column to Check'
					value={conditionNode.conditionName || ''}
					onChange={(e) =>
						handleConditionChange(sheetIndex, calculatedColumnIndex, conditionNode.id, 'conditionName', e.target.value)
					}
					fullWidth
					margin='normal'
					variant='outlined'
					size='small'
				/>

				<FormControl fullWidth variant='outlined' size='small' margin='normal'>
					<InputLabel>Condition Operator</InputLabel>
					<Select
						disabled={saving || !calculatedColumn.editable}
						value={conditionNode.conditionType || ''}
						onChange={(e) =>
							handleConditionChange(
								sheetIndex,
								calculatedColumnIndex,
								conditionNode.id,
								'conditionType',
								e.target.value
							)
						}
						label='Condition Operator'
					>
						<MenuItem value='EQUALS'>EQUALS</MenuItem>
						<MenuItem value='GREATER_THAN'>GREATER_THAN</MenuItem>
						<MenuItem value='LESS_THAN'>LESS_THAN</MenuItem>
						<MenuItem value='NOT_EQUALS'>NOT_EQUALS</MenuItem>
						<MenuItem value='BOOLEAN'>BOOLEAN</MenuItem>
					</Select>
				</FormControl>

				<TextField
					disabled={saving || !calculatedColumn.editable || conditionNode.conditionType === 'BOOLEAN'}
					label='Value'
					value={conditionNode.conditionValue || ''}
					onChange={(e) =>
						handleConditionChange(sheetIndex, calculatedColumnIndex, conditionNode.id, 'conditionValue', e.target.value)
					}
					fullWidth
					margin='normal'
					variant='outlined'
					size='small'
				/>

				{/* Delete Condition Button */}
				<IconButton
					color='error'
					disabled={saving || !calculatedColumn.editable}
					onClick={() => deleteCondition(sheetIndex, calculatedColumnIndex, conditionNode.id)}
				>
					<DeleteIcon />
				</IconButton>
			</Stack>
		);
	} else if (conditionNode.type === 'group') {
		// Render a group of conditions
		return (
			<Box
				mt={2}
				mb={2}
				p={2}
				pt={3}
				border={2}
				borderColor={conditionNode.operator === 'AND' ? 'green' : 'blue'}
				borderRadius={2}
				position='relative'
			>
				{/* Operator Label */}
				<Typography
					variant='caption'
					style={{
						position: 'absolute',
						top: -10,
						left: 10,
						backgroundColor: conditionNode.operator === 'AND' ? 'green' : 'blue',
						color: 'white',
						padding: '2px 8px',
						borderRadius: '4px',
					}}
				>
					{conditionNode.operator}
				</Typography>

				{/* Operator Selector for the group */}
				<Box mb={2}>
					<FormControl variant='outlined' size='small'>
						<InputLabel>Operator</InputLabel>
						<Select
							value={conditionNode.operator}
							onChange={(e) =>
								handleOperatorChange(sheetIndex, calculatedColumnIndex, conditionNode.id, e.target.value)
							}
							label='Operator'
						>
							<MenuItem value='AND'>AND</MenuItem>
							<MenuItem value='OR'>OR</MenuItem>
						</Select>
					</FormControl>
				</Box>

				{/* Render Children */}
				<Stack spacing={1}>
					{conditionNode.children.map((childNode) => (
						<ConditionGroup
							key={childNode.id}
							conditionNode={childNode}
							sheetIndex={sheetIndex}
							calculatedColumnIndex={calculatedColumnIndex}
							handleConditionChange={handleConditionChange}
							deleteCondition={deleteCondition}
							addCondition={addCondition}
							addGroup={addGroup}
							handleOperatorChange={handleOperatorChange}
							saving={saving}
							calculatedColumn={calculatedColumn}
						/>
					))}
				</Stack>

				{/* Add Condition and Add Group Buttons */}
				<Stack direction='row' spacing={1} mt={2} alignItems='center'>
					<Button variant='outlined' onClick={() => addCondition(sheetIndex, calculatedColumnIndex, conditionNode.id)}>
						Add Condition
					</Button>
					<Button variant='outlined' onClick={() => addGroup(sheetIndex, calculatedColumnIndex, conditionNode.id)}>
						Add Group
					</Button>

					{/* Delete Group Button */}
					<Button
						variant='outlined'
						startIcon={<DeleteIcon color='error' />}
						onClick={() => deleteCondition(sheetIndex, calculatedColumnIndex, conditionNode.id)}
						disabled={saving || !calculatedColumn.editable}
					>
						Delete Group
					</Button>
				</Stack>
			</Box>
		);
	} else {
		return null;
	}
}

export default ConditionGroup;
