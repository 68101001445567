/**
 * PRODUCTION ENVIRONMENT
 */
export default {
	apiKey: 'AIzaSyDjCOiF041OLb23P6TmLMX4irEJmeBNqzU',
	authDomain: 'portal.ept911.com', //'directory-71d6d.firebaseapp.com',
	databaseURL: 'https://directory-71d6d-default-rtdb.firebaseio.com',
	projectId: 'directory-71d6d',
	storageBucket: 'directory-71d6d.appspot.com',
	messagingSenderId: '461503283931',
	appId: '1:461503283931:web:6ef002b2b7a367c66a42fe',
	measurementId: 'G-1346KDYXGF',
};
